import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import Screen from './Screen';
import { VideoStore } from './VideoStore';
import { PollStore } from './PollStore';
import { ActivityStore } from './ActivityStore';
import { BadgesStore } from './BadgesStore';
import { ReactionStore } from './ReactionStore';
import { RTModel } from './RTModel';
import { WheelOfFortuneStore } from './WheelOfFortuneStore';
import { HTMLQuestionStore } from './HTMLQuestionStore';

export type CrownType = '' | 'gold-crown' | 'silver-crown';

const ActivityRoomState = RTModel.named('ActivityRoomState')
  .props({
    screen: Screen,
    students: types.array(types.string),
    room_type: types.union(
      types.literal('conference'),
      types.literal('group'),
      types.literal('lobby'),
      types.literal('individual'),
      types.literal('screenshare'),
      types.literal('checkpoint'),
      types.literal('project'),
      types.literal('unity')
    ),
    storage: types.map(types.string),
    submitted: InvokerType,
    setDriverInvoke: InvokerType,
    videoStore: VideoStore,
    pollStore: PollStore,
    htmlQuestionStore: HTMLQuestionStore,
    coachDriver: types.maybeNull(types.string),
    driver: types.maybeNull(types.string),
    presenter: types.maybeNull(types.string),
    requestInitiator: types.maybeNull(types.string),
    coachMessage: types.maybeNull(types.string),
    chatRoomName: '',
    callRoomName: '',
    getBatchStickyBoard: InvokerType,
    activityStore: ActivityStore,
    playlistName: types.maybeNull(types.string),
    startedAt: types.maybeNull(types.string),
    badgesStore: BadgesStore,
    crownState: types.map(types.array(types.string)),
    reactionStore: ReactionStore,
    showStationIntro: true,
    linkTag: '',
    wheelOfFortune: types.maybe(WheelOfFortuneStore),
    stickyBoardName: '',
    whiteBoardActive: false,
    groupIndex: 0,
    isPomodoro: types.boolean
  })
  .actions((self) => ({
    setStorage(key: string, value: string) {
      self.storage.set(key, value);
    },
    removeStorage(key: string) {
      self.storage.delete(key);
    },
    setCoachDriver(username: string) {
      self.coachDriver = username;
    },
    giveupCoachDriver() {
      self.coachDriver = null;
    },
    setPresenter(username: string | null) {
      self.presenter = username;
    },
    setRequestInitiator(username: string) {
      self.requestInitiator = username;
    },
    setDriver(username: string) {
      return self.setDriverInvoke.invoke({ username });
    },
    alertCoach(message: string) {
      self.coachMessage = message;
    },
    clearAlert() {
      self.coachMessage = null;
    },
    getStickyBoard() {
      return self.getBatchStickyBoard.invoke();
    },
    toggleStationIntro() {
      self.showStationIntro = !self.showStationIntro;
    },
    setStickyBoard(board: string) {
      self.stickyBoardName = board;
    },
    toggleWhiteBoardVisibility() {
      self.whiteBoardActive = !self.whiteBoardActive;
    },
    clearCrown(username: string) {
      ['gold-crown', 'silver-crown'].forEach((crown) => {
        const users = (self.crownState.get(crown) || []) as string[];
        self.crownState.set(
          crown,
          users.filter((user) => user !== username)
        );
      });
    },
  }))
  .actions((self) => ({
    setCrownState(crown: CrownType, username: string) {
      self.clearCrown(username);
      self.crownState.set(crown, [...(self.crownState.get(crown) || []), username]);
    },
  }))
  .views((self) => ({
    get sideBarCallActive() {
      return self.room_type !== 'conference' && self.room_type !== 'lobby';
    },
    get groupChatActive() {
      return self.room_type !== 'individual';
    },
    get shouldShowNavigation() {
      return (
        self.room_type === 'individual' ||
        self.room_type === 'group' ||
        self.room_type === 'project' ||
        self.room_type === 'checkpoint' ||
        self.room_type === 'unity'
      );
    },
    get canNavigate() {
      return (
        self.room_type === 'individual' ||
        self.room_type === 'group' ||
        self.room_type === 'project' ||
        self.room_type === 'unity'
      );
    },
    get name() {
      return self.resource_id;
    },
    get linkTagOrName() {
      return self.linkTag || self.resource_id;
    },
    get roomLabel() {
      switch (self.room_type) {
        case 'lobby':
          return 'Lobby';
        case 'conference':
          return 'Enquiry';
        case 'individual':
          return 'Independent';
        case 'checkpoint':
          return 'Checkpoint';
        case 'group':
          return 'Team';
        case 'project':
          return 'Creative';
        case 'screenshare':
          return 'Screen share';
        case 'unity':
          return 'Unity';
        default:
          return self.room_type;
      }
    },
    get stickyBoardActive() {
      return self.stickyBoardName.length > 0;
    },
    crown(username?: string) {
      if (!username) return;

      let users = (self.crownState.get('gold-crown') || []) as string[];
      if (users.includes(username)) return 'gold-crown';

      users = (self.crownState.get('silver-crown') || []) as string[];
      if (users.includes(username)) return 'silver-crown';
    },
  }));

export default ActivityRoomState;
export interface IActivityRoomState extends Instance<typeof ActivityRoomState> {}
