import { fireStats } from 'api';
import { types, flow } from 'mobx-state-tree';

const StatsStore = types.model({}).actions((self) => ({
  incr: flow(function* (stat: string) {
    yield fireStats({ incr: stat });
  }),
  timing: flow(function* (stat: string, ms: number) {
    yield fireStats({ timing: stat, ms });
  }),
}));

export default StatsStore;
