import { SeverityLevel } from '@sentry/types';

export const SentrySeverityLevel = {
  Info: 'info' as SeverityLevel,
  Error: 'error' as SeverityLevel,
  Fatal: 'fatal' as SeverityLevel,
  Debug: 'debug' as SeverityLevel,
  Log: 'log' as SeverityLevel,
  Warning: 'warning' as SeverityLevel,
};
