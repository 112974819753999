import { ChatStore } from 'chat/store/ChatStore';
import { types } from 'mobx-state-tree';
import ActivityRoomLinkState from './ActivityRoomLinkState';
import ActivityRoomState from './ActivityRoomState';
import { CallStore } from './CallStore';
import { ConnectionState } from './ConnectionState';
import GroupState from './GroupState';
import MasterclassSessionState, { MasterclassStatsStoreLite } from './MasterclassSessionState';
import MasterclassStudentState from './MasterclassStudentState';
import SingleActivityState from './SingleActivityState';
import TutorSessionCoachState from './TutorSessionCoachState';
import TutorSessionGroupManagerState from './TutorSessionGroupManagerState';
import TutorSessionRoomManagerState from './TutorSessionRoomManagerState';
import TutorSessionState from './TutorSessionState';
import TutorSessionStudentState from './TutorSessionStudentState';
import StickyBoardStore from './StickyBoardStore';
import { PollUserStore, PollResultStore } from './PollStore';
import MasterclassChatAdminState from './MasterclassChatAdminState';
import MasterclassChatState from './MasterclassChatState';
import { MasterclassAdminState } from './MasterclassAdminState';
import ProfilePicStore from 'store/ProfilePicStore';
import InsightCallSessionState from './InsightCallSessionState';
import { InClassIssuesOpsState, InClassIssuesCoachState } from './InClassIssuesState';
import TutorSessionCoachAdminState from './TutorSessionCoachAdmin';
import { TemplateSenderStatus } from './coachStores/TemplateSenderStore';
import { BrowserDebugState } from './BrowserDebugState';
import MasterclassMainChatState from './MasterclassMainChatState';
import LDSessionState from './LDSessionState';
import { BrowserState } from './BrowserCheck';
import AIChatState from './AIChatState';

export const RTWatchables = types.model('RTWatchables', {
  TutorSessionState: types.map(TutorSessionState),
  TutorSessionStudentState: types.map(TutorSessionStudentState),
  ActivityRoomState: types.map(ActivityRoomState),
  TutorSessionRoomManagerState: types.map(TutorSessionRoomManagerState),
  TutorSessionGroupManagerState: types.map(TutorSessionGroupManagerState),
  GroupState: types.map(GroupState),
  ChatStore: types.map(ChatStore),
  StudentConnectionState: types.map(ConnectionState),
  CoachConnectionState: types.map(ConnectionState),
  CallStore: types.map(CallStore),
  TutorSessionCoachState: types.map(TutorSessionCoachState),
  SingleActivityState: types.map(SingleActivityState),
  ActivityRoomLinkState: types.map(ActivityRoomLinkState),
  MasterclassSessionState: types.map(MasterclassSessionState),
  MasterclassStudentState: types.map(MasterclassStudentState),
  MasterclassChatAdminState: types.map(MasterclassChatAdminState),
  MasterclassChatState: types.map(MasterclassChatState),
  StickyBoardStore: types.map(StickyBoardStore),
  PollUserStore: types.map(PollUserStore),
  PollResultStore: types.map(PollResultStore),
  MasterclassAdminState: types.map(MasterclassAdminState),
  ProfilePicStore: types.map(ProfilePicStore),
  InsightCallSessionState: types.map(InsightCallSessionState),
  LDSessionState: types.map(LDSessionState),
  InClassIssuesOpsState: types.map(InClassIssuesOpsState),
  InClassIssuesCoachState: types.map(InClassIssuesCoachState),
  TutorSessionCoachAdminState: types.map(TutorSessionCoachAdminState),
  TemplateSenderStatus: types.map(TemplateSenderStatus),
  BrowserDebugState: types.map(BrowserDebugState),
  MasterclassMainChatState: types.map(MasterclassMainChatState),
  MasterclassStatsStoreLite: types.map(MasterclassStatsStoreLite),
  BrowserState: types.map(BrowserState),
  AIChatState: types.map(AIChatState),
});
