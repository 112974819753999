import { getParent, IDisposer, Instance, onPatch, types } from 'mobx-state-tree';

export const RTModel = types
  .model('RTModel', {
    resource_class: types.string,
    resource_id: types.identifier,
  })
  .actions((self) => {
    var patchWatcher: IDisposer | null = null;
    function afterCreate() {
      patchWatcher = onPatch(self, (data) => {
        if (data.path.indexOf('/_') >= 0) return;
        const parent = getParent(self, 3) as any;
        parent.sendStateUpdate(self.resource_class, self.resource_id, data);
      });

      if (typeof (self as any).afterRTCreate !== 'undefined') {
        (self as any).afterRTCreate();
      }
    }

    function beforeDestroy() {
      patchWatcher && patchWatcher();
    }

    return {
      afterCreate,
      beforeDestroy,
    };
  });

export interface IRTModel extends Instance<typeof RTModel> {}
