import { types, Instance, IAnyModelType } from 'mobx-state-tree';
import { CodingDetail, VideoDetail } from './ActivityDetail';

export const QuestionOption = types.model('QuestionOption', {
  name: types.string,
  option_content: types.string,
  is_correct: false,
});

export interface IQuestionOption extends Instance<typeof QuestionOption> {}

export const Question = types.model('Question', {
  id: types.integer,
  name: types.string,
  question_type: types.union(
    types.literal('mcq'),
    types.literal('msq'),
    types.literal('numeric'),
    types.literal('coding'),
    types.literal('text'),
    types.literal('EnergyPoll'),
    types.literal('YesNoPoll'),
    types.literal('StandardPoll'),
    types.literal('AskParentPoll'),
  ),
  question_content: types.string,
  solution_content: types.string,
  options: types.array(QuestionOption),
  coding_activity: types.maybeNull(types.late((): IAnyModelType => CodingDetail)),
  solution_video: types.maybeNull(types.late((): IAnyModelType => VideoDetail)),
  answer_unit: ''
});

export interface IQuestion extends Instance<typeof Question> {}
