import { changeSessionSprint } from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';
import Config from 'config';
import { parse } from 'fecha';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { fetchSessionActivityHistory, fetchStudentActivityDetail, setSessionCompleted } from '../api';
import { BatchLiteWithoutCoach } from './coachStores/BatchStore';
import Sprint, { ISprint } from './coachStores/Sprint';
import { Student } from './User';

export const SessionRating = types
  .model({
    rating: 0,
    issues: '',
    category: types.string,
  })
  .actions((self) => ({
    setRating(rating: number) {
      self.rating = rating;
    },
    setIssues(issues: string) {
      self.issues = issues;
    },
  }));

export interface ISessionRating extends Instance<typeof SessionRating> {}

const StudentActivityLog = types
  .model('StudentActivityLog', {
    started_at: types.string,
    completed_at: types.maybeNull(types.string),
    duration: types.maybeNull(types.string),
    admin_url: types.string,
    is_correct: types.maybeNull(types.boolean),
    activity_uuid: types.maybeNull(types.string),
  })
  .actions((self) => ({
    loadDetail: flow(function* () {
      try {
        if (!self.activity_uuid) return;
        const response = yield fetchStudentActivityDetail({ activity_uuid: self.activity_uuid });
        return response.data;
      } finally {
      }
    }),
  }));

export interface IStudentActivityLog extends Instance<typeof StudentActivityLog> {}

export const BaseSession = types
  .model('BaseSession', {
    name: types.maybeNull(types.string),
    sprint: types.maybeNull(Sprint),
    changingSprint: false,
  })
  .actions((self) => ({
    changeSessionSprint: flow(function* (newSprintName: string, reason: string) {
      self.changingSprint = true;
      try {
        if (!self.name) return;
        const response: ApiResponse<any> = yield changeSessionSprint({
          sessionName: self.name,
          sprintName: newSprintName,
          reason,
        });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        return true;
      } finally {
        self.changingSprint = false;
      }
    }),
    setSprint(sprint: ISprint) {
      self.sprint = cast(sprint);
    },
  }));

export const Session = BaseSession.named('Session')
  .props({
    name: types.identifier,
    start_at: types.string,
    completed_at: types.maybeNull(types.string),
    sprint: Sprint,
    students: types.array(Student),
    ratingsList: types.array(SessionRating),
    activityLogs: types.maybe(types.map(types.map(StudentActivityLog))),
    starts_in: types.maybe(types.number),
    can_join: true,
    batch: types.maybeNull(BatchLiteWithoutCoach),
  })
  .views((self) => ({
    get startAtDate() {
      return parse(self.start_at, Config.DATETIME_FORMAT);
    },
    get isDone() {
      return self.completed_at !== null;
    },
    get ratingInited() {
      return self.ratingsList.length > 0;
    },
    get subject() {
      return self.sprint?.course?.subject || ('' as string);
    },
    get isUnfinished() {
      // is session open for more than 1hr 30mins
      return self.starts_in ? self.starts_in < -5400 : false;
    },
    get hasStarted() {
      return self.starts_in ? self.starts_in < -300 : false;
    },
  }))
  .actions((self) => ({
    markCompleted: flow(function* () {
      const response = yield setSessionCompleted({
        session_name: self.name,
        feedbacks: self.ratingsList,
      });

      if (response.problem) {
        return;
      }
      self.completed_at = response.data.completed_at;
    }),
    initRatings() {
      const RATING_CATEGORIES = ['default', 'coach-session-feedback'];
      self.ratingsList = cast(RATING_CATEGORIES.map((category) => SessionRating.create({ category })));
    },
    loadActivityHistory: flow(function* () {
      const response = yield fetchSessionActivityHistory({
        session_name: self.name,
      });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      self.sprint = response.data.sprint;
      self.activityLogs = response.data.activity_logs;
    }),
  }));

export interface ISession extends Instance<typeof Session> {}

export const TutorSessionPico = types.model('TutorSessionPico', {
  name: types.identifier,
  start_at: types.string,
});

export const TutorSession = types
  .model('TutorSession', {
    name: types.identifier,
    start_at: types.string,
    sprint: Sprint,
  })
  .views((self) => ({
    get startAtDate() {
      return parse(self.start_at, Config.DATETIME_FORMAT);
    },
  }))
  .actions((self) => ({}));

export const CompletedStudentSession = types
  .model('CompletedStudentSession', {
    session: types.maybe(TutorSession),
    attendance: types.maybeNull(types.boolean),
    feedback: types.maybeNull(types.number),
  })
  .actions((self) => ({}));

export interface ICompletedStudentSession extends Instance<typeof CompletedStudentSession> {}
export interface IBaseSession extends Instance<typeof BaseSession> {}
