import { VideoDetail, IVideoDetail } from './ActivityDetail';
import { Instance, types, cast } from 'mobx-state-tree';
import { toJS } from 'mobx';

const HintStore = types
  .model('HintStore', {
    videos: types.array(VideoDetail),
    index: 0
  })
  .actions((self) => ({
    setVideos(videos: Array<IVideoDetail>) {
      self.videos = cast(videos.map(video => toJS(video)));
    },
    setIndex(index: number) {
      self.index = index;
    }
  }))
  .views((self) => ({
    get isActive() {
      return self.videos.length > 0;
    },
  }));

export interface IHintStore extends Instance<typeof HintStore> {}
export default HintStore;
