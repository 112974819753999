import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

const MasterclasStat = types.model({
  enrolled: 0,
  joined: 0,
  active: 0,
});

const MasterclassStatsStore = types
  .model('MasterclassStatsStore', {
    students: MasterclasStat,
    unverified: MasterclasStat,
    guests: MasterclasStat,
    feedbackCount: 0,
    now: 0,
    refreshStats: InvokerType,
    uniqueActiveCount: 0,
    uniqueJoinedCount: 0,
  })
  .views((self) => ({
    get activeCount() {
      return self.students.active + self.guests.active + self.unverified.active;
    },
    get activeCountWithoutGuests() {
      return self.students.active + self.unverified.active;
    },
    get enrolledCount() {
      return self.students.enrolled + self.guests.enrolled + self.unverified.enrolled;
    },
    get joinedCount() {
      return self.students.joined + self.guests.joined + self.unverified.joined;
    },
  }));

export interface IMasterclassStatsStore extends Instance<typeof MasterclassStatsStore> {}

export const MasterclassAdminState = RTModel.named('MasterclassSessionState')
  .props({
    hostChatRoomName: types.string,
    pinnedParticipants: types.array(types.string),
    setPinnedParticipantInvoke: InvokerType,
    statsStore: MasterclassStatsStore,
    toggleParticipantStudentViewInvoke: InvokerType,
    showPolls: false,
    recentPoll: types.frozen({ pollType: '', activityName: '' }),
  })
  .actions((self) => ({
    setPinned(username: string) {
      return self.setPinnedParticipantInvoke.invoke({ username, pin: true });
    },
    removePinned(username: string) {
      return self.setPinnedParticipantInvoke.invoke({ username, pin: false });
    },
    toggleParticipantStudentView(remove: boolean, username: string) {
      return self.toggleParticipantStudentViewInvoke.invoke({ remove, username });
    },
    setRecentPoll(pollType: string, activityName: string) {
      self.recentPoll = { pollType, activityName };
    },
    setShowPolls(val: boolean) {
      self.showPolls = val;
    },
  }));

export interface IMasterclassAdminState extends Instance<typeof MasterclassAdminState> {}
