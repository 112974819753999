import { types, Instance } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

const StickyNote = types
  .model('StickyNote', {
    x_coordinate: types.optional(types.integer, 0),
    y_coordinate: types.optional(types.integer, 0),
    content: types.optional(types.string, ''),
    color: types.optional(types.string, ''),
    username: types.string,
  })
  .actions((self) => ({
    setContent(text: string) {
      self.content = text;
    },
    setX(X: number) {
      self.x_coordinate = X;
    },
    setY(Y: number) {
      self.y_coordinate = Y;
    },
    setXY(X: number, Y: number) {
      self.x_coordinate = Math.floor(X);
      self.y_coordinate = Math.floor(Y);
    },
    setColor(color: string) {
      self.color = color;
    },
  }));

export interface IStickyNote extends Instance<typeof StickyNote> {}

const StickyBoardStore = RTModel.named('StickyBoardStore')
  .props({
    notes_map: types.map(StickyNote),
    readOnly: false,
    add: InvokerType,
    remove: InvokerType,
    scrollScale: types.frozen({ left: 0, top: 0, scale: 1 }),
  })
  .actions((self) => ({
    addNote() {
      return self.add.invoke();
    },
    removeNote(noteName: string) {
      return self.remove.invoke({ noteName });
    },
    toggleReadOnly() {
      self.readOnly = !self.readOnly;
    },
    setScrollScale(left: number, top: number, scale: number) {
      self.scrollScale = { left, top, scale };
    },
  }));

export interface IStickyBoardStore extends Instance<typeof StickyBoardStore> {}

export default StickyBoardStore;