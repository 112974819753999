import { parse } from "fecha";
import { types, Instance } from "mobx-state-tree";

export const ChatMessage = types
  .model("ChatMessage", {
    content: types.string,
    created_at: types.string,
    user: types.string,
    is_deleted: false,
    message_id: types.string
  })
  .views(self => ({
    get createdAt() {
      return parse(self.created_at, "YYYY-MM-DDTHH:mm:ssZZ");
    }
  }));

export interface IChatMessage extends Instance<typeof ChatMessage> {}
