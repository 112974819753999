import { Instance, types } from 'mobx-state-tree';
import InvokerType from 'store/InvokerType';
import { RTModel } from 'store/RTModel';
import { ChatMessage } from './ChatMessage';

export const ChatStore = RTModel.named('ChatStore')
  .props({
    activeMap: types.map(types.boolean),
    messages: types.array(ChatMessage),
    chat_room: types.string,
    typing: types.map(types.boolean),
    locked: false,
    add: InvokerType,
    remove: InvokerType,
    lockedUserMap: types.map(types.boolean),
    lockUserInvoke: InvokerType,
    unlockUserInvoke: InvokerType,
    _prefilledMessage: types.maybeNull(types.string),
  })
  .views((self) => ({
    get lastMessage() {
      if (!self.messages || self.messages.length === 0) {
        return undefined;
      }

      return self.messages[self.messages.length - 1];
    },
  }))
  .actions((self) => ({
    setLocalActive(username: string, active: boolean) {
      self.activeMap.set(username, active);
    },
    setActive(active: boolean) {
      [...self.activeMap.keys()].forEach((username) => {
        self.activeMap.set(username, active);
      });
    },
    setTyping(username: string, value: boolean) {
      if (value) {
        self.typing.set(username, value);
      } else {
        self.typing.delete(username);
      }
    },
    addMessage(message: string) {
      return self.add.invoke({ message });
    },
    removeMessage(message_id: string) {
      return self.remove.invoke({ message_id });
    },
    getLastSeenMessageId() {
      return sessionStorage.getItem(`chat.last.${self.chat_room}`);
    },
    getLastNotifiedMessageId() {
      return sessionStorage.getItem(`chat.lastNotify.${self.chat_room}`);
    },
    setLastSeenMessageId() {
      if (!self.lastMessage) {
        return;
      }

      sessionStorage.setItem(`chat.last.${self.chat_room}`, self.lastMessage.message_id);
    },
    setLastNotifiedMessageId() {
      if (!self.lastMessage) {
        return;
      }

      sessionStorage.setItem(`chat.lastNotify.${self.chat_room}`, self.lastMessage.message_id);
    },
    afterRTCreate() {
      if (!this.getLastSeenMessageId()) {
        this.setLastSeenMessageId();
      }

      if (!this.getLastNotifiedMessageId()) {
        this.setLastNotifiedMessageId();
      }
    },
    setLock(lock: boolean) {
      self.locked = lock;
    },
    lockUser(username: string) {
      return self.lockUserInvoke.invoke({ username });
    },
    unlockUser(username: string) {
      return self.unlockUserInvoke.invoke({ username });
    },
    setPrefilledMessage(message: string) {
      self._prefilledMessage = message;
    },
    clearPrefilledMessage() {
      self._prefilledMessage = null;
    },
  }));

export interface IChatStore extends Instance<typeof ChatStore> {}
