import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Config from 'config';
import { useGA4React } from 'ga-4-react';
import { mxTrackPageVisit } from 'utils/mixpanelEvents';

function GATracker() {
  const history = useHistory();
  const ga4react = useGA4React(Config.GTM_ID);

  useEffect(() => {
    if (!history || !ga4react) return;

    ga4react.pageview(window.location, window.location, document.title);

    mxTrackPageVisit(document.title);

    history.listen((location) => {
      ga4react.pageview(location as any, location as any, document.title);

      mxTrackPageVisit(document.title);
    });
  }, [history, ga4react]);
  return null;
}
export default GATracker;
