import { types, Instance } from 'mobx-state-tree';

export const WheelOfFortuneStore = types
  .model('WheelOfFortuneStore', {
    isActive: false,
    selectedUsername: types.string,
    startSpin: false,
    students: types.map(types.boolean),
  })
  .actions((self) => ({
    setIsActive(val: boolean) {
      self.isActive = val;
    },
    setSelectedUsername(username: string) {
      self.selectedUsername = username;
    },
    setStartSpin(state: boolean) {
      self.startSpin = state;
    },
    removeStudent(username: string) {
      self.students.set(username, false);
    },
    resetStudents(usernames: Array<string>) {
      self.students.clear();
      usernames.forEach((username) => {
        self.students.set(username, true);
      });
    },
  }));

export interface IWheelOfFortuneStore extends Instance<typeof WheelOfFortuneStore> {}
