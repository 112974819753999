import { addNote, getStudentNotes } from 'api';
import { ApiResponse } from 'apisauce';
import { toJS } from 'mobx';
import { types, Instance, getEnv, flow, cast } from 'mobx-state-tree';
import { IStudent, Student } from './User';

const Note = types.model('Note', {
  description: types.string,
  sprint: types.maybeNull(types.optional(types.string, '')),
  added_by: types.string,
  created_at: types.string,
});

export interface Inote extends Instance<typeof Note> {}

const NoteStore = types
  .model('NoteStore', {
    loading: false,
    creating: false,
    student: types.maybe(Student),
    notes: types.maybe(types.map(types.array(Note))),
  })
  .actions((self) => ({
    setStudent(student: IStudent) {
      self.student = toJS(student); // to avoid "A node cannot exists twice in the state tree" error
    },
    reset() {
      self.student = undefined;
      self.notes = undefined;
    },
    addNote: flow(function* (category: string, description: string, activityroom?: string) {
      if (!self.student) return;
      self.creating = true;
      try {
        const response: ApiResponse<any> = yield addNote({
          activity_room: activityroom,
          category: category,
          description: description,
          added_for: self.student.username,
        });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
      } finally {
        self.creating = false;
      }
    }),
    getNotes: flow(function* (daysBefore?: number) {
      if (!self.student) return;
      self.loading = true;
      self.notes?.clear();
      try {
        const response: ApiResponse<any> = yield getStudentNotes({
          addedFor: self.student.username,
          daysBefore: daysBefore,
        });
        if (response.status === 404) {
          self.notes?.clear();
        } else if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
        } else if (response.data) self.notes = cast(response.data.notes);
      } finally {
        self.loading = false;
      }
    }),
  }));

export default NoteStore;
export interface InoteStore extends Instance<typeof NoteStore> {}
