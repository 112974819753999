import { getCompletedSession, getCompletedSessions } from 'api';
import {
  fetchCatchupSessions,
  fetchCompletedStudentSessions,
  fetchStudentAttendance,
  fetchStudentFeedback,
} from 'api/coachDashboard';
import Config from 'config';
import { parse } from 'fecha';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { Batch } from './coachStores/BatchStore';
import { PageStore } from './Page';
import { CompletedStudentSession } from './Session';
import { SessionQualityCoachLite } from './coachStores/SessionRecordingFeedbackStore';
import Sprint from './coachStores/Sprint';
import User, { Student, StudentLite } from './User';

export const CompletedSession = types
  .model({
    name: types.identifier,
    start_at: types.string,
    completed_at: types.maybeNull(types.string),
    sprint: Sprint,
    students: types.array(Student),
    batch: types.maybeNull(Batch),
    attendance: 0.0,
    student_feedback: types.maybeNull(types.number),
    session_quality: types.maybeNull(SessionQualityCoachLite),
    coach: types.maybeNull(User),
  })
  .views((self) => ({
    get startAtDate() {
      return parse(self.start_at, Config.DATETIME_FORMAT);
    },
    get hasFeedbacks() {
      return self.student_feedback !== null;
    },
  }));

export interface ICompletedSession extends Instance<typeof CompletedSession> {}

const StudentFeedback = types
  .model('StudentFeedback', {
    user: types.maybe(StudentLite),
    rating: types.maybe(types.number),
    issues: types.maybe(types.string),
  })
  .actions((self) => ({}));

const StudentAttendance = types.model('StudentAttendance', {
  user: types.maybe(StudentLite),
  joined_at: types.maybeNull(types.string),
});
export interface IStudentAttendance extends Instance<typeof StudentAttendance> {}

export const CompletedSessionStore = types
  .model({
    fetchingSession: false,
    completedSessions: types.array(CompletedSession),
    currentSession: types.maybe(CompletedSession),
    feedbacks: types.maybe(types.array(StudentFeedback)),
    page: types.maybe(PageStore),
    attendance: types.maybe(types.array(StudentAttendance)),
    loading: false,
  })
  .actions((self) => ({
    fetchCompletedSessions: flow(function* ({ batch, daysBefore, pageNumber, demo, test, sprint, pageSize }) {
      self.fetchingSession = true;
      self.completedSessions.clear();
      const response = yield getCompletedSessions({ batch, daysBefore, pageNumber, demo, test, sprint, pageSize });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      self.page = response.data.page;
      self.completedSessions = response.data.results;
      self.fetchingSession = false;
    }),

    fetchCompletedSession: flow(function* (session_name) {
      self.fetchingSession = true;
      const response = yield getCompletedSession({ session_name });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      self.currentSession = response.data;
      self.fetchingSession = false;
    }),

    fetchStudentFeedback: flow(function* (sessionName: string) {
      self.loading = true;
      try {
        const response = yield fetchStudentFeedback({
          sessionName,
        });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.feedbacks = response.data;
      } finally {
        self.loading = false;
      }
    }),
    fetchStudentAttendance: flow(function* (sessionName: string) {
      self.loading = true;
      try {
        const response = yield fetchStudentAttendance({ sessionName });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.attendance = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
  }));

export const CompletedStudentSessionStore = types
  .model('CompletedStudentSessionStore', {
    completedSessions: types.maybe(types.array(CompletedStudentSession)),
    page: types.maybe(PageStore),
  })
  .actions((self) => ({
    fetchCompletedStudentSessions: flow(function* (batchStudentName: string, daysBefore: number, pageNumber: number) {
      const response = yield fetchCompletedStudentSessions({
        batchStudentName,
        pageNumber,
        daysBefore,
      });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      self.completedSessions = response.data.results;
      self.page = response.data.page;
    }),
  }));

export const CatchupSessionStore = types
  .model('CatchupSessionStore', {
    catchupSessions: types.maybe(types.array(CompletedStudentSession)),
    page: types.maybe(PageStore),
  })
  .actions((self) => ({
    fetchCatchupSessions: flow(function* (batchStudentName: string, daysBefore: number, pageNumber: number) {
      const response = yield fetchCatchupSessions({
        batchStudentName,
        pageNumber,
        daysBefore,
      });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      self.catchupSessions = response.data.results;
      self.page = response.data.page;
    }),
  }));
