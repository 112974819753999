import { getDefaultEmpathyFormData, getEmpathyFormData, submitEmpathyFormData } from 'api';
import { ApiResponse } from 'apisauce';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { Student } from './User';

const EmpathySingleAnswer = types
  .model({
    name: types.string,
    answerType: 'single',
    answer: types.string,
    label: types.string,
  })
  .actions((self) => ({
    setAnswer: (answer: string) => {
      self.answer = answer;
    },
  }));

const EmpathyMCQAnswer = types
  .model({
    name: types.string,
    label: types.string,
    answerType: 'empathy-mcq-answer',
    answer: types.array(types.string),
  })
  .actions((self) => ({
    setAnswer: (answers: string[]) => {
      self.answer = cast(answers);
    },
  }));

const EmpathyMCQAnswerAutocomplete = types
  .model({
    name: types.string,
    label: types.string,
    options: types.array(types.string),
    answerType: 'empathy-mcq-answer-autocomplete',
    answer: types.array(types.string),
  })
  .actions((self) => ({
    setAnswer: (answers: string[]) => {
      self.answer = cast(answers);
    },
  }));

const EmpathyRadioOptionsAnswer = types.model({
  name: types.string,
  label: types.string,
  options: types.array(types.string),
  answerType: 'empathy-radio-options',
  answer: types.string,
});

const EmpathyAnswer = types.union(
  EmpathyMCQAnswer,
  EmpathySingleAnswer,
  EmpathyMCQAnswerAutocomplete,
  EmpathyRadioOptionsAnswer
);

export type EmpathyAnswerType = (
  | IEmpathyMCQAnswer
  | IEmpathySingleAnswer
  | IEmpathyMCQAnswerAutocomplete
  | IEmpathyRadioOptionsAnswer
)[];
export interface IEmpathyMCQAnswer extends Instance<typeof EmpathyMCQAnswer> {}
export interface IEmpathySingleAnswer extends Instance<typeof EmpathySingleAnswer> {}
export interface IEmpathyMCQAnswerAutocomplete extends Instance<typeof EmpathyMCQAnswerAutocomplete> {}
export interface IEmpathyRadioOptionsAnswer extends Instance<typeof EmpathyRadioOptionsAnswer> {}

const EmpathyFormStore = types
  .model('EmpathyFormStore', {
    form_data: types.maybeNull(
      types.union(
        types.model({
          questions: types.undefined,
          hobbies: types.array(types.string),
          goodAt: types.string,
          reasonForExcitement: types.string,
        }),
        types.model({ questions: types.array(EmpathyAnswer) })
      )
    ),
    form_data_form: types.maybeNull(types.model({ questions: types.array(EmpathyAnswer) })),
    student: types.maybe(Student),
    loading: false,
  })
  .actions((self) => ({
    submitEmpathyFormGeneric: flow(function* () {
      self.loading = true;
      const response: ApiResponse<any> = yield submitEmpathyFormData(self.form_data_form || {});
      if (response.ok) {
        self.form_data_form = cast(response.data.form_data);
      }
      self.loading = false;
      return response;
    }),
    submitEmpathyForm: flow(function* ({
      hobbies,
      goodAt,
      reasonForExcitement,
    }: {
      hobbies: string[];
      goodAt: string;
      reasonForExcitement: string;
    }) {
      self.loading = true;
      const response: ApiResponse<any> = yield submitEmpathyFormData({
        hobbies,
        goodAt,
        reasonForExcitement,
      });
      if (response.ok) {
        if (response.data.form_data.questions) {
          self.form_data = cast(response.data.form_data.questions);
        } else {
          self.form_data = {
            hobbies: response.data.form_data.hobbies,
            goodAt: response.data.form_data.good_at,
            reasonForExcitement: response.data.form_data.reasonForExcitement,
            questions: undefined,
          };
        }
      }
      self.loading = false;
      return response;
    }),
    getDefaultData: flow(function* () {
      self.loading = true;
      const response: ApiResponse<any> = yield getDefaultEmpathyFormData();
      if (response.problem) {
        self.loading = false;
        return;
      }
      self.form_data_form = response.data.default_data;
      self.loading = false;
    }),
    getData: flow(function* ({ student }: { student: string }) {
      self.form_data = null;
      self.loading = true;
      const response: ApiResponse<any> = yield getEmpathyFormData({ student });
      if (response.problem) {
        self.loading = false;
        return;
      }

      if (response.data.length > 0) {
        const data = response.data[0];
        if (data.form_data) {
          if (data.form_data.questions) {
            self.form_data = data.form_data;
          } else {
            self.form_data = {
              hobbies: data.form_data.hobbies,
              goodAt: data.form_data.goodAt,
              reasonForExcitement: data.form_data.reasonForExcitement,
              questions: undefined,
            };
          }
        } else {
          self.form_data = response.data.default_data;
        }

        self.student = data.student;
      }
      self.loading = false;
    }),
  }));

export default EmpathyFormStore;
export interface IEmpathyFormStore extends Instance<typeof EmpathyFormStore> {}

export const HOBBIES = [
  'Coding',
  'Robotics',
  'Space',
  'Roblox',
  'Minecraft',
  'Fortnite',
  'Valorant',
  'Apex Legends',
  'Making YouTube Videos',
  'Watching YouTube Videos',
  'Tech & Gadgets',
  'Cricket',
  'Football',
  'Tennis',
  'Table Tennis',
  'Kabaddi',
  'Rugby',
  'Singing',
  'Movies',
  'Music',
  'Dancing',
  'Photography',
  'Wildlife',
  'Chess',
  'Painting',
  'Digital Art',
  'Drawing',
  'Science Experiments',
  'History',
  'Geography',
  'Quizzing',
  'Running',
  'Athletics',
  'Basketball',
  'American Football',
  'Badminton',
  'Swimming',
  'Hockey',
  'Cycling',
  'Volleyball',
  'Golf',
  'Formula 1',
];

export const REASON_FOR_EXCITEMENT_CODING = [
  'I want to have fun while learning coding, just like in the masterclass',
  'I want to become good and fast at making games & apps',
  'I want to become a software developer, just like my parents',
  'I want to make amazing friends',
];

export const REASON_FOR_EXCITEMENT_CORE = [
  'I want to have fun while learning, just like in the masterclass',
  'I want to become good at solving real world problems',
  'I want to become a scientist/mathematician',
  'I want to make amazing friends',
];

export const MOTIVATION_STYLE: { [key: string]: { style: string; description: string[] } } = {
  'I want to have fun while learning coding, just like in the masterclass': {
    style: 'Intrinsic',
    description: [
      'You are someone who enjoys the process of doing a task. Ex: Some people love the process of cooking because it relaxes them.',
      'So when your motivation is down, to boost it I will put you in coding tasks that you generally enjoy doing.',
    ],
  },
  'I want to become good and fast at making games & apps': {
    style: 'Goal Attainment',
    description: [
      "You are someone who wants to achieve mastery in the task. Ex: You are learning to solve a rubik's cube because you want to solve it in under 5 secs.",
      'So when your motivation is down, to boost it I will break down the challenge into smaller pieces, arrange them in increasing order of difficulty, help you master them, etc.',
    ],
  },
  'I want to become a software developer, just like my parents': {
    style: 'Instrumental',
    description: [
      'You are someone who is learning this to achieve a different goal. Ex: You hate the process of exercising but you still do it because you want to be fit for your badminton competition.',
      'So when your motivation is down, to boost it I will give you specific topics to practice that are related to your goal, I will also show you how your practice is connected to your goal, etc.',
    ],
  },
  'I want to make amazing friends': {
    style: 'Social',
    description: [
      'You are someone who loves a fun social environment and wants to make friends and learn with them. Ex: You joined the youtuber class because it was always fun to work with that team.',
      'So when your motivation is down, to boost it I will create more opportunities to interact, will create systems where the team can chat and socialise even after class, etc.',
    ],
  },
};
