import { types, Instance } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

export const ConnectionState = RTModel.named('ConnectionState')
  .props({
    is_connected: false,
    joined_at: types.maybeNull(types.string),
    time_to_session: types.maybe(types.number),
    forceRefresh: InvokerType,
  })
  .actions((self) => ({
    refresh() {
      return self.forceRefresh.invoke();
    },
  }))
  .views((self) => ({
    get isEarly() {
      return self.time_to_session !== undefined && self.time_to_session > 0;
    },
    get isLate() {
      return self.time_to_session !== undefined && self.time_to_session <= -120;
    },
    get canJoin() {
      return (
        self.joined_at !== null ||
        (self.time_to_session !== undefined && self.time_to_session <= 0 && self.time_to_session >= -120)
      );
    },
  }));

export interface IConnectionState extends Instance<typeof ConnectionState> {}
