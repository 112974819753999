import { getInsightCallSession } from "api";
import { ApiResponse } from "apisauce";
import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";

const InsightCallSession = types
  .model({
    name: types.string,
    start_at: types.string,
    insight_call_name: types.string,
  })

const InsightCallSessionStore = types
  .model({
    loading: false,
    insightCallSessions: types.maybe(types.array(InsightCallSession)),
    currentSession: types.maybeNull(InsightCallSession),
  })
  .actions((self) => ({
    fetchInsightCallSession: flow(function* (insightCallSessionName: string) {
      const response: ApiResponse<any> = yield getInsightCallSession(insightCallSessionName)
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      self.insightCallSessions = cast([response.data]);
      self.currentSession = cast(response.data);
    })
  }))


export default InsightCallSessionStore;
export interface IInsightCallSession extends Instance<typeof InsightCallSession> { }
