document.domain = window.location.hostname;

const devConfig = {
  BASE_URL: 'http://' + window.location.host,
  SOCKET_BASE_URL: 'ws://' + window.location.hostname + ':8000',
  API_BASE_URL: 'http://' + window.location.hostname + ':8000/api/',
  GOOGLE_OAUTH_CLIENT_ID: '581542018185-aidp5ecuc11cv99o99knl6dnj7706un5.apps.googleusercontent.com',
  MIXPANEL_TOKEN: 'd6ab5dd3cabeff906f074e0597893d7d',
  GTM_ID: 'G-WQX60MGBVK',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyCgKzZBNg5yjfA5VB2w3WK901gUPeS27Go',
    authDomain: 'stayq-fb-dev.firebaseapp.com',
    databaseURL: 'https://stayq-fb-dev-default-rtdb.firebaseio.com',
    projectId: 'stayq-fb-dev',
    storageBucket: 'stayq-fb-dev.appspot.com',
    messagingSenderId: '1026580340703',
    appId: '1:1026580340703:web:0682ae7b0245c004256e6a',
    measurementId: 'G-L7GFM0HFY5',
  },
};

export default devConfig;
