import { createInsightCalls, fetchMyInsightCalls, ICreateInsightCalls } from 'api/coachDashboard';
import { getMyUpcomingInsightCalls } from 'api';
import { ApiResponse } from 'apisauce';
import Config from 'config';
import { parse } from 'fecha';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { BatchStudentStore } from './coachStores/BatchStudentStore';
import { PageStore } from './Page';
import { TranscodedVideo } from './TranscodedVideoStore';
import { User } from './User';

export const BaseInsightCall = types
  .model({
    name: types.string,
    is_booked: types.boolean,
    batch_student_status: types.string,
    can_skip_insights: types.boolean,
    type: types.maybe(types.maybeNull(types.string)),
    parent: types.maybeNull(User),
    is_coach_absent: types.maybe(types.boolean),
  })
  .views((self) => ({
    get batchStudentColor() {
      return self.batch_student_status === 'active' || self.batch_student_status === 'reassigned'
        ? 'inherit'
        : '#FE6D73';
    },
    get coachColor() {
      return self.is_coach_absent ? '#FE6D73' : 'inherit';
    },
    get coachAbsenceInfoTitle() {
      return self.is_coach_absent ? 'Needs attention - Coach absent' : undefined;
    },
    get typeLabel() {
      switch (self.type) {
        case 'on-demand':
          return '🧓 On-Demand PIC';
        case 'office-hour':
          return '🧑‍🎓 Office Hours';
        case 'sprint-scheduled':
          return '🧑‍🏫 Semester';
        default:
          return null;
      }
    },
    get isOfficeHour() {
      return self.type === 'office-hour';
    },
  }));

const InsightCallStore = BaseInsightCall.named('InsightCallStore')
  .props({
    batch_student: BatchStudentStore,
    start_at: types.maybeNull(types.string),
    parent_session_link: types.maybeNull(types.string),
    session_link: types.maybeNull(types.string),
    sprint: types.maybeNull(types.string),
    unit: types.maybeNull(types.string),
    feedback_submitted: types.boolean,
    has_upcoming_payment: types.boolean,
    status: types.string,
    is_parent_unreachable: types.boolean,
    comments: types.maybeNull(types.string),
    recording_url: types.maybeNull(types.string),
    insight_video: types.maybeNull(TranscodedVideo),
    active_insight_call_session_name: types.maybeNull(types.string),
    parent_contact: types.maybeNull(types.string),
    template_map_exists: types.boolean,
    parent_query: types.maybeNull(types.string),
  })
  .views((self) => {
    const superLabel = self.typeLabel;
    return {
      get typeLabel() {
        if (self.has_upcoming_payment) {
          return '🧑‍🏫 Renewal';
        }
        return superLabel;
      },
      get startAtDate() {
        if (!self.start_at) return null;
        return parse(self.start_at, Config.DATETIME_FORMAT);
      },
      get startsInMiliseconds() {
        if (!self.start_at) return 0;
        const diff = (parse(self.start_at, Config.DATETIME_FORMAT)?.getTime() || 0) - new Date().getTime();
        if (diff > 0) return Math.round(diff);
        return 0;
      },

      get rescheduleLink() {
        return `https://stayqrious.com/insight-call?q=${self.name}`;
      },
    };
  })
  .views((self) => ({
    get startsInSeconds() {
      const startsInMiliseconds = self.startsInMiliseconds;
      if (startsInMiliseconds === 0) return 0;
      return startsInMiliseconds / 1000;
    },
  }));

export interface IInsightCallStore extends Instance<typeof InsightCallStore> {}

const InsightCallListStore = types
  .model({
    insightCalls: types.array(InsightCallStore),
    insightCallsPage: types.maybe(PageStore),
    upcomingCalls: types.array(InsightCallStore),
    upcomingCallsPage: types.maybe(PageStore),
    pastCalls: types.array(InsightCallStore),
    pastCallsPage: types.maybe(PageStore),
    loading: false,
    insightVideos: types.array(InsightCallStore),
    insightVideosPage: types.maybe(PageStore),
  })
  .actions((self) => ({
    fetchInsightCallList: flow(function* ({
      page = 1,
      pageSize,
      booked,
      completedCalls,
      today,
      batchStudent,
      startAtOrder,
    }: {
      page?: number;
      pageSize?: number;
      booked?: string;
      completedCalls?: boolean;
      today?: boolean;
      batchStudent?: string;
      startAtOrder?: string;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchMyInsightCalls({
          page,
          pageSize,
          booked,
          completedCalls,
          today,
          batchStudent,
          startAtOrder,
        });
        if (response.status === 401 || response.status === 403) return;
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        if (response.status === 200) {
          self.insightCalls = cast(response.data.results);
          self.insightCallsPage = response.data.page;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchInsightVideosList: flow(function* ({
      page = 1,
      pageSize,
      booked,
      completedCalls,
      today,
    }: {
      page?: number;
      pageSize?: number;
      booked?: string;
      completedCalls?: boolean;
      today?: boolean;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchMyInsightCalls({
          page,
          pageSize,
          booked,
          completedCalls,
          parentUnreachable: true,
          today,
        });
        if (response.status === 401 || response.status === 403) return;
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        if (response.status === 200) {
          self.insightVideos = cast(response.data.results);
          self.insightVideosPage = response.data.page;
        }
      } finally {
        self.loading = false;
      }
    }),
    createInsightCalls: flow(function* (data: ICreateInsightCalls) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield createInsightCalls(data);
        if (response.status === 401 || response.status === 403) return;
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        if (response.status === 201) {
          return true;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchUpcomingCalls: flow(function* ({
      page = 1,
      pageSize,
      booked,
      completedCalls,
      today,
      batchStudent,
      startAtOrder,
    }: {
      page?: number;
      pageSize?: number;
      booked?: string;
      completedCalls?: boolean;
      today?: boolean;
      batchStudent?: string;
      startAtOrder?: string;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchMyInsightCalls({
          page,
          pageSize,
          booked,
          completedCalls,
          today,
          upcomingCalls: true,
          batchStudent,
          startAtOrder,
        });
        if (response.status === 401 || response.status === 403) return;
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        if (response.status === 200) {
          self.upcomingCalls = cast(response.data.results);
          self.upcomingCallsPage = response.data.page;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchMyUpcomingInsightCalls: flow(function* () {
      // For student user
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield getMyUpcomingInsightCalls();
        if (response.status === 401 || response.status === 403) return;
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        if (response.status === 200) {
          self.upcomingCalls = cast(response.data);
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchPastCalls: flow(function* ({
      page = 1,
      pageSize,
      booked,
      completedCalls,
      today,
    }: {
      page?: number;
      pageSize?: number;
      booked?: string;
      completedCalls?: boolean;
      today?: boolean;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchMyInsightCalls({
          page,
          pageSize,
          booked,
          completedCalls,
          today,
          pastCalls: true,
        });
        if (response.status === 401 || response.status === 403) return;
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        if (response.status === 200) {
          self.pastCalls = cast(response.data.results);
          self.pastCallsPage = response.data.page;
        }
      } finally {
        self.loading = false;
      }
    }),
  }));

export default InsightCallListStore;

export type IInsightCallLinks = {
  booking_link?: string | null;
  session_link?: string | null;
};
