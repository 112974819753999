import { cast, Instance, types } from 'mobx-state-tree';
import { RTModel } from './RTModel';

const ActivityRoomLinkState = RTModel.named('ActivityRoomLinkState')
  .props({
    requestInitiator: '',
    presentedRoom: '',
    agreements: types.map(types.boolean),
    presence: types.map(types.boolean),
  })
  .actions((self) => ({
    setPresentedRoom(name: string, username: string) {
      self.presentedRoom = name;
      self.requestInitiator = username;
      self.agreements = cast({});
    },
    setAgreement(username: string, agreed: boolean) {
      self.agreements.set(username, agreed);
    },
    clearAll() {
      self.presentedRoom = '';
      self.requestInitiator = '';
      self.agreements = cast({});
    },
  })).actions(self => {
    let localUsername: string | undefined = undefined;
    function setPresence(username: string, present: boolean) {
      if (present) {
        self.presence.set(username, present);
        localUsername = username;
      }
      else {
        self.presence.delete(username);
        localUsername = undefined;
      }
    }

    function beforeDestroy() {
      if (localUsername) {
        self.presence.delete(localUsername);
      }
    }

    return { setPresence, beforeDestroy };
  }).views(self => ({
    hasAgreed(username: string) {
      return !!self.agreements.get(username);
    },
    get presentUsernames() {
      return [...self.presence.keys()];
    }
  }));

export default ActivityRoomLinkState;
export interface IActivityRoomLinkState extends Instance<typeof ActivityRoomLinkState> {}
