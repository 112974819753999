import { types } from 'mobx-state-tree';
import { reaction } from 'mobx';
import canAutoplay from 'can-autoplay';
import config from 'config';

const CommonStore = types
  .model('CommonStore', {
    token: types.maybeNull(types.string),
    appLoaded: false,
    lastProblem: types.maybe(types.string),
    autoplayBlocked: true,
    localPrefs: types.frozen({ micDeviceId: '', camDeviceId: '' }),
    idle: false,
    aboutToIdle: false,
    appLoadError: false,
  })
  .actions((self) => ({
    clickHandler() {
      self.autoplayBlocked = false;
    },
    setAutoplayBlocked(result: boolean) {
      self.autoplayBlocked = result;
    },
    setIdleStatus(result: boolean) {
      self.idle = result;
    },
    setIdleWarnStatus(result: boolean) {
      self.aboutToIdle = result;
    },
    setToken(token: string | null) {
      self.token = token;
    },
    setAppLoaded() {
      self.appLoaded = true;
    },
    setAppLoadError() {
      self.appLoadError = true;
    },
    setNetworkProblem(problem: string) {
      self.lastProblem = problem;
    },
    dismissNetworkProblem() {
      self.lastProblem = undefined;
    },
    setPreferredMicrophone(micDeviceId: string) {
      self.localPrefs = { ...self.localPrefs, micDeviceId };
    },
    setPreferredWebcam(camDeviceId: string) {
      self.localPrefs = { ...self.localPrefs, camDeviceId };
    },
    setPreferredDevices(prefs: { camDeviceId: string; micDeviceId: string }) {
      self.localPrefs = prefs;
    },
  }))
  .actions((self) => {
    var iid: number;
    var iidwarning: number;

    const resetIdleTimer = () => {
      const skip = window.localStorage.getItem('SkipIdleTime');
      if (skip === 'true') {
        return;
      }
      const events = ['mousedown', 'mousemove', 'keypress', 'scroll'];
      events.forEach((name) => {
        document.removeEventListener(name, resetIdleTimer, true);
      });

      self.setIdleStatus(false);
      const timeout = 600 * 1000; //idles in about 10 minutes
      window.clearTimeout(iid);
      iid = window.setTimeout(() => {
        self.setIdleStatus(true);
      }, timeout);

      self.setIdleWarnStatus(false);
      window.clearTimeout(iidwarning);
      iidwarning = window.setTimeout(() => {
        self.setIdleWarnStatus(true);
      }, timeout - Math.max(timeout * 0.1, 5 * 1000)); // atleast 5 sec prior warning

      window.setTimeout(() => {
        /// Idle timers
        events.forEach((name) => {
          document.addEventListener(name, resetIdleTimer, true);
        });
      }, 2000);
    };

    const afterCreate = () => {
      self.token = window.localStorage.getItem('jwt');
      self.localPrefs = JSON.parse(window.localStorage.getItem('localPrefs') || '{}');

      const shouldCheck = window.localStorage.getItem('autoplayOverrided') !== 'true';
      if (shouldCheck)
        canAutoplay.audio().then(({ result, error }: { result: boolean; error: Error }) => {
          if (error) {
            config.DEBUG && console.info(error);
          }
          self.setAutoplayBlocked(!result);
        });
      else {
        self.setAutoplayBlocked(false);
      }

      document.addEventListener('click', self.clickHandler, { once: true });

      reaction(
        () => self.localPrefs,
        (prefs) => {
          window.localStorage.setItem('localPrefs', JSON.stringify(prefs));
        }
      );

      window.addEventListener('storage', (ev) => {
        if (ev.key === 'localPrefs') {
          self.setPreferredDevices(JSON.parse(ev.newValue || '{}'));
        }
        if (ev.key === 'jwt') {
          self.setToken(ev.newValue);
        }
      });

      reaction(
        () => self.token,
        (token) => {
          if (token === null) {
            window.localStorage.removeItem('jwt');
          } else {
            window.localStorage.setItem('jwt', token);
            window.localStorage.setItem('hasSeen', 'yes');
          }
        }
      );

      resetIdleTimer();
    };

    return { afterCreate, resetIdleTimer };
  })
  .views((self) => ({
    get audioConfig() {
      if (self.localPrefs.micDeviceId) {
        return { deviceId: self.localPrefs.micDeviceId };
      }
      return {};
    },
    get videoConfig() {
      if (self.localPrefs.camDeviceId) {
        return { deviceId: self.localPrefs.camDeviceId };
      }
      return {};
    },
  }));

export default CommonStore;
