import { guestLogin, signup, signupPrevalidate, updateGuest } from 'api';
import { ApiResponse } from 'apisauce';
import { applySnapshot, cast, flow, getEnv, Instance, onSnapshot, types } from 'mobx-state-tree';
import { mxTrackErrors } from 'utils/mixpanelEvents';

const getTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return '';
  }
};

const SERVER_ERROR_MESSAGE = "Oops! there's some issue at our end, Please try again afer some time.";
const PHONE_REGEX_INDIA = /^(\+?91|0)[6789]\d{9}$/;
const SignUpStore = types
  .model('SignUpStore', {
    loading: false,
    errors: types.map(types.array(types.string)),
    otherErrors: '',
    otpError: '',
    parentName: '',
    parentEmail: '',
    phone: '',
    kidName: '',
    kidSchool: '',
    kidSchoolPreset: false,
    grade: '',
    extraInfo: types.map(types.string),
    username: '',
    otp: '',
    timezone: getTimezone(),
    confirmTZ: false
  })
  .actions((self) => ({
    setErrors(errors: { [key: string]: Array<string> }) {
      self.errors.merge(errors);
      mxTrackErrors(cast(self.errors));
    },
    setOTPError(err: string) {
      self.otpError = err;
    },
    clearErrors() {
      self.errors.clear();
      self.otherErrors = '';
      self.otpError = '';
    },
  }))
  .actions((self) => ({
    validate: flow(function* () {
      self.loading = true;
      self.clearErrors();

      if (self.parentEmail.length > 0) {
        const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (emailRegex.test(self.parentEmail)) {
          self.errors.delete('parentEmail');
        } else {
          self.setErrors({ parentEmail: ['Please enter a valid email!'] });
        }
      }

      if (self.phone === '') {
        self.setErrors({ phone: ['Please enter a valid phone number!'] });
      } else if ((self.phone.startsWith('91') || self.phone.startsWith('+91')) && !PHONE_REGEX_INDIA.test(self.phone)) {
        self.setErrors({ phone: ['Please enter a valid phone number!'] });
      } else {
        self.errors.delete('phone');
      }
      try {
        if (self.errors.size === 0) {
          const response: ApiResponse<any> = yield signupPrevalidate({
            parentName: self.parentName,
            parentEmail: self.parentEmail,
            phone: self.phone,
            kidName: self.kidName,
            kidSchool: self.kidSchool,
            grade: self.grade,
            extraInfo: self.extraInfo as Map<string, string>,
            timezone: self.timezone,
          });
          if (response.problem) {
            if (response.status === 400) {
              self.errors.merge(response.data);
            } else {
              self.otherErrors = SERVER_ERROR_MESSAGE;
            }
            return;
          }
          return response;
        }
      } finally {
        self.loading = false;
      }
    }),
    setParentName(parentName: string) {
      self.errors.delete('parentName');
      self.parentName = parentName;
    },
    setParentEmail(parentEmail: string) {
      self.errors.delete('parentEmail');
      self.parentEmail = parentEmail;
    },
    setPhone(phone: string) {
      self.errors.delete('phone');
      self.phone = phone;
    },
    setKidName(kidName: string) {
      self.errors.delete('kidName');
      self.kidName = kidName;
    },
    setKidSchool(kidSchool: string, preset: boolean = false) {
      self.errors.delete('kidSchool');
      self.kidSchool = kidSchool;
      self.kidSchoolPreset = preset;
    },
    setGrade(grade: string) {
      self.errors.delete('grade');
      self.grade = grade;
    },
    setExtraInfo(params: { [key: string]: string }) {
      self.extraInfo.merge(params);
    },
    setTimezone(tz: string) {
      self.timezone = tz;
    },
    setConfirmTZ() {
      self.confirmTZ = true;
    },
    setOtp(otp: string) {
      self.errors.delete('otp');
      self.otp = otp;
    },
    preFillForm(data: { [key: string]: string }) {
      this.setKidName(data['kidName'] || self.kidName);
      this.setParentEmail(data['parentEmail'] || self.parentEmail);
      this.setPhone(data['phone'] || self.phone);
      this.setKidSchool(data['kidSchool'] || self.kidSchool, self.kidSchoolPreset);
      this.setGrade(data['grade'] || self.grade);
    },
    signup: flow(function* () {
      self.loading = true;
      self.clearErrors();

      try {
        const response: ApiResponse<any> = yield signup({
          parentName: self.parentName,
          parentEmail: self.parentEmail,
          phone: self.phone,
          kidName: self.kidName,
          kidSchool: self.kidSchool,
          grade: self.grade,
          extraInfo: self.extraInfo as Map<string, string>,
          otp: self.otp,
          timezone: getTimezone(),
        });
        if (response.problem) {
          if (response.status === 400) {
            const { otp, ...otherErrors } = response.data;
            self.errors.merge(otherErrors);
            self.otpError = (otp || '').toString();
          } else {
            self.otherErrors = SERVER_ERROR_MESSAGE;
          }
          return;
        }
        if (response.status === 201) {
          const { data } = response;
          getEnv(self).commonStore.setToken(data.auth_token);
        }
      } finally {
        self.loading = false;
      }
    }),
    guestLogin: flow(function* () {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield guestLogin({
          kidName: self.kidName,
          grade: self.grade,
          extraInfo: self.extraInfo as Map<string, string>,
          phone: self.phone,
          school: self.kidSchool,
        });
        if (response.problem) {
          if (response.status === 400) {
            self.errors.merge(response.data);
          }
        }
        if (response.status === 201) {
          self.username = response.data.username;
          getEnv(self).commonStore.setToken(response.data.auth_token);
        }
        return response;
      } finally {
        self.loading = false;
      }
    }),
    updateGuest: flow(function* (username: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield updateGuest(username, {
          kidName: self.kidName,
          grade: self.grade,
          extraInfo: self.extraInfo as Map<string, string>,
          phone: self.phone,
          school: self.kidSchool,
        });
        return response;
      } finally {
        self.loading = false;
      }
    }),
    afterCreate() {
      const initialData = JSON.parse(sessionStorage.getItem('signUp') || '{}');
      if (SignUpStore.is(initialData)) applySnapshot(self, initialData);

      onSnapshot(self, (snapshot) => {
        const toSave = { ...snapshot, errors: {}, loading: false, otherErrors: '', otp: '' };
        sessionStorage.setItem('signUp', JSON.stringify(toSave));
      });
    },
  }))
  .views((self) => ({
    get isClean() {
      return self.otherErrors === '' && self.errors.size === 0;
    },
    get hasValidPhone() {
      if (self.phone === '') return false;
      if ((self.phone.startsWith('91') || self.phone.startsWith('+91')) && !PHONE_REGEX_INDIA.test(self.phone))
        return false;
      return self.phone.length > 6;
    },
    get shouldConfirmTZ() {
      if (self.confirmTZ) {
        return false;
      }
      if (self.phone.startsWith('91') || self.phone.startsWith('+91')) {
        const tz = self.timezone;
        return !(tz === 'Asia/Kolkata' || tz === 'Asia/Calcutta');
      }
      return false;
    }
  }));

export default SignUpStore;

export interface ISignUpStore extends Instance<typeof SignUpStore> {}
