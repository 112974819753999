import * as Sentry from '@sentry/browser';
import { IReactionDisposer, reaction } from 'mobx';
import {
  applyPatch,
  applySnapshot,
  getEnv,
  getPropertyMembers,
  IDisposer,
  IJsonPatch,
  Instance,
  resolvePath,
  types,
} from 'mobx-state-tree';
import Config from '../config';
import { RTWatchables } from './RTWatchables';
import Socket from './Socket';
import querystring from 'query-string';
import { SentrySeverityLevel } from 'helpersObjects/Sentry';

const EPOCH = 1600000000000;

const reported: { [key: string]: boolean } = {};

function errorWrapped(cb: () => void, errExtraCb: () => { [key: string]: string }) {
  try {
    cb();
    return true;
  } catch (e) {
    // Missing object!
    const err = e as Error;
    console.error(err);

    if (!(err.message in reported)) {
      Sentry.configureScope(function (scope) {
        const errExtra = errExtraCb();
        Object.keys(errExtra).forEach((key) => {
          scope.setExtra(key, errExtra[key]);
        });

        Sentry.captureException(err);
      });

      reported[err.message] = true;
    }
    return false;
  }
}

const SubData = types
  .model({
    lastMsgId: 0,
    status: types.union(
      types.literal('subscribing'),
      types.literal('subscribed'),
      types.literal('error'),
      types.literal('unsubscribing'),
      types.literal('unsubscribed'),
      types.literal('wait')
    ),
    error: types.maybe(types.string),
    subCount: 1,
  })
  .actions((self) => ({
    setStatus(
      status: 'wait' | 'subscribing' | 'subscribed' | 'error' | 'unsubscribing' | 'unsubscribed',
      error?: string
    ) {
      self.status = status;
      self.error = error;
    },
    setLastMessageID(id: number) {
      self.lastMsgId = id;
    },
    incrSubCount() {
      self.subCount += 1;
    },
    decrSubCount() {
      self.subCount -= 1;
    },
  }));

export interface ISubData extends Instance<typeof SubData> {}

const RTControl = types
  .model('RTControl', {
    watchables: RTWatchables,
    socket: types.maybe(Socket),
    delta: types.optional(types.number, 0),
    subscriptions: types.map(SubData),
  })
  .actions((self) => ({
    setDelta(delta: number) {
      self.delta = delta;
    },
    getSocket() {
      if (!self.socket) {
        const commonStore = getEnv(self).commonStore;
        const impersonateUser = window.localStorage.getItem('impersonate') || undefined;
        const query = {
          impersonate: impersonateUser,
          token: commonStore.token,
        };
        const url = querystring.stringifyUrl({ url: `${Config.SOCKET_BASE_URL}/ws/rt`, query });
        self.socket = Socket.create({ url });
      }
      return self.socket;
    },
    closeSocket() {
      self.socket?.closeSocket();
      self.socket = undefined;
    },
    onError() {
      getEnv(self).commonStore.setNetworkProblem('Socket Error');
    },
    onOpen() {
      getEnv(self).commonStore.dismissNetworkProblem();
    },
  }))
  .actions((self) => ({
    subscribe(resource_class: string, resource_id: string) {
      if (process.env.NODE_ENV === 'development') {
        const defn = getPropertyMembers(self.watchables);
        if (!(resource_class in defn.properties)) {
          throw new Error('Unknown resource ' + resource_class);
        }
      }
      const key = `${resource_class}::${resource_id}`;
      const socket = self.getSocket();
      // console.log('SUB', key);
      let existingSubscription = self.subscriptions.get(key);
      if (existingSubscription) {
        existingSubscription.incrSubCount();
        if (existingSubscription.status === 'subscribed') {
          // console.log('SUB cancel', key);
          return Promise.resolve(true);
        }
      } else {
        self.subscriptions.set(key, SubData.create({ status: 'wait', subCount: 1 }));
        existingSubscription = self.subscriptions.get(key);
      }

      Sentry.addBreadcrumb({
        category: 'socket',
        message: `Subscribe request ${key}`,
        level: SentrySeverityLevel.Info,
      });

      return new Promise((resolve, reject) => {
        if (
          existingSubscription &&
          (existingSubscription.status === 'wait' ||
            existingSubscription.status === 'unsubscribed' ||
            existingSubscription.status === 'unsubscribing' ||
            existingSubscription.status === 'error')
        ) {
          if (socket.send({ cmd: 'subscribe', resource_class, resource_id })) {
            existingSubscription.setStatus('subscribing');
          }
        }

        let disposer: IReactionDisposer | null = null;

        const timeout = setTimeout(() => {
          reject(new Error('subtimeout'));
          if (disposer) disposer();
        }, 15000);

        disposer = reaction(
          () => {
            return [existingSubscription?.status, existingSubscription?.error];
          },
          ([status, err], _, ref) => {
            if (status === 'subscribed') {
              ref.dispose();
              resolve(true);
              clearTimeout(timeout);
            }
            if (status === 'error') {
              ref.dispose();
              reject(new Error(err));
              clearTimeout(timeout);
            }

            if (status === 'unsubscribing') {
              ref.dispose();
              reject(new Error('unsubscribed'));
              clearTimeout(timeout);
            }
          }
        );
      });
    },
    unsubscribe(resource_class: string, resource_id: string) {
      const key = `${resource_class}::${resource_id}`;
      const existingSubscription = self.subscriptions.get(key);
      // console.log('UNSUB', key);
      if (!existingSubscription) return;
      existingSubscription.decrSubCount();
      if (existingSubscription.subCount > 0) {
        // console.log('UNSUB cancel', key);
        return;
      }

      existingSubscription.setStatus('unsubscribing');

      const socket = self.getSocket();
      socket.send({ cmd: 'unsubscribe', resource_class, resource_id });

      Sentry.addBreadcrumb({
        category: 'socket',
        message: `Unsubscribe request ${key}`,
        level: SentrySeverityLevel.Info,
      });
    },
  }))
  .actions((self) => {
    var _applying = false;

    function processDelta(ms: number) {
      if (typeof ms === 'undefined') {
        return;
      }

      const server_time = ms + EPOCH;
      const client_time = new Date().getTime();
      const delta = client_time - server_time;
      if (Math.abs(delta - self.delta) > 30) {
        self.setDelta(delta);
      }
    }

    function onMessage(data: any) {
      if (data.cmd === 'ping') {
        processDelta(data.stat.ms);
        const socket = self.getSocket();
        socket.send({ ...data, cmd: 'pong' });
      } else if (data.cmd === 'hello') {
        processDelta(data.ms);
        const resub: Array<{ resource_class: string; resource_id: string; last_msg_id: number; status: string }> = [];
        self.subscriptions.forEach((subData, k) => {
          const key = String(k);
          const [resource_class, resource_id] = key.split('::');
          if (subData.status !== 'wait')
            resub.push({ resource_class, resource_id, last_msg_id: subData.lastMsgId, status: subData.status });
        });

        const socket = self.getSocket();
        if (resub.length > 0) {
          socket.send({ cmd: 'resub', resub });
        }
        socket.flushBuffer();
      } else if (data.cmd === 'patch') {
        _applying = true;

        const { resource_class, resource_id, msg_id } = data;

        const key = `${resource_class}::${resource_id}`;
        const subscription = self.subscriptions.get(key);
        if (!subscription || subscription.status !== 'subscribed') return;

        if (msg_id) {
          subscription.setLastMessageID(msg_id);
        }

        let rtObject: any;

        errorWrapped(
          () => {
            rtObject = resolvePath(self.watchables, `/${resource_class}/${resource_id}`);
          },
          () => ({
            patch: JSON.stringify(data.patch),
            resource: key,
          })
        );

        if (rtObject) {
          errorWrapped(
            () => {
              const _apply = (patch: any) => {
                if (patch.path === '/') applySnapshot(rtObject, { resource_class, resource_id, ...patch.value });
                else applyPatch(rtObject, patch);
              };

              const patch = data.patch;
              if (patch.op === 'multi') {
                patch.patch.forEach(_apply);
              } else {
                _apply(patch);
              }
            },
            () => ({
              patch: JSON.stringify(data.patch),
              state: JSON.stringify(rtObject),
              resource: key,
            })
          );
        }

        _applying = false;
      } else if (data.cmd === 'err') {
        const socket = self.getSocket();
        socket.closeSocket();
        self.socket = undefined;
      } else if (data.cmd === 'subscribe_success') {
        const { resource_class, resource_id, data: initial_data } = data;
        const key = `${resource_class}::${resource_id}`;
        const resourceMap = resolvePath(self.watchables, `/${resource_class}`);

        _applying = true;
        errorWrapped(
          () => {
            resourceMap.set(resource_id, { resource_class, resource_id, ...initial_data });
          },
          () => ({
            state: JSON.stringify(initial_data),
            resource: key,
          })
        );
        _applying = false;

        const subData = self.subscriptions.get(key);
        if (subData?.status !== 'unsubscribing') {
          subData?.setStatus('subscribed');
        } else {
          return;
        }

        Sentry.addBreadcrumb({
          category: 'socket',
          message: `Subscribe success ${key}`,
          level: SentrySeverityLevel.Info,
        });
      } else if (data.cmd === 'subscribe_error') {
        const { resource_class, resource_id, reason } = data;

        const key = `${resource_class}::${resource_id}`;
        const subData = self.subscriptions.get(key);
        subData?.setStatus('error', reason);

        Sentry.addBreadcrumb({
          category: 'socket',
          message: `Subscribe error ${key}`,
          level: SentrySeverityLevel.Error,
        });
      } else if (data.cmd === 'unsubscribe_success') {
        const { resource_class, resource_id } = data;

        const resourceMap = resolvePath(self.watchables, `/${resource_class}`);
        resourceMap.delete(resource_id);

        const key = `${resource_class}::${resource_id}`;
        const subData = self.subscriptions.get(key);

        if (subData?.status !== 'subscribing') {
          subData?.setStatus('unsubscribed');
        }

        Sentry.addBreadcrumb({
          category: 'socket',
          message: `Unsubscribe success ${key}`,
          level: SentrySeverityLevel.Info,
        });
      }
    }

    function sendStateUpdate(resource_class: string, resource_id: string, data: IJsonPatch) {
      if (_applying) {
        return;
      }
      //console.log("<<<", JSON.stringify(data));
      const socket = self.getSocket();
      socket.send({ cmd: 'patch', patch: data, resource_class, resource_id });
    }

    return {
      onMessage,
      sendStateUpdate,
    };
  })
  .views((self) => ({
    get connectionState() {
      return self.socket?.connectionState;
    },
  }))
  .actions((self) => {
    let disposer: IDisposer | null = null;
    function afterCreate() {
      const commonStore = getEnv(self).commonStore;
      disposer = reaction(
        () => commonStore.token,
        () => {
          if (self.socket) {
            self.closeSocket();
          }
        }
      );
    }

    function beforeDestroy() {
      disposer && disposer();
    }

    return { afterCreate, beforeDestroy };
  });

export default RTControl;
