import { Instance, types } from 'mobx-state-tree';
import { RTModel } from './RTModel';
import InvokerType from './InvokerType';

const AiChatMessages = types.model({
  user: types.string,
  content: types.string,
  created_at: types.string,
  message_id: types.string,
  audio_url: types.maybeNull(types.string),
});

export interface IAiChatMessages extends Instance<typeof AiChatMessages> {}

const AIEvaluatorMessage = types.model({
  name: types.string,
  username: types.string,
  response_message: types.string,
  response_score: types.number,
  response_result: types.union(
    types.literal('continue'),
    types.literal('retry'),
    types.literal('nudge'),
    types.literal('stop')
  ),
});

const AIChatState = RTModel.named('AIChatState')
  .props({
    messages: types.array(AiChatMessages),
    recording: types.frozen({}),
    chat_room: types.string,
    activeMap: types.frozen({}),
    locked: false,
    lockedUserMap: types.frozen({}),
    end_conversation: false,
    error: types.maybeNull(types.string),
    status: types.maybeNull(
      types.union(
        types.literal('ProcessingUserMessage'),
        types.literal('AiProcessing'),
        types.literal('AiProcessingDone')
      )
    ),
    ai_evaluator_messages: types.array(AIEvaluatorMessage),
    add: InvokerType,
  })
  .views((self) => ({}))
  .actions((self) => ({
    addMessage(url: string) {
      return self.add.invoke({ url });
    },
  }));

export default AIChatState;
export interface IAIChatState extends Instance<typeof AIChatState> {}
