import { Instance, types } from 'mobx-state-tree';
import { ActivityDetail } from './ActivityDetail';
import { StudentActivity } from './StudentActivity';
import { ActivityState } from './ActivityStates';
import InvokerType from './InvokerType';
import { reaction, toJS } from 'mobx';

export const Activity = types.model({
  submitted: false,
  activity_type: types.maybe(types.string),
  activity_detail: types.maybe(ActivityDetail),
  activity_state: types.maybe(ActivityState),
  student_activity: types.maybe(StudentActivity),
});

export interface IActivity extends Instance<typeof Activity> {}

export const ActivityStore = types
  .model('ActivityStore', {
    currentIndex: 0,
    activityName: types.maybeNull(types.string),
    activityMap: types.map(Activity),
    started: InvokerType,
    refresh: InvokerType,
    submitted: InvokerType,
    completed: InvokerType,
    move: InvokerType,
    fetchActivity: InvokerType,
    externalProjectURLDetails: types.maybeNull(types.string),
    setExternalProjectURLInvoker: InvokerType,
    externalProjectURL: types.maybeNull(types.string),
    displayExternalURLProjectDialog: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    moveToIndex(index: number) {
      return self.move.invoke({ index });
    },
    markStarted() {
      return self.started.invoke();
    },
    markCompleted() {
      return self.completed.invoke();
    },
    refreshActivity() {
      return self.refresh.invoke();
    },
    submitActivityGeneric(student_activity: any) {
      return self.submitted.invoke({
        submitted_activity: JSON.parse(JSON.stringify(toJS(student_activity))),
      });
    },
    fetchActivityByName(activity_name: string) {
      return self.fetchActivity.invoke({ activity_name });
    },
    setExternalProjectURL: (value: string) => {
      self.externalProjectURL = value;
    },
    setDisplayExternalURLProjectDialog: (value: boolean) => {
      self.displayExternalURLProjectDialog = value;
    },
  }))
  .actions((self) => ({
    setExternalProjectURLInvokerAction: (value: string) => {
      self.setExternalProjectURLInvoker.invoke({ url: value }).then((res) => {
        self.setExternalProjectURL(value);
      });
    },
    submitActivity: (student_activity: any) => {
      if (!self.externalProjectURLDetails) {
        return self.submitActivityGeneric(student_activity);
      }

      self.setDisplayExternalURLProjectDialog(true);

      return new Promise<any>((resolve, rej) => {
        reaction(
          () => self.externalProjectURL,
          (externalProjectURL) => {
            if (!externalProjectURL) return;
            self.submitActivityGeneric(student_activity).then((res) => {
              self.setDisplayExternalURLProjectDialog(false);
              resolve(res);
            });
          }
        );
      });
    },
  }))
  .views((self) => ({
    get currentActivity() {
      if (!self.activityName) {
        return undefined;
      }

      const act = self.activityMap.get(self.activityName);
      if (!act?.activity_type) {
        return undefined;
      }
      return act;
    },
  }));

export interface IActivityStore extends Instance<typeof ActivityStore> {}
