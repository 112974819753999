import { getFirebaseToken } from '../api';
import { types, flow, Instance } from 'mobx-state-tree';
import { ApiResponse } from 'apisauce';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

const FirebaseAuthStore = types
  .model('FirebaseAuthStore', {
    firebaseToken: types.maybeNull(types.string),
    fetchingFirebaseToken: false,
    loggingInToFirebase: false,
    loggedIn: false,
  })
  .actions((self) => ({
    setFirebaseToken: flow(function* () {
      self.fetchingFirebaseToken = true;
      self.loggedIn = false;
      try {
        const response: ApiResponse<any> = yield getFirebaseToken();
        if (response.problem) {
          return;
        }
        self.firebaseToken = response.data.token;
        const auth = getAuth();
        if (!auth.currentUser && self.firebaseToken && self.loggingInToFirebase === false) {
          self.loggingInToFirebase = true;
          const firebaseResponse = yield signInWithCustomToken(auth, self.firebaseToken);
          if (firebaseResponse.problem) {
            return;
          }
          self.loggedIn = true;
        }
      } finally {
        self.loggingInToFirebase = false;
        self.fetchingFirebaseToken = false;
      }
    }),
  }));

export default FirebaseAuthStore;
export interface IUFirebaseAuthStore extends Instance<typeof FirebaseAuthStore> {}
