import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles()({
  notFound: {
    width: '50%',
    margin: '100px auto',
  },
});

const NotFoundPage = ({ itemName, extraInfo='' }: { itemName?: string, extraInfo?: string }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.notFound}>
      <Helmet>
        <title>{itemName || 'Page'} Not Found :: StayQrious</title>
      </Helmet>
      <h1>{itemName || ''} Not Found</h1>
      <Link to="/">Go Home</Link>
      <br />
      {extraInfo}
    </div>
  );
};

export default NotFoundPage;
