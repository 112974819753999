import { IReactionDisposer, reaction } from 'mobx';
import { types, Instance } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

const DailyCOConfig = types.model('DailyCOConfig', {
  url: types.string,
  token: types.string,
});

export interface IDailyCOConfig extends Instance<typeof DailyCOConfig> {}

export const MediaStore = types
  .model('MediaStore', {
    audioMuted: false,
    videoMuted: false,
    audioOutMuted: false,
    noiseCancellationEnabled: false,
  })
  .actions((self) => ({
    toggleVideo() {
      self.videoMuted = !self.videoMuted;
    },

    toggleAudio() {
      self.audioMuted = !self.audioMuted;
    },
    setAudioMuted(state: boolean) {
      self.audioMuted = state;
    },
    setVideoMuted(state: boolean) {
      self.videoMuted = state;
    },
    toggleAudioOut() {
      self.audioOutMuted = !self.audioOutMuted;
    },
    toggleNoiseCancellation() {
      self.noiseCancellationEnabled = !self.noiseCancellationEnabled;
    },
  }))
  .actions((self) => {
    let disposerAudio: IReactionDisposer | null = null;
    let disposerVideo: IReactionDisposer | null = null;

    function localize() {
      const storedVideo = window.localStorage.getItem('MediaStore.videoMuted');
      if (storedVideo) self.videoMuted = storedVideo === '1';

      const storedAudio = window.localStorage.getItem('MediaStore.audioMuted');
      if (storedAudio) self.audioMuted = storedAudio === '1';

      disposerVideo = reaction(
        () => self.videoMuted,
        (muted) => {
          window.localStorage.setItem('MediaStore.videoMuted', muted ? '1' : '0');
        }
      );

      disposerAudio = reaction(
        () => self.audioMuted,
        (muted) => {
          window.localStorage.setItem('MediaStore.audioMuted', muted ? '1' : '0');
        }
      );
    }

    function beforeDestroy() {
      disposerVideo && disposerVideo();
      disposerAudio && disposerAudio();
    }
    return { localize, beforeDestroy };
  });

export interface IMediaStore extends Instance<typeof MediaStore> {}

export const CallStore = RTModel.named('CallStore')
  .props({
    mediaStoreMap: types.map(MediaStore),
    roomName: types.maybe(types.string),
    refresh_dailyco_call: InvokerType,
  })
  .actions((self) => ({
    refreshDailyCOCall(create_new = false) {
      return self.refresh_dailyco_call.invoke({ create_new }) as Promise<IDailyCOConfig>;
    },
    setMuteStateForAll(mute: boolean) {
      self.mediaStoreMap.forEach((remoteControl) => {
        remoteControl.setAudioMuted(mute);
      });
    },
  }));

export interface ICallStore extends Instance<typeof CallStore> {}
