import { Instance } from 'mobx-state-tree';
import CallSessionState from './CallSessionState';


const LDSessionState = CallSessionState.named('LDSessionState').props({
    rescheduleLinkRelative: '',
  });


export default LDSessionState;
export interface ILDSessionState extends Instance<typeof LDSessionState> {}

