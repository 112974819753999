import { saveBrowserCheck } from 'api';
import config from 'config';
import { flow, Instance, types } from 'mobx-state-tree';
import { RTModel } from './RTModel';

const CHECK_INTERVAL = 24 * 3600 * 1000 * 30; // a month now!

const BrowserCheck = types
  .model('BrowserCheck', {
    check_id: types.maybeNull(types.string),
    lastChecked: types.maybe(types.Date),
  })
  .actions((self) => ({
    save: flow(function* (meta?: object) {
      let locale = {};
      try {
        locale = Intl.DateTimeFormat().resolvedOptions();
      } catch (e) {}

      const browserCheck = {
        check_id: self.check_id ? self.check_id : undefined,
        user_agent: navigator.userAgent,
        platform: navigator.platform,
        timezone_offset: new Date().getTimezoneOffset(),
        passed_at: self.lastChecked,
        locale: locale,
        version: config.VERSION,
        commit: config.COMMIT,
        meta,
      };

      const response = yield saveBrowserCheck({ browserCheck });
      if (response.problem) {
        return;
      }
      self.check_id = response.data.check_id as string;
      self.lastChecked = response.data.passed_at ? new Date(response.data.passed_at) : undefined;

      window.localStorage.setItem('browserCheckId', self.check_id);
    }),
    afterCreate() {
      self.check_id = window.localStorage.getItem('browserCheckId');
      const lastChecked = window.localStorage.getItem('browserCheckedAt');
      if (lastChecked) {
        self.lastChecked = new Date(lastChecked);
      }
    },
  }))
  .views((self) => ({
    get needsCheck() {
      if (!self.lastChecked) {
        return true;
      }

      return new Date().getTime() - self.lastChecked.getTime() > CHECK_INTERVAL;
    },
  }));

export default BrowserCheck;

export const BrowserState = RTModel.named('BrowserState')
  .props({
    cameraPermission: types.optional(types.string, 'unknown'),
    micPermission: types.optional(types.string, 'unknown'),
    cameraWorking: types.optional(types.string, 'unknown'),
    browserError: types.optional(types.string, 'unknown'),
    screen: types.optional(types.string, 'unknown'),
    micWorking: types.optional(types.string, 'unknown'),
    meta: types.frozen({}),
    permError: false,
    browserCheck: false,
    isDone: false,
    isOnline: false
  })
  .actions((self) => ({
    init() {
      self.cameraPermission = 'unknown';
      self.micPermission = 'unknown';
      self.cameraWorking = 'unknown';
      self.browserError = 'unknown';
      self.screen = 'unknown';
      self.micWorking = 'unknown';
      self.meta = {};
      self.permError = false;
      self.browserCheck = false;
      self.isDone = false;
    },
    setCameraPermission(state: string) {
      self.cameraPermission = state;
    },
    setMicPermission(state: string) {
      self.micPermission = state;
    },
    setCameraWorking(state: string) {
      self.cameraWorking = state;
    },
    setBrowserError(browser: string) {
      self.browserError = browser;
    },
    setScreen(screen: string) {
      self.screen = screen;
    },
    setMicWorking(state: string) {
      self.micWorking = state;
    },
    setBrowserMeta(state: object) {
      self.meta = state;
    },
    setPermError(state: boolean) {
      self.permError = state;
    },
    setBrowserCheck(state: boolean) {
      self.browserCheck = state;
    },
    setIsDone(state: boolean) {
      self.isDone = state;
    },
  }));

export interface IBrowserState extends Instance<typeof BrowserState> {}
