import { Instance } from 'mobx-state-tree';
import { RTModel } from './RTModel';

const TutorSessionCoachState = RTModel.named('TutorSessionCoachState').props({
  is_superuser: false,
  can_use_ops_tools: false,
  reports_generated: false,
});

export default TutorSessionCoachState;
export interface ITutorSessionCoachState extends Instance<typeof TutorSessionCoachState> {}
