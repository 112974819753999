import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStores } from 'hooks/useStores';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

export interface IProtectedRouteProps extends RouteProps {
  anonymousComponent?: React.ReactNode;
  roles?: Array<string>;
}

function ProtectedRoute({
  anonymousComponent = undefined,
  roles = ['student', 'coach'],
  children,
  ...props
}: IProtectedRouteProps) {
  const { userStore } = useStores();
  if (!userStore.currentUser) {
    if (anonymousComponent) {
      return (
        <Route
          {...props}
          render={(props) => React.cloneElement(anonymousComponent as React.ReactElement<any>, props)}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: window.localStorage.getItem('hasSeen') ? '/login' : '/signup',
          state: { from: props.location },
        }}
      />
    );
  }

  if (roles && userStore.role && !roles.includes(userStore.role)) {
    return <Redirect to="/" />;
  }

  return <Route {...props}>{children}</Route>;
}

export default observer(ProtectedRoute);
