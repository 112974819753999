import { types, Instance } from 'mobx-state-tree';

export interface Coordinate {
  x: number;
  y: number;
}

const PointerStore = types
  .model('PointerStore', {
    mousePos: types.map(types.frozen({ x: -100, y: -100 })),
    showControls: false,
    drawingMode: false,
    activeColor: '',
    mode: types.optional(types.union(types.literal('free'), types.literal('erase')), 'free'),
  })
  .actions((self) => ({
    setMousePos(username: string, pos: Coordinate) {
      self.mousePos.set(username, pos);
    },
    clear() {
      self.mousePos.clear();
    },
    setShowControls(val: boolean) {
      self.showControls = val;
    },
    setActiveColor(val: string) {
      self.activeColor = val;
    },
    setMode(val: 'free' | 'erase') {
      self.mode = val;
    },
    setDrawingMode(mode: boolean) {
      self.drawingMode = mode;
    },
  }));

export default PointerStore;
export interface IPointerStore extends Instance<typeof PointerStore> {}
