import { checkHasPhone } from 'api';
import { ApiResponse } from 'apisauce';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useState } from 'react';

interface IHasPhoneContext {
  checkHasPhone: (username: string) => void;
  userResults: { [key: string]: boolean };
}

const HasPhoneContext = React.createContext<IHasPhoneContext | null>(null);

interface HasPhoneProviderProps {
  children: React.ReactNode;
}

export function HasPhoneProvider({ children }: HasPhoneProviderProps) {
  const [hasPhoneResults, setHasPhoneResults] = useState<{ [key: string]: boolean }>({});
  const [pendingChecks, setPendingChecks] = useState<string[]>([]);

  useEffect(() => {
    if (pendingChecks.length === 0) return;
    const checkPhone = async () => {
      const resp: ApiResponse<any> = await checkHasPhone(pendingChecks);
      setHasPhoneResults((oldResults) => ({ ...oldResults, ...resp.data }));
      setPendingChecks([]);
    };
    const iid = window.setTimeout(() => checkPhone(), 10);
    return () => window.clearTimeout(iid);
  }, [pendingChecks]);

  const addUsernameToChecklist = useCallback((username: string) => {
    setPendingChecks((oldChecks) => [...oldChecks, username]);
  }, []);

  return (
    <HasPhoneContext.Provider value={{ checkHasPhone: addUsernameToChecklist, userResults: hasPhoneResults }}>
      {children}
    </HasPhoneContext.Provider>
  );
}

interface HasPhoneProps {
  username: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

function HasPhone({ username, fallback = null, children }: HasPhoneProps) {
  const hasPhoneCtx = useContext(HasPhoneContext);
  const checkHasPhone = hasPhoneCtx?.checkHasPhone;

  useEffect(() => {
    checkHasPhone && checkHasPhone(username);
  }, [checkHasPhone, username]);

  const hasPhone = hasPhoneCtx?.userResults && hasPhoneCtx.userResults[username];

  return <>{hasPhone ? children : fallback}</>;
}

export default observer(HasPhone);
