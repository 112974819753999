import { types, Instance } from 'mobx-state-tree';
import { Question, IQuestion } from './Question';
import { toJS } from 'mobx';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

export const PollResultRow = types.model('PollResultRow', {
  count: 0,
  perc: 0,
  users: types.array(types.string),
});
export interface IPollResultRow extends Instance<typeof PollResultRow> {}

export const PollResult = types.model('PollResult', {
  total: 0,
  results: types.map(PollResultRow),
});
export interface IPollResult extends Instance<typeof PollResult> {}

export const PollStore = types
  .model('PollStore', {
    activityName: '',
    question: types.maybeNull(Question),
    result: types.maybeNull(PollResult),
    minimize: false,
    roomName: types.string,
    submitInvoke: InvokerType,
    clearInvoke: InvokerType,
    setQuestionInvoke: InvokerType,
    setQuestionByNameInvoke: InvokerType,
    showResultInvoke: InvokerType,
    hideResultInvoke: InvokerType,
  })
  .actions((self) => ({
    setQuestion(question: IQuestion, activityName: string) {
      return self.setQuestionInvoke.invoke({ question: toJS(question), activity_name: activityName });
    },
    setQuestionByName(name: string) {
      return self.setQuestionByNameInvoke.invoke({ name });
    },
    submitAnswer(answers: Array<string>) {
      return self.submitInvoke.invoke({ answers });
    },
    clearQuestion() {
      return self.clearInvoke.invoke();
    },
    showResults(showUsers: boolean) {
      return self.showResultInvoke.invoke({ show_users: showUsers });
    },
    hideResults() {
      return self.hideResultInvoke.invoke();
    },
    setMinimize(val: boolean) {
      self.minimize = val;
    },
  }))
  .views((self) => ({
    get isActive() {
      return !!self.question;
    },
  }));

export interface IPollStore extends Instance<typeof PollStore> {}

export const PollUserStore = RTModel.named('PollUserStore').props({
  answerMap: types.map(types.array(types.string)),
});

export interface IPollUserStore extends Instance<typeof PollUserStore> {}

export const PollResultStore = RTModel.named('PollResultStore').props({
  result: types.maybeNull(PollResult),
});

export interface IPollResultStore extends Instance<typeof PollResultStore> {}
