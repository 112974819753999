import {
  fetchBatchProjectFeedback,
  fetchBatchProjectFeedbackList,
  fetchBatchProjectFeedbackReview,
  fetchBatchStudentProjectFeedback,
} from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { Batch, BatchStudentLite } from './coachStores/BatchStore';
import User from './User';

export const BatchStudentProject = types
  .model('BatchStudentProject', {
    name: types.string,
    batch_student: BatchStudentLite,
    batch_coach: User,
    coach: User,
    project_image: types.maybeNull(types.string),
    presentation_video: types.maybeNull(types.string),
    project_link: types.maybeNull(types.string),
    certificate: types.maybeNull(types.string),
    approved: types.maybeNull(types.boolean),
    loading: false,
    project_title: types.maybeNull(types.string),
    project_preview: types.maybeNull(types.string)
  })
  .actions((self) => ({
    giveFeedback: flow(function* ({ approved, feedback }: { approved?: boolean; feedback?: string }) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchBatchProjectFeedbackReview(self.name, approved, feedback);
        if (response.ok) {
          if (approved !== undefined) {
            self.approved = approved;
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        self.loading = false;
      }
    }),
  }));

export interface IBatchStudentProject extends Instance<typeof BatchStudentProject> {}

export const BatchProject = types.model('BatchProject', {
  batch: Batch,
  project: types.string,
  pending_approval_count: types.number,
  created_at: types.string,
});

export interface IBatchProject extends Instance<typeof BatchProject> {}

export const BatchProjectFeedbackStore = types
  .model('BatchGalleryStore', {
    studentProjects: types.array(BatchStudentProject),
    batchprojects: types.array(BatchStudentProject),
    batchProjectsList: types.array(BatchProject),
    loading: false,
  })
  .actions((self) => ({
    fetchProjectList: flow(function* (batchName?: string, coachUsername?: string, onlyCertificateProject?: boolean) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchBatchProjectFeedbackList(
          batchName,
          coachUsername,
          onlyCertificateProject
        );
        if (response.problem) {
          if (response.status === 404) {
            getEnv(self).commonStore.setNetworkProblem(response.data.error);
          } else {
            getEnv(self).commonStore.setNetworkProblem(response.problem);
          }
          return;
        }
        self.batchProjectsList = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
    fetchBatchProjects: flow(function* (batchName: string, projectName: string, preview: boolean) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchBatchProjectFeedback(batchName, projectName, preview);
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.batchprojects = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
    fetchBatchStudentProjects: flow(function* (batchStudentName: string) {
      self.loading = true;
      self.studentProjects.clear();
      try {
        const response: ApiResponse<any> = yield fetchBatchStudentProjectFeedback(batchStudentName);
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.studentProjects = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
  }));

export interface IBatchProjectFeedbackStore extends Instance<typeof BatchProjectFeedbackStore> {}
