import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import 'polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'typeface-cabin';
import 'typeface-lato';
import 'typeface-quicksand';
import App from './App';
import Config from './config';
import './index.css';
import * as serviceWorker from './serviceWorker';

if (Config.SENTRY_DSN) {
  const eventLimiter: { [key: string]: boolean } = {};
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    release: Config.COMMIT,
    environment: Config.APP_ENV,
    sampleRate: 0.25,
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      /getReadModeConfig/,
      /getReadModeRender/,
      /getReadModeExtract/,
    ],
    beforeSend: (event, hint) => {
      const msg = (hint?.originalException as Error | undefined)?.message as string;

      // do not send event if already sent in last 1 minute
      if (msg && msg in eventLimiter) {
        console.log('Rate limiting activated for', msg);
        return null;
      }

      eventLimiter[msg] = true;

      setTimeout(() => {
        delete eventLimiter[msg];
      }, 300 * 1000); // 5 minutes?

      return event;
    },
    denyUrls: [/^file:\/\//],
  });
}

if (Config.MIXPANEL_TOKEN) {
  mixpanel.init(Config.MIXPANEL_TOKEN);
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

declare global {
  interface Window {
    React: any;
    ReactDOM: any;
    withStyles: any;
  }
}

//// export
window.React = React;
window.ReactDOM = ReactDOM;
