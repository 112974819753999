/// Student activities

import { postProjectToken } from 'api';
import { ApiResponse } from 'apisauce';
import { types, Instance, cast, IAnyModelType, flow } from 'mobx-state-tree';
import { Question } from './Question';
import { TranscodedVideo } from './TranscodedVideoStore';

export const ReactQuestionAnswer = types
  .model('ReactQuestionAnswer', {
    id: types.maybeNull(types.integer),
    content: types.string,
    student: types.frozen({}),
    feedback_answer: types.maybeNull(types.late((): IAnyModelType => ReactFeedbackAnswer)),
    feedback_answer_id: types.maybeNull(types.integer),
    activity_type: types.literal('react question answer'),
    transcoded_video: types.maybeNull(TranscodedVideo),
  })
  .actions((self) => ({
    setAnswer(text: string) {
      self.content = text;
    },
  }));

export interface IReactQuestionAnswer extends Instance<typeof ReactQuestionAnswer> {}

const FeedbackAnswer = types.model('FeedbackAnswer', {
  feedback_question: types.integer,
  content: types.string,
});

export interface IFeedbackAnswer extends Instance<typeof FeedbackAnswer> {}

export const ReactFeedbackAnswer = types
  .model('ReactFeedbackAnswer', {
    id: types.maybeNull(types.integer),
    badges: types.array(types.string),
    feedback_answers: types.array(FeedbackAnswer),
    question_answer: types.maybeNull(ReactQuestionAnswer),
    question_answer_id: types.maybeNull(types.integer),
    student: types.frozen({}),
    activity_type: types.literal('react feedback answer'),
  })
  .actions((self) => ({
    setBadges(badges: Array<string>) {
      self.badges = cast(badges);
    },
    setFeedbackAnswers(answers: Array<IFeedbackAnswer>) {
      self.feedback_answers = cast(answers);
    },
  }));

export const ChannelToken = types.model('ChannelToken', {
  token: types.string,
  channel: types.string,
  firebase_name: types.string,
  firebase_token: types.string,
});

export interface IChannelToken extends Instance<typeof ChannelToken> {}

export const CodingAnswer = types.model('CodingAnswer', {
  activity_type: types.literal('coding answer'),

  report: types.frozen({}),
  share_url: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  loader_token: types.optional(types.string, ''),
  channel_token: types.maybeNull(ChannelToken),
});

export interface ICodingAnswer extends Instance<typeof CodingAnswer> {}

export const CodingRecordingAnswer = types.model('CodingRecordingAnswer', {
  activity_type: types.literal('coding recording answer'),
  recording_url: types.string,
  coding_answer: types.maybeNull(CodingAnswer),
  coding_answer_id: types.maybeNull(types.integer),
  transcoded_video: types.maybeNull(TranscodedVideo),
});

export interface ICodingRecordingAnswer extends Instance<typeof CodingRecordingAnswer> {}

export const CodingAnnotateAnswer = types.model('CodingAnnotateAnswer', {
  activity_type: types.literal('coding annotate answer'),
  annotate_url: '',
});

export interface ICodingAnnotateAnswer extends Instance<typeof CodingAnnotateAnswer> {}

export const FormFieldResponse = types.model('FormFieldResponse', {
  field_id: types.number,
  value: types.string,
});

export interface IFormFieldResponse extends Instance<typeof FormFieldResponse> {}

export const FormResponse = types.model('FormResponse', {
  activity_type: types.literal('form response'),
  form_id: types.maybeNull(types.integer),
});

export interface IFormResponse extends Instance<typeof FormResponse> {}

export const ScreenShareAnswer = types.model('ScreenShareAnswer', {
  activity_type: types.literal('screenshareactivity student activity'),
});

export interface IScreenShareAnswer extends Instance<typeof ScreenShareAnswer> {}

export const QuizSet = types.model('QuizSet', {
  name: types.string,
  questions: types.array(Question),
});

export const QuestionAnswer = types.model('QuestionAnswer', {
  question_id: types.integer,
  answer: types.frozen({}),
  coding_answer: types.maybeNull(CodingAnswer),
});

export interface IQuestionAnswer extends Instance<typeof QuestionAnswer> {}

export const QuizResponse = types.model('QuizResponse', {
  activity_type: types.literal('quiz response'),
  quiz_set: QuizSet,
  quiz_set_id: types.integer,
  answers: types.array(QuestionAnswer),
});

export interface IQuizResponse extends Instance<typeof QuizResponse> {}

export const PollAnswer = types.model('PollAnswer', {
  activity_type: types.literal('poll answer'),
  question_answer: types.maybeNull(QuestionAnswer),
});

export interface IPollAnswer extends Instance<typeof PollAnswer> {}

export const GenericActivity = types.model('GenericActivity', {
  activity_type: types.union(
    types.literal('video student activity'),
    types.literal('htmlembedactivity student activity'),
    types.literal('unityactivity student activity'),
    types.literal('googleformactivity student activity'),
    types.literal('generic activity'),
    types.literal('conversationalrecordingactivity student activity')
  ),
});

export const RecordingActivityQuestionAnswer = types.model('RecordingQuestionAnswer', {
  question: types.number,
  answer: types.string,
});

export interface IRecordingActivityQuestionAnswer extends Instance<typeof RecordingActivityQuestionAnswer> {}

export const RecordingActivityAnswer = types
  .model('RecordingActivityAnswer', {
    activity_type: types.literal('recording activity answer'),
    answers: types.array(RecordingActivityQuestionAnswer),
    transcoded_video: types.maybeNull(TranscodedVideo),
  })
  .actions((self) => ({
    setAnswer(question: number, answer: string) {
      self.answers.push({ question, answer });
    },
  }));

export interface IRecordingActivityAnswer extends Instance<typeof RecordingActivityAnswer> {}

const P5JSToken = types
  .model('P5JSToken', {
    id: types.number,
    project_level_name: types.string,
    cloned_project_level_name: types.maybeNull(types.string),
    token: types.string,
  })
  .views((self) => ({
    get projectLevelName() {
      return self.cloned_project_level_name || self.project_level_name;
    },
    get isSaved() {
      return self.cloned_project_level_name !== self.project_level_name;
    },
  }))
  .actions((self) => ({
    saveClonedName: flow(function* (newName: string) {
      const response: ApiResponse<any> = yield postProjectToken({ id: self.id, project_level_name: newName });
      if (response.problem) return;
      self.cloned_project_level_name = newName;
      return response;
    }),
  }));

export interface IP5JSToken extends Instance<typeof P5JSToken> {}

export const P5JSAnswer = types.model('P5JSAnswer', {
  p5js_token: P5JSToken,
  activity_type: types.literal('p5js answer'),
});

export interface IP5JSAnswer extends Instance<typeof P5JSAnswer> {}

export const ActivityRecordingActivityAnswer = types.model('ActivityRecordingActivityAnswer', {
  activity_type: types.literal('activity recording activity answer'),
  student_answer: types.frozen(),
  recording_url: '',
  transcoded_video: types.maybeNull(TranscodedVideo),
});

export interface IActivityRecordingActivityAnswer extends Instance<typeof ActivityRecordingActivityAnswer> {}

export const IframeRecordingActivityAnswer = types.model('IframeRecordingActivityAnswer', {
  activity_type: types.literal('iframe recording activity answer'),
  recording_url: types.maybeNull(types.string),
  page_url: types.maybeNull(types.string),
  transcoded_video: types.maybeNull(TranscodedVideo),
});

export interface IIframeRecordingActivityAnswer extends Instance<typeof IframeRecordingActivityAnswer> {}

export const VoiceChatActivityAnswer = types.model('VoiceChatActivityAnswer', {
  activity_type: types.literal('voice chat activity answer'),
  conversation: types.maybeNull(types.string),
});

export const HTMLQuestionAnswer = types.model('HTML Question Answer', {
  activity_type: types.literal('html question answer'),
  is_done: types.maybeNull(types.boolean),
  time_taken: types.maybeNull(types.number),
  answer: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
});

export const PhotoCapturActivityAnswer = types.model('PhotoCaptureActivityAnswer', {
  activity_type: types.literal('photo capture activity answer'),
  image_url: types.string
});

export interface IPhotoCapturActivityAnswer extends Instance<typeof PhotoCapturActivityAnswer> {}

export const FileUploadActivityAnswer = types.model('FileUploadActivityAnswer', {
  activity_type: types.literal('file upload activity answer'),
  file_url: types.string
});

export interface IFileUploadActivityAnswer extends Instance<typeof FileUploadActivityAnswer> {}

export const DiscussActivityStudentActivity = types.model('DiscussActivityStudentActivity', {
  activity_type: types.literal('discussactivity student activity'),
});



export interface IVoiceChatActivityAnswer extends Instance<typeof VoiceChatActivityAnswer> {}

export const StudentActivity = types.union(
  ReactQuestionAnswer,
  ReactFeedbackAnswer,
  CodingAnswer,
  CodingRecordingAnswer,
  CodingAnnotateAnswer,
  FormResponse,
  ScreenShareAnswer,
  QuizResponse,
  PollAnswer,
  RecordingActivityAnswer,
  P5JSAnswer,
  ActivityRecordingActivityAnswer,
  GenericActivity,
  IframeRecordingActivityAnswer,
  VoiceChatActivityAnswer,
  HTMLQuestionAnswer,
  PhotoCapturActivityAnswer,
  FileUploadActivityAnswer,
  DiscussActivityStudentActivity
);

export interface IStudentActivity extends Instance<typeof StudentActivity> {}
