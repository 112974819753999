import { types, flow, cast, getEnv, Instance } from 'mobx-state-tree';
import {
  listMasterclasses as listSessions,
  getMasterclassDetail as getSessionDetail,
  listActiveSessions as listActiveSessionsAPI,
  masterClassRegister,
  listOpenMasterClassSession,
  getOpenMasterClassSession,
  askMasterclassQuestion,
  fetchRegisteredMasterclassSessions,
  listPremiumMasterclasses,
} from '../api';
import { Session, ISession } from './Session';
import { ApiResponse } from 'apisauce';
import { PageStore } from './Page';
import User from './User';

const MasterclassSchool = types.model({
  name: types.string,
  title: types.string,
  banner: types.string,
  about_content: types.string,
  can_pre_register: types.boolean,
});

const MasterclassSession = Session.named('MasterclassSession')
  .props({
    school: types.maybeNull(MasterclassSchool),
    coach: types.maybeNull(User),
    registration_question: types.maybeNull(types.string),
    short_link: types.optional(types.string, ''),
    loading: false,
  })
  .actions((self) => ({
    askQuestion: flow(function* ({ question }: { question: string }) {
      self.loading = true;
      try {
        const response = yield askMasterclassQuestion({ session_name: self.name, question: question });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
  }))
  .views((self) => ({
    get shortLink() {
      return self.short_link;
    },
  }));

export interface IMasterclassSession extends Instance<typeof MasterclassSession> {}

const MasterclassStore = types
  .model({
    currentSession: types.maybe(types.safeReference(MasterclassSession)),
    upcomingSessions: types.array(types.safeReference(MasterclassSession)),
    fetchingSession: false,
    sessions: types.array(MasterclassSession),
    errors: types.optional(types.string, ''),
    creating: false,
    upcomingSessionsPage: types.maybe(PageStore),
    redirectToSession: '',
    registeredSessionNames: types.array(types.string),
    qriositySessions: types.array(MasterclassSession.named('QriosityMasterclassSession')),
    qriositySessionsPage: types.maybe(PageStore),
    fetchingQriositySessions: false,
    loading: false,
  })
  .actions((self) => {
    function setSessions(sessions: any) {
      self.sessions = cast(sessions);
    }

    function clear() {
      self.currentSession = undefined;
      self.sessions = cast([]);
    }

    const listUpcomingSessions = flow(function* ({
      page = 1,
      pageSize,
      test = undefined,
    }: {
      page?: number;
      pageSize?: number;
      test?: boolean;
    }) {
      self.fetchingSession = true;
      const response = yield listSessions({ upcoming: true, page, pageSize, test });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      setSessions(response.data.results);
      self.upcomingSessions = response.data.results.map((x: { name: string }) => x.name);
      self.upcomingSessionsPage = response.data.page;
      self.fetchingSession = false;
    });

    const listActiveSessions = flow(function* () {
      self.fetchingSession = true;
      try {
        const response: ApiResponse<any> = yield listActiveSessionsAPI();
        if (response.problem) {
          if (response.status === 403) {
            self.errors = 'Unauthorised';
          }
          if (response.status === 500) {
            self.errors = 'Oops!';
          }
          return;
        }
        setSessions(response.data);
      } finally {
        self.fetchingSession = false;
      }
    });

    const fetchSession = flow(function* (session_name: string) {
      self.fetchingSession = true;
      self.redirectToSession = '';
      try {
        const response = yield getSessionDetail({ session_name });
        if (response.problem) {
          if (response.status === 404 || response.status === 403) {
            self.errors = 'Not Found';
            return;
          }
          if (response.status === 302) {
            self.redirectToSession = response.data.name;
            return;
          }
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          self.errors = 'Error loading';
          return;
        }
        setSessions([response.data]);
        setCurrentSession(response.data);
      } finally {
        self.fetchingSession = false;
      }
    });

    function setCurrentSession(session: ISession) {
      self.currentSession = session.name as any;
    }

    const fetchRegisteredSessions = flow(function* (username: string) {
      self.fetchingSession = true;
      try {
        const response = yield fetchRegisteredMasterclassSessions(username);
        if (response.status === 200) {
          self.registeredSessionNames = cast(response.data);
        }
      } finally {
        self.fetchingSession = false;
      }
    });

    const masterclassRegister = flow(function* (sessionName: string) {
      const response = yield masterClassRegister({ sessionName });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return false;
      }
      return true;
    });

    const listUpcomingOpenSessions = flow(function* ({
      masterclassName,
      subject,
    }: {
      masterclassName?: string | null;
      subject?: string | null;
    }) {
      self.fetchingSession = true;
      const response = yield listOpenMasterClassSession({ masterclassName, subject });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      setSessions(response.data.results);
      self.upcomingSessions = response.data.results.map((x: { name: string }) => x.name);
      self.upcomingSessionsPage = response.data.page;
      self.fetchingSession = false;
    });

    const getUpcomingOpenSessions = flow(function* ({
      masterclassName,
      school,
    }: {
      masterclassName: string;
      school?: string;
    }) {
      self.fetchingSession = true;
      const response = yield getOpenMasterClassSession({ masterclassName, school });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      setSessions([response.data]);
      setCurrentSession(response.data);
      self.fetchingSession = false;
    });

    const listPremiumMasterclassSessions = flow(function* (params: {
      upcoming: boolean;
      page: number;
      pageSize?: number;
      test?: boolean;
    }) {
      self.fetchingQriositySessions = true;
      try {
        const response: ApiResponse<any> = yield listPremiumMasterclasses(params);
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.qriositySessions = cast(response.data.results);
        self.qriositySessionsPage = cast(response.data.page);
      } finally {
        self.fetchingQriositySessions = false;
      }
    });

    return {
      listUpcomingSessions,
      setCurrentSession,
      fetchSession,
      listActiveSessions,
      clear,
      masterclassRegister,
      listUpcomingOpenSessions,
      getUpcomingOpenSessions,
      fetchRegisteredSessions,
      listPremiumMasterclassSessions,
    };
  });

export default MasterclassStore;
export interface IMasterclassStore extends Instance<typeof MasterclassStore> {}
