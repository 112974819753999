import { Instance, types } from 'mobx-state-tree';
import CallSessionState from './CallSessionState';


const InsightCallSessionState = CallSessionState.named('InsightCallSessionState')
  .props({
    feedbackLinkRelative: '',
    rescheduleLinkRelative: '',
    hasUpcomingPayment: false,
    parentAttendedSession: false,
    batchStudentName: '',
    rescheduledSessionName: types.maybeNull(types.string),
    meritAdmissionLink: types.maybeNull(types.string),
  });

export default InsightCallSessionState;
export interface IInsightCallSessionState extends Instance<typeof InsightCallSessionState> {}
