import {
  archiveStudentReport,
  fetchReportsToBeSent,
  fetchStudentReports,
  fetchUnitReportLink,
  getReportsCount,
  getStudentReport,
  markStudentReportSeen,
  publishStudentReport,
  regenerateReport,
  saveStudentReport,
  fetchAllReportsByStudent,
} from 'api';
import { ApiResponse } from 'apisauce';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import { BatchLiteWithoutCoach } from './coachStores/BatchStore';
import { PageStore } from './Page';
import User, { Student } from './User';
import { fetchFeedbackBadges } from 'api/coachDashboard';
import { FeedbackBadge } from './BadgesStore';

export const TEMPLATES = {
  feedback_badge_update_report: 'feedback_badge_update_report',
  student_recording: 'student_recording_activity',
  unit_report: 'unit_report',
  empathy_report_video: 'empathy_report_video',
  empathy_report_no_video: 'empathy_report_no_video',
  mid_unit_report: 'mid_unit_report',
  school_project_report: 'school_project_report',
  evening_project_report: 'evening_project_report',
};

const TranscodedVideo = types.model('TranscodedVideo', {
  transcoded_video_url: types.maybe(types.string),
  thumbnail: types.maybe(types.string),
});

const StudentReport = types
  .model('StudentReport', {
    name: types.string,
    summary: '',
    can_edit: types.boolean,
    batch: types.maybeNull(BatchLiteWithoutCoach),
    student: Student,
    coach: types.maybe(User),
    template: types.string,
    subject: types.string.name,
    is_seen: types.boolean,
    status: types.union(
      types.literal('draft'),
      types.literal('published'),
      types.literal('private'),
      types.literal('processing')
    ),
    param_values: types.maybe(types.frozen()),
    updated_at: types.maybeNull(types.string),
    created_at: types.maybeNull(types.string),
    loading: false,
    loaded: false,
    submitting: false,
    mediaType: types.optional(types.maybeNull(types.string), null),
    saving: false,
    saved: false,
    errors: '',
    archiving: false,
    can_skip: false,
    is_pending: types.boolean,
  })
  .actions((self) => ({
    setMediaType: (mediaType: 'text' | 'video' | null) => {
      self.mediaType = mediaType;
    },
    markSeen: flow(function* () {
      const result: ApiResponse<any> = yield markStudentReportSeen(self.name);
      if (result.status !== 200) return false;
      self.is_seen = true;
      return true;
    }),
    setParamValue: (paramName: string, value: any) => {
      self.param_values = { ...self.param_values, [paramName]: value };
    },
    saveReportInternal: flow(function* (parameters: any) {
      const response: ApiResponse<any> = yield saveStudentReport(self.name, parameters);
      if (response.problem) return;
      return true;
    }),
    regenerateReport: flow(function* () {
      const response: ApiResponse<any> = yield regenerateReport(self.name);
      if (response.problem) return;
      return true;
    }),
  }))
  .actions((self) => ({
    saveReport: flow(function* () {
      self.saving = true;
      let params = {};
      switch (self.template) {
        case TEMPLATES.school_project_report:
          params = {
            project_link: self.param_values.project_link || { link: '' },
            project_video: self.param_values.project_video || { video: '' },
            coach_insights_md: self.param_values.coach_insights_md,
            feedback_video: self.param_values.feedback_video || { video: '' },
            project_answer: self.param_values.project_answer ? self.param_values.project_answer : null,
            project_intro: self.param_values.project_intro ? self.param_values.project_intro : null,
            project_learnings: self.param_values.project_learnings ? self.param_values.project_learnings : null,
          };
          break;
        case TEMPLATES.unit_report:
        case TEMPLATES.evening_project_report:
          params = {
            project_link: self.param_values.project_link || { link: '' },
            project_video: self.param_values.project_video || { video: '' },
            coach_insights_md: self.param_values.coach_insights_md,
            feedback_video: self.param_values.feedback_video || { video: '' },
            project_answer: self.param_values.project_answer ? self.param_values.project_answer : null,
          };
          break;
        case TEMPLATES.mid_unit_report:
          params = {
            coach_insights_md: self.param_values.coach_insights_md,
            project_video: self.param_values.project_video || { video: '' },
            coach_feedback_points: self.param_values.coach_feedback_points || '2',
            show_project_video: self.param_values.show_project_video || 'false',
          };
          break;
        default:
          if (!self.mediaType) return (self.saving = false);
          const paramName = `feedback_${self.mediaType}`;
          params = {
            [paramName]: self.param_values[paramName],
          };
          break;
      }

      self.saved = yield self.saveReportInternal(params);
      self.saving = false;
      if (!self.saved) self.errors = 'There was a problem saving the report';
    }),
    setReportSaved(isSaved: boolean) {
      self.saved = isSaved;
    },
    setErrors(errors: string) {
      self.errors = errors;
    },
  }))
  .views((self) => ({
    get previewURLRelative(): string {
      return `/reports/${self.name}`;
    },
    get previewURLAbsolute(): string {
      return `${window.location.origin}/reports/${self.name}`;
    },
  }))
  .views((self) => ({
    getPlaceholderText: (placeholderText: string) => {
      const placeholders = {
        student_fname: self.student.first_name || 'Name',
        gender_prounoun: self.student.genderDetails.pronoun.toLowerCase(),
        gender_prounoun_cap: self.student.genderDetails.pronoun,
        student_his_her: self.student.genderDetails.possessive.toLowerCase(),
        student_his_her_cap: self.student.genderDetails.possessive,
        gender_addressing: self.student.genderDetails.addressing.toLowerCase(),
        gender_addressing_cap: self.student.genderDetails.addressing,
      } as { [key: string]: string };

      return placeholderText.replace(/{{\w+}}/g, (variable: string) => {
        const theVariable = variable.slice(2, variable.length - 2);
        return placeholders[theVariable] || '';
      });
    },

    get eventName() {
      return self.summary;
    },
  }))
  .actions((self) => ({
    submitStudentReport: flow(function* (type: string, reportName: string, parameters: any) {
      self.submitting = true;
      try {
        const submittedReport: ApiResponse<any> = yield publishStudentReport({
          type,
          report_name: reportName,
          parameters,
        });
        if (submittedReport.status !== 200) {
          self.setErrors('There was a problem publishing the report');
          return false;
        }
        self.setErrors('');
        if (submittedReport.data) self.status = submittedReport.data.student_report.status;
        return true;
      } finally {
        self.submitting = false;
      }
    }),
    archiveReport: flow(function* (archiveReason: string) {
      self.archiving = true;
      self.setErrors('');
      try {
        const archiveReportResponse: ApiResponse<any> = yield archiveStudentReport(self.name, archiveReason);
        if (archiveReportResponse.problem) return self.setErrors(archiveReportResponse.problem);
      } finally {
        self.archiving = false;
      }
    }),
  }));

export interface ITranscodedVideo extends Instance<typeof TranscodedVideo> {}
export interface IStudentReport extends Instance<typeof StudentReport> {}

export const StudentReportStore = types
  .model({
    studentReports: types.array(StudentReport),
    selectedStudentReport: types.maybe(StudentReport),
    loading: false,
    loaded: false,
    notFound: false,
    page: types.maybe(PageStore),
    reportCount: types.frozen({ unitReports: 0, badgeReports: 0 }),
    feedbackBadges: types.array(FeedbackBadge),
  })
  .actions((self) => ({
    fetchStudentReports: flow(function* (params?: {} | undefined) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchStudentReports(params);
        if (response.problem) return;
        if (response.status !== 200) return;
        self.page = response.data.page;
        self.studentReports = cast(response.data.results);
        self.loaded = true;
      } finally {
        self.loading = false;
      }
    }),
    fetchStudentReportsToBeSent: flow(function* (params: {
      page?: number;
      pageSize?: number;
      sessionName?: string;
      reportTypes?: string[];
    }) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchReportsToBeSent(params);
        if (response.problem) return;
        if (response.status !== 200) return;
        self.page = response.data.page;
        self.studentReports = cast(response.data.results);
        self.loaded = true;
      } finally {
        self.loading = false;
      }
    }),
    fetchAllReportsByStudent: flow(function* (params: { page?: number; pageSize?: number; studentUsername: string }) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchAllReportsByStudent(params);
        if (response.problem) return;
        if (response.status !== 200) return;
        self.page = response.data.page;
        self.studentReports = cast(response.data);
        self.loaded = true;
      } finally {
        self.loading = false;
      }
    }),
    fetchFeedbackBadges: flow(function* (batchStudentName?: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchFeedbackBadges({ batchStudentName, pageSize: 3 });
        if (response.problem) {
          return false;
        }
        self.feedbackBadges = cast(response.data.results);
      } finally {
        self.loading = false;
      }
    }),
    clearReports() {
      self.loading = true;
      self.loaded = false;
      self.studentReports = cast([]);
    },

    getStudentReport: flow(function* (studentReportName: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield getStudentReport(studentReportName);
        if (response.problem) return (self.notFound = true);
        if (response.status !== 200) return (self.notFound = true);
        self.selectedStudentReport = response.data;
        self.loaded = true;
      } finally {
        self.loading = false;
      }

      if (!self.selectedStudentReport) return;
      const param_values = self.selectedStudentReport.param_values;
      if (self.selectedStudentReport.status === 'draft') {
        switch (self.selectedStudentReport.template) {
          case TEMPLATES.feedback_badge_update_report:
            if (param_values.feedback_text?.text) return true;
            const placeholderText = param_values.feedback_badge.student_report_placeholder || '';
            if (!placeholderText) return true;
            self.selectedStudentReport.setParamValue('feedback_text', {
              text: self.selectedStudentReport.getPlaceholderText(placeholderText),
            });
            break;
          case TEMPLATES.student_recording:
            if (param_values.feedback_text?.text) return true;
            const videoPlaceholderText = param_values.student_recording.feedback_text_placeholder || '';
            if (!videoPlaceholderText) return true;
            self.selectedStudentReport.setParamValue('feedback_text', {
              text: self.selectedStudentReport.getPlaceholderText(videoPlaceholderText),
            });
            break;
          case TEMPLATES.unit_report:
          case TEMPLATES.school_project_report:
          case TEMPLATES.evening_project_report:
            if (!param_values.coach_insights_md?.text && param_values.unit_details?.coach_insights_placeholder) {
              const coachInsightsPlaceholder = param_values.unit_details.coach_insights_placeholder;
              self.selectedStudentReport.setParamValue('coach_insights_md', {
                text: self.selectedStudentReport.getPlaceholderText(coachInsightsPlaceholder),
              });
            }
            break;
          default:
            break;
        }
      }

      if (self.selectedStudentReport.status === 'draft') {
        switch (self.selectedStudentReport.template) {
          case TEMPLATES.school_project_report:
            if (!param_values.project_intro?.text && param_values.unit_details?.project_intro) {
              const projectIntroPlaceholder = param_values.unit_details.project_intro;
              self.selectedStudentReport.setParamValue('project_intro', {
                text: self.selectedStudentReport.getPlaceholderText(projectIntroPlaceholder),
              });
            }
            if (!param_values.project_learnings?.text && param_values.unit_details?.project_learnings) {
              const projectLearningsPlaceholder = param_values.unit_details.project_learnings;
              self.selectedStudentReport.setParamValue('project_learnings', {
                text: self.selectedStudentReport.getPlaceholderText(projectLearningsPlaceholder),
              });
            }
        }
      }

      return true;
    }),
    getReportCount: flow(function* () {
      try {
        const response: ApiResponse<any> = yield getReportsCount();
        if (response.problem) return;
        if (response.status === 200) {
          self.reportCount = { unitReports: response.data.unit_reports, badgeReports: response.data.badge_reports };
        }
      } finally {
      }
    }),
    fetchUnitReportLink: flow(function* (studentUsername: string, unitName?: string) {
      const response: ApiResponse<any> = yield fetchUnitReportLink({ studentUsername, unitName });

      if (response.problem) {
        return;
      }
      const { data } = response;
      return data.link;
    }),
  }))
  .views((self) => ({
    get pendingReports() {
      return self.studentReports.filter((report) => report.is_pending) as IStudentReport[];
    },
  }));

export interface IStudentReportStore extends Instance<typeof StudentReportStore> {}

export const StudentReports = StudentReportStore.named('StudentReports');
export const BadgeReports = StudentReportStore.named('BadgeReports');

export type TUnitLevel = 'fundamental' | 'intermediate' | 'advanced';

const FileUploadActivityReportAnswer = types.model({
  activity_type: 'file upload activity',
  file_type: types.union(types.literal('image'), types.literal('pdf')),
  file_url: types.string,
  activity_title: types.maybeNull(types.string),
});

export interface IFileUploadActivityReportAnswer extends Instance<typeof FileUploadActivityReportAnswer> {}

const ProjectAnswer = types.union(FileUploadActivityReportAnswer);

export interface IProjectAnswer extends Instance<typeof ProjectAnswer> {}
