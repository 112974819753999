import loadable from '@loadable/component';
import GATracker from 'components/GATracker';
import ProtectedRoute, { IProtectedRouteProps } from 'components/ProtectedRoute';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { QueryParamProvider } from 'use-query-params';
import Loading from './components/Loading';
import LogoutPage from './pages/LogoutPage';
import NotFoundPage from './pages/NotFoundPage';
import DailyCOMasterclassTestLogin from 'components/DailyCOMasterclassTestLogin';

const ProtectedCoachRoute = (props: IProtectedRouteProps) => {
  return <ProtectedRoute roles={['coach']} {...props} />;
};

const ProtectedStudentRoute = (props: IProtectedRouteProps) => {
  return <ProtectedRoute roles={['student']} {...props} />;
};

const ProtectedRoutePlusGuest = (props: IProtectedRouteProps) => {
  return <ProtectedRoute roles={['student', 'coach', 'guest', 'parent']} {...props} />;
};

const HomePageLazy = loadable(() => import('./pages/HomePage'), {
  fallback: <Loading fullpage />,
});

const SignupPageLazy = loadable(() => import('./pages/SignupPage'), {
  fallback: <Loading fullpage />,
});

const LoginPageLazy = loadable(() => import('./pages/LoginPage'), {
  fallback: <Loading fullpage />,
});

const SessionPageLazy = loadable(() => import('./pages/SessionPage'), {
  fallback: <Loading fullpage />,
});

const ScheduleCatchupPageLazy = loadable(() => import('./pages/CatchupSessionSchedulingPage'), {
  fallback: <Loading fullpage />,
});

const MasterclassPageLazy = loadable(() => import('./masterclass/pages/MasterClassPage'), {
  fallback: <Loading fullpage />,
});

const CoachSessionPageLazy = loadable(() => import('./sessionDashboard/pages/SessionPage'), {
  fallback: <Loading fullpage />,
});

const CoachMasterclassPageLazy = loadable(() => import('./masterclass/pages/CoachPage'), {
  fallback: <Loading fullpage />,
});

const DashboardMainLazy = loadable(() => import('./coachDashboard/DashboardMain'), {
  fallback: <Loading fullpage />,
});

const MasterClassAttendancePageLazy = loadable(() => import('./masterclass/pages/AttendancePage'), {
  fallback: <Loading fullpage />,
});

const MasterclassRegisterPageLazy = loadable(() => import('./masterclass/pages/RegisterPage'), {
  fallback: <Loading fullpage />,
});

const PlaylistPageStandaloneLazy = loadable(() => import('./pages/PlaylistPageStandalone'), {
  fallback: <Loading fullpage />,
});

const StudentReportPageLazy = loadable(() => import('./studentReport/pages/StudentReportPage'), {
  fallback: <Loading fullpage />,
});

const ReferralPageLazy = loadable(() => import('pages/ReferralPage'), {
  fallback: <Loading fullpage />,
});

const AchievementsPageLazy = loadable(() => import('pages/AchievementsPage'), {
  fallback: <Loading fullpage />,
});

const ProjectLinkLazy = loadable(() => import('./pages/ProjectLink'), {
  fallback: <Loading fullpage />,
});

const ReportLinkLazy = loadable(() => import('./pages/ReportLink'), {
  fallback: <Loading fullpage />,
});

const TranscodedVideoLazy = loadable(() => import('./pages/TranscodedVideoPage'), {
  fallback: <Loading fullpage />,
});

const CoachRecordingPageLazy = loadable(() => import('./studentReport/pages/CoachRecordingPage'), {
  fallback: <Loading fullpage />,
});

const ParentFeedbackFormLazy = loadable(() => import('./pages/ParentFeedbackForm'), {
  fallback: <Loading fullpage />,
});

const ParentNPSFormLazy = loadable(() => import('./pages/ParentNPSForm'), {
  fallback: <Loading fullpage />,
});

const IvrNoResponseFormLazy = loadable(() => import('./pages/IvrNoResponseForm'), {
  fallback: <Loading fullpage />,
});

const InsightCallSessionPageLazy = loadable(() => import('./insightcalls/pages/InsightCallSessionPage'), {
  fallback: <Loading fullpage />,
});
const LDSessionPageLazy = loadable(() => import('./ldsessions/pages/LDSessionPage'), {
  fallback: <Loading fullpage />,
});
const CoachUpdateFormLazy = loadable(() => import('./pages/CoachUpdateForm'), {
  fallback: <Loading fullpage />,
});

const EmpathyFormLazy = loadable(() => import('components/EmpathyFormV2'), {
  fallback: <Loading fullpage />,
});

const SessionRecordingFeedback = loadable(() => import('./pages/SessionRecordingFeedback'), {
  fallback: <Loading fullpage />,
});

const CallRescheduleRequestFormLazy = loadable(() => import('./pages/CallRescheduleRequestForm'), {
  fallback: <Loading fullpage />,
});
const InsightCallDetailLazy = loadable(() => import('./pages/InsightDetailRedirect'), {
  fallback: <Loading fullpage />,
});
const PartialPaymentLinkLazy = loadable(() => import('./pages/PartialPaymentRedirect'), {
  fallback: <Loading fullpage />,
});

const InClassIssuesPageLazy = loadable(() => import('./coachDashboard/pages/admin/InClassIssuesPage'), {
  fallback: <Loading fullpage />,
});

const BatchGalleryLazy = loadable(() => import('./pages/BatchGallery'), {
  fallback: <Loading fullpage />,
});

const GoogleRatingPageLazy = loadable(() => import('./pages/GoogleRatingPage'), {
  fallback: <Loading fullpage />,
});

const BatchProjectFeedbackLazy = loadable(() => import('./pages/BatchProjectFeedback'), {
  fallback: <Loading fullpage />,
});

const BatchStudentProjectFeedbackLazy = loadable(() => import('./pages/BatchStudentReviewProjects'), {
  fallback: <Loading fullpage />,
});

const StudentProfilePageLazy = loadable(() => import('./pages/StudentProfilePage'), {
  fallback: <Loading fullpage />,
});

const DailyReportLazy = loadable(() => import('studentReport/pages/DailyReportPage'), {
  fallback: <Loading fullpage />,
});

const DailyReportPreviewlazy = loadable(() => import('studentReport/pages/DailyReportPreview'), {
  fallback: <Loading fullpage />,
});

const DailyCOTestLazy = loadable(() => import('call/DailyCOTest'), {
  fallback: <Loading fullpage />,
});

const QriosityMasterclassRegistrationLazy = loadable(() => import('./pages/QriosityMasterclassRegistration'), {
  fallback: <Loading fullpage />,
});

function Routes() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => {
              return <LoginPageLazy {...props} otpDefault />;
            }}
          ></Route>
          <Route
            exact
            path="/login/coach"
            render={(props) => {
              return <LoginPageLazy {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/signup"
            render={(props) => {
              return <SignupPageLazy {...props} />;
            }}
          ></Route>
          <Route
            exact
            path="/masterclass/:session_name/register"
            render={(props) => {
              return <QriosityMasterclassRegistrationLazy />;
            }}
          ></Route>
          <Route exact path="/masterclass/:session_name/dailyco_testing">
            <DailyCOMasterclassTestLogin />
          </Route>
          <Route exact path="/masterclass">
            <MasterclassRegisterPageLazy />
          </Route>
          <ProtectedCoachRoute path="/dashboard">
            <DashboardMainLazy />
          </ProtectedCoachRoute>
          <ProtectedRoute exact path="/admin/otp">
            <Redirect to="/dashboard/admin/otp" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/admin/livesessions">
            <Redirect to="/dashboard/admin/livesessions" />
          </ProtectedRoute>
          <ProtectedRoutePlusGuest exact path="/">
            <HomePageLazy />
          </ProtectedRoutePlusGuest>
          <ProtectedStudentRoute exact path="/session/:session_name">
            <SessionPageLazy />
          </ProtectedStudentRoute>
          <ProtectedStudentRoute exact path="/schedule-catchup">
            <ScheduleCatchupPageLazy />
          </ProtectedStudentRoute>
          <ProtectedRoutePlusGuest
            exact
            path="/masterclass/:session_name"
            anonymousComponent={<MasterClassAttendancePageLazy />}
          >
            <MasterclassPageLazy />
          </ProtectedRoutePlusGuest>
          <ProtectedRoute path="/activity/:resource_id">
            <PlaylistPageStandaloneLazy activity />
          </ProtectedRoute>
          <ProtectedStudentRoute path="/playlist/:resource_id">
            <PlaylistPageStandaloneLazy />
          </ProtectedStudentRoute>
          <ProtectedStudentRoute path="/student-profile">
            <StudentProfilePageLazy />
          </ProtectedStudentRoute>
          <ProtectedCoachRoute path="/session/:session_name/coach">
            <CoachSessionPageLazy />
          </ProtectedCoachRoute>
          <ProtectedCoachRoute path="/masterclass/:session_name/coach">
            <CoachMasterclassPageLazy />
          </ProtectedCoachRoute>
          <ProtectedRoutePlusGuest
            exact
            path="/masterclass/:session_name/:school_name"
            anonymousComponent={<MasterClassAttendancePageLazy />}
          >
            <MasterclassPageLazy />
          </ProtectedRoutePlusGuest>
          <Route exact path="/logout">
            <LogoutPage />
          </Route>
          <ProtectedRoute exact path="/referral">
            <ReferralPageLazy />
          </ProtectedRoute>
          <Route exact path="/reports/edit/:report_name">
            <StudentReportPageLazy editPage />
          </Route>
          <Route exact path="/reports/:report_name">
            <StudentReportPageLazy />
          </Route>
          <ProtectedRoute path="/achievements/:username?">
            <AchievementsPageLazy />
          </ProtectedRoute>
          <Route exact path="/projects/student">
            <ProjectLinkLazy />
          </Route>
          <Route exact path="/student_reports">
            <ReportLinkLazy />
          </Route>
          <Route exact path="/videos/student/:pks">
            <TranscodedVideoLazy />
          </Route>
          <Route exact path="/coachrecording">
            <CoachRecordingPageLazy />
          </Route>
          <Route exact path="/parent-feedback/">
            <ParentFeedbackFormLazy />
          </Route>
          <Route exact path="/parent-nps/">
            <ParentNPSFormLazy />
          </Route>
          <Route exact path="/ivr-noresponse/">
            <IvrNoResponseFormLazy />
          </Route>
          <Route exact path="/coach-update/">
            <CoachUpdateFormLazy />
          </Route>
          <Route exact path="/insight-call/:session_name">
            <InsightCallSessionPageLazy />
          </Route>
          <Route exact path="/orientation-session/:session_name">
            <LDSessionPageLazy />
          </Route>
          <Route exact path="/l/insight-call/:username">
            <InsightCallDetailLazy />
          </Route>
          <Route exact path="/l/partial-payment/:username">
            <PartialPaymentLinkLazy />
          </Route>
          <Route exact path="/empathy-form/">
            <EmpathyFormLazy />
          </Route>
          <ProtectedCoachRoute exact path="/session-recording-feedback/:recording_name">
            <SessionRecordingFeedback />
          </ProtectedCoachRoute>
          <Route exact path="/insight-call-reschedule-request/">
            <CallRescheduleRequestFormLazy />
          </Route>
          <Route exact path="/orientation-session-reschedule-request/">
            <CallRescheduleRequestFormLazy type="ld" />
          </Route>
          <Route exact path="/session/:session_name/in-class-issues/">
            <InClassIssuesPageLazy />
          </Route>
          <Route exact path="/gallery/:projectName/:batchName">
            <BatchGalleryLazy />
          </Route>
          <Route exact path="/google-rating">
            <GoogleRatingPageLazy />
          </Route>
          <ProtectedCoachRoute exact path="/:projectName/:batchName/project-review/">
            <BatchProjectFeedbackLazy />
          </ProtectedCoachRoute>
          <ProtectedCoachRoute exact path="/project-review/:projectName/:batchName/">
            <BatchProjectFeedbackLazy preview />
          </ProtectedCoachRoute>
          <ProtectedCoachRoute exact path="/:batchStudentName/project-review/">
            <BatchStudentProjectFeedbackLazy />
          </ProtectedCoachRoute>
          <ProtectedCoachRoute exact path="/daily-report/:sprint_name/preview/">
            <DailyReportPreviewlazy />
          </ProtectedCoachRoute>
          <Route exact path="/daily-report/:report_name/">
            <DailyReportLazy />
          </Route>
          <ProtectedRoute exact path="/dailycotest">
            <DailyCOTestLazy />
          </ProtectedRoute>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </QueryParamProvider>
      <GATracker />
    </Router>
  );
}

export default observer(Routes);
