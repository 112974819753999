import { flow, IAnyType, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { PageStore } from './Page';

export const DataTable = (arrayType: IAnyType) =>
  types
    .model('DataTable', {
      fetch: InvokerType,
      _page: types.maybeNull(PageStore),
      _data: types.array(arrayType),
    })
    .actions((self) => ({
      fetchData: flow(function* (query?: string, page_number?: number, page_size?: number) {
        const data = yield self.fetch.invoke({ query, page_number, page_size });
        self._page = data.page;
        self._data = data.results;
      }),
    }))
    .views((self) => ({
      get loading() {
        return self.fetch.invoking;
      },
      get page() {
        return self._page;
      },
      get data() {
        return self._data;
      },
    }));
