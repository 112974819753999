import { types, isAlive, getPath, getParent } from 'mobx-state-tree';
import { IReactionDisposer, reaction } from 'mobx';

const InvokerModel = types
  .model('InvokerType', {
    params: types.optional(types.frozen(), null),
    ret: types.optional(types.frozen(), null),
    _invoking: false,
  })
  .actions((self) => ({
    setParams(params: { params: any; _id: number } | null) {
      self.params = params;
    },
    setInvoking(val: boolean) {
      self._invoking = val;
    },
    invokeAndForget(params?: any) {
      self.params = { params: params || {} };
    },
  }))
  .views((self) => ({
    get invoking() {
      return self._invoking;
    },
  }))
  .actions((self) => {
    let watchers: { [key: number]: { dispose: IReactionDisposer; iid: number } } = {};

    const invoke = (params?: any) => {
      const invoke_id = Math.random();
      const invoke_at = +new Date();
      const objPath = getPath(self);
      console.log('[Invoker] invoking', objPath, invoke_id, params);
      self.setInvoking(true);

      const ret = new Promise<any>((resolve, reject) => {
        const iid = window.setTimeout(() => {
          watchers[invoke_id].dispose();
          delete watchers[invoke_id];

          if (!isAlive(self) || !isAlive(getParent(self))) return;

          self.setInvoking(false);
          console.error('[Invoker] Timeout', objPath, invoke_id, +new Date() - invoke_at + 'ms');
          reject(new Error('Timeout ' + objPath));
        }, 15000);

        const dispose = reaction(
          () => self.ret,
          (ret, prevVal, reaction) => {
            if (ret && ret._id === invoke_id) {
              reaction.dispose();
              self.setInvoking(false);
              window.clearTimeout(iid);
              delete watchers[invoke_id];

              console.log('[Invoker] return recv', objPath, ret._id, ret.params);
              if (ret.err) {
                reject(ret.err);
              } else {
                resolve(ret.params);
              }
            }
          }
        );
        watchers[invoke_id] = { iid, dispose };
      });
      self.params = { params: params || {}, _id: invoke_id };
      return ret;
    };

    const beforeDestroy = () => {
      Object.values(watchers).forEach((watcher) => {
        window.clearTimeout(watcher.iid);
        watcher.dispose();
      });
    };

    return { invoke, beforeDestroy };
  });

const InvokerType = types.optional(InvokerModel, {});
export default InvokerType;
