import { fetchStudent, submitVideoListForStitching } from 'api';
import { ApiResponse } from 'apisauce';
import { flow, Instance, types } from 'mobx-state-tree';
import { Student } from './User';

export const CoachRecordingStore = types
  .model('CoachRecordingStore', {
    videoType: types.maybeNull(types.string),
    studentUsername: types.maybe(types.string),
    projectName: types.maybe(types.string),
    submittedVideos: false,
    student: types.maybe(Student),
    errors: types.maybe(types.string),
  })
  .actions((self) => ({
    setVideoType: (videoType: 'project' | 'camera' | null) => {
      self.videoType = videoType;
    },
    setStudentUsername(studentUsername: string) {
      self.studentUsername = studentUsername;
    },
    setProjectName(projectName: string) {
      self.projectName = projectName;
    },
    stitchVideos: flow(function* (videoList: string[], stitchVideosClassIdentifier: string, otherParams: any) {
      yield submitVideoListForStitching({ videoList, stitchVideosClassIdentifier, otherParams });
      self.submittedVideos = true;
    }),
    fetchStudent: flow(function* (studentUsername: string) {
      const studentResponse: ApiResponse<any> = yield fetchStudent({ studentUsername });
      if (studentResponse.problem) return (self.errors = studentResponse.problem);
      self.student = studentResponse.data;
    }),
  }));

export interface ICoachRecordingStore extends Instance<typeof CoachRecordingStore> {}
