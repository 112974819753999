import { useEffect } from "react";
import { useHistory } from "react-router";
import { useStores } from "../hooks/useStores";

function LogoutPage() {
  const { authStore, rtStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    authStore.logout(true);
    rtStore.closeSocket();
    history.push('/login');
  });
  return null;
}

export default LogoutPage;
