import Config from 'config';
import { format, parse } from 'fecha';

export const formatDate = (dateString: string, dateFormat: string = 'MMM D, YYYY') => {
  var date = parse(dateString, Config.DATETIME_FORMAT);
  if (date) {
    return format(date, dateFormat);
  }
  return '';
};

export const toTimestampWithTZ = (dateTime: Date) => {
  return format(dateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const getLocalTime = (timeZone: string) => {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat([], options);
  return formatter.format(date);
};
