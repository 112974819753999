import { masterClassTestRegister } from 'api';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface PageParams {
  session_name: string;
}

function DailyCOMasterclassTestLogin() {
  const { session_name } = useParams<PageParams>();
  const { commonStore, userStore } = useStores();
  useEffect(() => {
    masterClassTestRegister({ sessionName: session_name }).then((response) => {
      const { data } = response as any;
      commonStore.setToken(data.auth_token);
      userStore.pullUser().then(() => {
        window.localStorage.setItem('autoplayOverrided', 'true');
        window.location.replace('/masterclass/' + session_name)
      });
    });
  }, [session_name, commonStore, userStore]);

  return null;
}

export default observer(DailyCOMasterclassTestLogin);
