import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';
import User, { StudentLite } from './User';

export const Issue = types
  .model('Issue', {
    name: types.string,
    coach: User,
    student: StudentLite,
    parent_username: types.maybeNull(types.string),
    status: types.union(
      types.literal('unresolved'),
      types.literal('resolved'),
      types.literal('escalated_to_tech'),
      types.literal('ignored')
    ),
    issue_type: types.union(
      types.literal('Not joined'),
      types.literal('Trying to join'),
      types.literal('Disconnected'),
      types.literal('Cannot Hear'),
      types.literal('Cannot see camera'),
      types.literal('Cannot see screen'),
      types.literal('Needs Help - SOS'),
      types.literal('Callback requested'),
      types.literal('Other tech issues')
    ),
    comment: types.string,
    handled_by: types.maybeNull(User),
    session_link: types.string,
    created_at: types.string,
  })
  .views((self) => ({
    get statusDisplayName() {
      return STATUS_VALUE_TO_NAME[self.status];
    },
    get createdAt() {
      return new Date(self.created_at);
    },
    get timeElapsedSecs() {
      return (new Date().getTime() - new Date(self.created_at).getTime()) / 1000;
    },
  }))
  .actions((self) => ({}));

export interface IIssue extends Instance<typeof Issue> {}

const InClassIssuesState = RTModel.named('InClassIssuesState')
  .props({
    active_issues: types.array(Issue),
    setStatusInvoke: InvokerType,
    assignToInvoke: InvokerType,
    reportIssueInvoke: InvokerType,
    addToSessionInvoke: InvokerType,
    setCommentInvoke: InvokerType,
    getPermalinkInvoke: InvokerType,
    addOpsGroupInvoke: InvokerType,
    notifyCoachInvoke: InvokerType,
  })
  .views((self) => ({
    activeIssues(username: string) {
      return self.active_issues.filter((issue) => issue.student.username === username);
    },
  }))
  .actions((self) => ({
    setStatus: (name: string, status: string) => {
      return self.setStatusInvoke.invoke({ status, name });
    },
    setComment: (name: string, comment: string) => {
      return self.setCommentInvoke.invoke({ comment, name });
    },
    assignTo: (name: string, toUsername: string) => {
      return self.assignToInvoke.invoke({ name, toUsername });
    },
    reportIssue: (studentUsername: string, issueType: string, comment: string, sessionName: string) => {
      return self.reportIssueInvoke.invoke({ studentUsername, issueType, comment, sessionName });
    },
    addToSession: (name: string) => {
      return self.addToSessionInvoke.invoke({ name });
    },
    getPermalink(name: string) {
      return self.getPermalinkInvoke.invoke({ name });
    },
    addOpsGroup(coach_usernames: string[], ops_username: string) {
      return self.addOpsGroupInvoke.invoke({ coach_usernames, ops_username });
    },
    notifyCoach(name: string, message: string) {
      return self.notifyCoachInvoke.invoke({ name, message });
    },
  }));

export default InClassIssuesState;
export interface IInClassIssuesState extends Instance<typeof InClassIssuesState> {}

export const InClassIssuesOpsState = InClassIssuesState.named('InClassIssuesOpsState')
  .props({})
  .actions((self) => ({}))
  .views(() => ({}));
export interface IInClassIssuesOpsState extends Instance<typeof InClassIssuesOpsState> {}

export const InClassIssuesCoachState = InClassIssuesState.named('InClassIssuesCoachState')
  .props({
    resolved_issues: types.array(Issue),
  })
  .actions((self) => ({}))
  .views((self) => ({
    resolvedIssues(username: string) {
      return self.resolved_issues.filter((issue) => issue.student.username === username);
    },
  }))
  .views((self) => ({
    issuesCount(username: string) {
      return self.activeIssues(username).length + self.resolvedIssues(username).length;
    },
  }));

export interface IInClassIssuesCoachState extends Instance<typeof InClassIssuesCoachState> {}

const STATUS_VALUE_TO_NAME = {
  unresolved: 'Unresolved',
  resolved: 'Resolved',
  escalated_to_tech: 'Escalated to Tech',
  ignored: 'Ignored',
};
