import { Instance, types } from 'mobx-state-tree';
import { v4 as uuid4 } from 'uuid';
import { DataTable } from './DataTable';
import InvokerType from './InvokerType';
import { PollStore } from './PollStore';
import { RTModel } from './RTModel';
import Sprint from './coachStores/Sprint';
import User, { IUser, UserMap } from './User';

const Link = types.model('Link', {
  url: types.string,
  description: types.string,
  key: types.string,
});

export interface ILink extends Instance<typeof Link> {}

export const MasterclassStudent = types.model('MasterclassStudent', {
  is_connected: types.boolean,
  user: User,
  facing_issues: types.boolean,
});

export interface IMasterclassStudent extends Instance<typeof MasterclassStudent> {}

export const MasterclassStatsStoreLite = types.model('MasterclassStatsStoreLite', {
  activeCountWithoutGuests: types.number,
  joinedCount: types.number,
});
export interface IMasterclassStatsStoreLite extends Instance<typeof MasterclassStatsStoreLite> {}

const MasterclassSessionState = RTModel.named('MasterclassSessionState')
  .props({
    coaches: types.array(User),
    coach: types.maybe(User),
    sprint: Sprint,
    subject: types.maybeNull(types.string),
    sessionClosed: false,
    isLive: false,
    enableChat: true,
    showFeedbackForm: false,
    presenter: '',
    invokeFetchUserDetails: InvokerType,
    endSessionInvoke: InvokerType,
    pollStore: PollStore,
    links: types.map(types.array(Link)),
    showSignup: false,
    getStudentDetailInvoke: InvokerType,
    setMicLockedInvoke: InvokerType,
    notifyDisconnectedInvoke: InvokerType,
    markFacingIssues: InvokerType,
    markIssuesResolved: InvokerType,
    notifyDisconnectedEligibleInvoke: InvokerType,
    highlightedLink: types.string,
    highlightScholarshipLink: types.boolean,
    highlightYTLink: false,
    highlightGoogleBusinessLink: false,
    participants: DataTable(MasterclassStudent),
    removedStudentsList: types.array(types.string),
    pinnedParticipants: types.array(types.string),
    setPinnedParticipantInvoke: InvokerType,
  })
  .views((self) => ({
    get coachesUserMap(): UserMap {
      return self.coaches.reduce((a: UserMap, user: IUser) => {
        a[user.username] = user;
        return a;
      }, {});
    },
  }))
  .actions((self) => ({
    setIsLive(val: boolean) {
      self.isLive = val;
    },
    setEnableChat(val: boolean) {
      self.enableChat = val;
    },
    setPresenter(val: string) {
      self.presenter = val;
    },
    fetchUserDetails(usernames: Array<string>) {
      return self.invokeFetchUserDetails.invoke({ usernames }) as Promise<Array<IUser>>;
    },
    setShowFeedbackForm(val: boolean) {
      self.showFeedbackForm = val;
    },
    endSession() {
      return self.endSessionInvoke.invoke();
    },
    addLink(url: string, description: string) {
      self.links.set('coach', [...(self.links.get('coach') || []), { url, description, key: uuid4() }]);
    },
    removeLink(key: string) {
      self.links.set(
        'coach',
        self.links.get('coach')?.filter((link) => link.key !== key)
      );
    },
    getHighlightedlink() {
      return self.highlightedLink && self.links.get('coach')?.find((link) => link.key === self.highlightedLink);
    },
    setShowSignup(val: boolean) {
      self.showSignup = val;
    },
    getStudentDetail(username: string) {
      return self.getStudentDetailInvoke.invoke({ username });
    },
    setMicLocked(username: string, locked: boolean) {
      return self.setMicLockedInvoke.invoke({ username, locked });
    },
    notifyDisconnectedStudents() {
      return self.notifyDisconnectedInvoke.invoke();
    },
    getNotificationEligibleStudents() {
      return self.notifyDisconnectedEligibleInvoke.invoke();
    },
    setHighlightedlink(link: string) {
      self.highlightedLink = link;
    },
    toggleHighlightScholarshipLink() {
      self.highlightScholarshipLink = !self.highlightScholarshipLink;
    },
    toggleHighlightYTLink() {
      self.highlightYTLink = !self.highlightYTLink;
    },
    togglehighlightGoogleBusinessLink() {
      self.highlightGoogleBusinessLink = !self.highlightGoogleBusinessLink;
    },
    setPinned(username: string) {
      return self.setPinnedParticipantInvoke.invoke({ username, pin: true });
    },
    removePinned(username: string) {
      return self.setPinnedParticipantInvoke.invoke({ username, pin: false });
    },
  }));

export default MasterclassSessionState;
export interface IMasterclassSessionState extends Instance<typeof MasterclassSessionState> {}
