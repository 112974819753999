import { Instance, types } from 'mobx-state-tree';
import { BatchLiteWithoutCoach } from './coachStores/BatchStore';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';
import Sprint from './coachStores/Sprint';
import User, { IStudent, IUser, Student, StudentMap, UserMap } from './User';
import { PersistedReactionsStore } from './PersistedReactionsStore';

const TutorSessionState = RTModel.named('TutorSessionState')
  .props({
    students: types.array(Student),
    coaches: types.array(User),
    coach: types.maybe(User),
    sprint: Sprint,
    sessionClosed: false,
    getAbsenteesInvoke: InvokerType,
    batch: types.maybeNull(BatchLiteWithoutCoach),
    persistedReactionsStore: PersistedReactionsStore
  })
  .views((self) => ({
    get userMap(): UserMap {
      return self.students.concat(self.coaches).reduce((a: UserMap, user: IUser) => {
        a[user.username] = user;
        return a;
      }, {});
    },
    get studentMap(): StudentMap {
      return self.students.reduce((a: StudentMap, user: IStudent) => {
        a[user.username] = user;
        return a;
      }, {});
    },
    get mainUsers() {
      const users = self.students as IUser[];
      const coach = self.coach as IUser;
      const otherCoaches = self.coaches.filter((x) => x.username !== coach.username);
      return [coach, ...otherCoaches, ...users];
    },
    get canSkipSessionSchedule() {
      return !self.batch || self.sprint.is_catchup;
    },
    get canSkipCatchupSessionSchedule() {
      return self.sprint.is_catchup;
    },
  }))
  .actions((self) => ({
    getAbsentees() {
      return self.getAbsenteesInvoke.invoke();
    },
  }));

export default TutorSessionState;
export interface ITutorSessionState extends Instance<typeof TutorSessionState> {}
