import {
  fetchExpiredButActiveSubscriptions,
  fetchSubscriptionPlan,
  fetchSubscriptionsExpiringSoon,
  fetchUserSubscription,
  sendRenewalLink,
} from 'api';
import { ApiResponse } from 'apisauce';
import { cast, flow, types } from 'mobx-state-tree';
import { BatchStudentStore } from './coachStores/BatchStudentStore';
import Student from './User';
import { PageStore } from './Page';

export const UserSubscription = types
  .model('UserSubscription', {
    name: types.string,
    subscription_name: types.string,
    expires_in_days: types.number,
    cut_off_in_days: types.maybeNull(types.number),
    end_date: types.string,
  })
  .views((self) => ({
    get expiredDaysAgo() {
      return -1 * (self.expires_in_days || 0);
    },
  }))
  .actions((self) => ({
    sendRenewalLink: flow(function* (phone?: string) {
      const response: ApiResponse<any> = yield sendRenewalLink(self.name, phone);
      if (response.problem) throw Error(response.data.error);
      return true;
    }),
  }));

export const SubscriptionPlanOption = types.model('SubscriptionPlanOption', {
  name: types.string,
  amount: types.number,
  duration: types.number,
  discount: types.number,
  display_page_config: types.frozen({}),
});

export const UserSubscriptionExpiry = types
  .model('UserSubscriptionExpiry', {
    name: types.string,
    student: Student,
    parent_username: types.maybe(types.string),
    subscription_plan_option: SubscriptionPlanOption,
    start_date: types.string,
    end_date: types.string,
    status: types.string,
    cut_off_date: types.maybeNull(types.string),
    batch_student: types.maybeNull(BatchStudentStore),
  })
  .actions((self) => ({
    sendRenewalLink: flow(function* (phone?: string) {
      const response: ApiResponse<any> = yield sendRenewalLink(self.name, phone);
      if (response.problem) throw Error(response.data.error);
      return true;
    }),
  }));

export const SubscriptionStore = types
  .model('SubscriptionStore', {
    loading: false,
    mySubscription: types.maybe(UserSubscription),
    subscriptionsExpiringSoon: types.array(UserSubscriptionExpiry),
    expiredButActiveSubscriptions: types.array(UserSubscriptionExpiry),
    page: types.maybe(PageStore),
  })
  .actions((self) => ({
    fetchUserSubscriptionPlan: flow(function* (username: string, subject?: string) {
      try {
        self.loading = true;
        self.mySubscription = undefined;
        const response: ApiResponse<any> = yield fetchSubscriptionPlan({ username, subject });
        if (response.ok) {
          self.mySubscription = response.data?.plan_info;
        } else if (response.problem) {
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchUserSubscription: flow(function* (username: string) {
      try {
        self.loading = true;
        self.mySubscription = undefined;
        const response: ApiResponse<any> = yield fetchUserSubscription(username);
        if (response.ok) {
          self.mySubscription = response.data;
        } else if (response.problem) {
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchSubscriptionsExpiringSoon: flow(function* ({ page, pageSize }: { page?: number; pageSize?: number }) {
      try {
        self.loading = true;
        self.subscriptionsExpiringSoon.clear();
        const response: ApiResponse<any> = yield fetchSubscriptionsExpiringSoon({ page, pageSize });
        if (response.ok) {
          self.page = response.data.page;
          self.subscriptionsExpiringSoon = cast(response.data.results);
        } else if (response.problem) {
          return;
        }
      } finally {
        self.loading = false;
      }
    }),
    fetchExpiredButActiveSubscriptions: flow(function* ({ page, pageSize }: { page?: number; pageSize?: number }) {
      try {
        self.loading = true;
        self.expiredButActiveSubscriptions.clear();
        const response: ApiResponse<any> = yield fetchExpiredButActiveSubscriptions({ page, pageSize });
        if (response.ok) {
          self.page = response.data.page;
          self.expiredButActiveSubscriptions = cast(response.data.results);
        }
      } finally {
        self.loading = false;
      }
    }),
  }));
