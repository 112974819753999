import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { MasterclassMessage } from './MasterclassMessages';
import { RTModel } from './RTModel';

const MasterclassChatState = RTModel.named('MasterclassChatState')
  .props({
    messageFeed: types.array(types.string),
    messageMap: types.map(MasterclassMessage),
    submitMessage: InvokerType
  }).actions((self) => ({
      submitChatMessage(content: string){
          return self.submitMessage.invoke({content})
      }

  }));

export default MasterclassChatState;
export interface IMasterclassChatState extends Instance<typeof MasterclassChatState> {}
