import { listCourses } from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';
import { cast, flow, getEnv, IAnyModelType, Instance, types } from 'mobx-state-tree';
import { PageStore } from '../Page';

export function CourseLate(): IAnyModelType {
  return types.model('Course', {
    name: types.string,
    title: types.string,
    subject: types.union(
      types.literal('coding'),
      types.literal('science'),
      types.literal('core'),
      types.literal('holistic'),
      types.literal('neoschool'),
      types.literal('eveningschool')
    ),
    loading: false,
    loaded: false,
  });
}

export interface ICourse extends Instance<ReturnType<typeof CourseLate>> {}

export const CourseStore = types
  .model('CourseStore', {
    courses: types.array(types.late(CourseLate)),
    loading: false,
    loaded: false,
    page: types.maybeNull(PageStore),
  })
  .actions((self) => ({
    listCourses: flow(function* ({ subject }: { subject?: string }) {
      try {
        self.loading = true;
        self.loaded = false;
        const response: ApiResponse<any> = yield listCourses({ subject });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.courses = cast(response.data);
        self.loaded = true;
      } finally {
        self.loading = false;
      }
    }),
    clearCourses: () => {
      self.courses = cast([]);
      self.loaded = false;
    },
  }));

export interface ICourseStore extends Instance<typeof CourseStore> {}
