import { observer } from 'mobx-react-lite';
import React, { createContext, useCallback, useEffect, useState } from 'react';

export interface Notification {
  message: string;
  sender: string;
  senderIdentifier: string;
  isRead: boolean;
}

interface NotificationsHandlerContextType {
  addNotification: ({
    notificationDbIdentifier,
    notificationObj,
  }: {
    notificationDbIdentifier: string;
    notificationObj: Notification;
  }) => void;
  getNotifications: (notificationDbIdentifier: string) => Notification[];
  markNotificationAsRead: (notificationDbIdentifier: string, senderIdentifier: string) => void;
}

export const NotificationsHandlerContext = createContext<NotificationsHandlerContextType>({
  addNotification: () => null,
  getNotifications: () => [],
  markNotificationAsRead: () => null,
});

export const NotificationsHandlerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notificationsHolder, setNotificationsHolder] = useState<{ [key: string]: Notification[] }>({});

  useEffect(() => {
    const loadAllNotificationsFromStorage = () => {
      const sqNotificationsString = localStorage.getItem('sq_notifications');
      const sqNotifications = JSON.parse(sqNotificationsString || '{}');
      setNotificationsHolder(sqNotifications);
    };

    loadAllNotificationsFromStorage();
  }, []);

  const getNotifications = useCallback(
    (notificationDbIdentifier: string) => {
      return notificationsHolder[notificationDbIdentifier] || [];
    },
    [notificationsHolder]
  );

  const addNotification = useCallback(
    ({
      notificationDbIdentifier,
      notificationObj,
    }: {
      notificationDbIdentifier: string;
      notificationObj: Notification;
    }) => {
      const notifications = [...(notificationsHolder[notificationDbIdentifier] || []), notificationObj];
      const updatedNotificationsHolder = {
        ...notificationsHolder,
        [notificationDbIdentifier]: notifications,
      };
      setNotificationsHolder(updatedNotificationsHolder);
      localStorage.setItem('sq_notifications', JSON.stringify(updatedNotificationsHolder));
    },
    [notificationsHolder]
  );

  const markNotificationAsRead = useCallback(
    (notificationDbIdentifier: string, senderIdentifier: string) => {
      setNotificationsHolder((oldHolder) => {
        const notifications = oldHolder[notificationDbIdentifier] || [];
        let isUpdated = false;

        const updatedNotifications = notifications.map((notification) => {
          if (notification.senderIdentifier === senderIdentifier && !notification.isRead) {
            isUpdated = true;
            return { ...notification, isRead: true };
          }
          return notification;
        });
        if (!isUpdated) return oldHolder;
        const updatedNotificationsHolder = {
          ...oldHolder,
          [notificationDbIdentifier]: updatedNotifications,
        };
        localStorage.setItem('sq_notifications', JSON.stringify(updatedNotificationsHolder));
        return updatedNotificationsHolder;
      });
    },
    []
  );

  return (
    <NotificationsHandlerContext.Provider value={{ getNotifications, addNotification, markNotificationAsRead }}>
      {children}
    </NotificationsHandlerContext.Provider>
  );
};

export default observer(NotificationsHandlerProvider);
