import AuthStore from './AuthStore';
import UserStore from './UserStore';
import CommonStore from './CommonStore';
import BrowserCheck from './BrowserCheck';
import TutorSessionStore from './TutorSessionStore';
import PointerStore from './PointerStore';
import { CatchupSessionStore, CompletedSessionStore, CompletedStudentSessionStore } from './CompletedSession';
import StatsStore from './StatsStore';
import { ProjectStore } from './ProjectStore';
import SignUpStore from './SignUpStore';
import RTControl from './RT';
import MasterclassStore from './MasterclassStore';
import { StudentReports, BadgeReports, StudentReportStore } from './StudentReportStore';
import { AchievementStore } from './AchievementStore';
import NoteStore from './NoteStore';
import { StudentStore } from './StudentStore';
import { TranscodedVideoStore } from './TranscodedVideoStore';
import { CoachRecordingStore } from './CoachRecordingStore';
import { ParentFeedbackStore } from './ParentFeedbackStore';
import InsightCallListStore from './InsightCallStore';
import InsightCallSessionStore from './InsightCallSessionStore';
import InsightCallFeedbackStore from './InsightCallFeedbackStore';
import EmpathyFormStore from './EmpathyStore';
import { CatchupStore } from './CatchupStore';
import { BatchGalleryStore } from './BatchGalleryStore';
import { BatchProjectFeedbackStore } from './BatchProjectStore';
import FirebaseAuthStore from './FirebaseAuthStore';
import DailyReportStore from './DailyReportStore';
import { SubscriptionStore } from './SubscriptionStore';
import { PomodoroStore } from './PomodoroStore';

export const commonStore = CommonStore.create({});
export const userStore = UserStore.create({});
export const firebaseAuthStore = FirebaseAuthStore.create({});
export const authStore = AuthStore.create({}, { userStore, commonStore });
export const signUpStore = SignUpStore.create({}, { userStore, commonStore });
export const browserCheckStore = BrowserCheck.create({});
export const tutorSessionStore = TutorSessionStore.create({}, { commonStore });
export const projectStore = ProjectStore.create({}, { commonStore });
export const catchupStore = CatchupStore.create({});
export const pointerStore = PointerStore.create({});

export const studentStore = StudentStore.create({});

export const completedSessionStore = CompletedSessionStore.create({}, { commonStore });
export const completedStudentSessionStore = CompletedStudentSessionStore.create({}, { commonStore });
export const catchupSessionStore = CatchupSessionStore.create({}, { commonStore });

export const statsStore = StatsStore.create({});

export const rtStore = RTControl.create({ watchables: {} }, { commonStore });

export const masterclassStore = MasterclassStore.create({}, { commonStore });
export const studentReportStore = StudentReportStore.create({});
export const studentReports = StudentReports.create({});
export const badgeReports = BadgeReports.create({});
export const achievementStore = AchievementStore.create({}, { commonStore });
export const noteStore = NoteStore.create({}, { commonStore });
export const transcodedVideoStore = TranscodedVideoStore.create({}, { commonStore });
export const coachRecordingStore = CoachRecordingStore.create({});
export const parentFeedbackStore = ParentFeedbackStore.create({});
export const insightCallListStore = InsightCallListStore.create({}, { commonStore });
export const insightCallSessionStore = InsightCallSessionStore.create({}, { commonStore });
export const insightCallFeedbackStore = InsightCallFeedbackStore.create({}, {});
export const empathyFormStore = EmpathyFormStore.create({});
export const batchGalleryStore = BatchGalleryStore.create({}, { commonStore });
export const batchProjectFeedbackStore = BatchProjectFeedbackStore.create({}, { commonStore });
export const dailyReportStore = DailyReportStore.create({}, { commonStore });
export const pomodoroStore = PomodoroStore.create({}, { commonStore });

export const subscriptionStore = SubscriptionStore.create();