import { Instance, types } from 'mobx-state-tree';

export const PomodoroStore = types
  .model({
    timerSeconds: 0,
    timerStarted: false,
    mode: types.optional(types.union(types.literal('BREAK'), types.literal('DEEP-WORK')), 'DEEP-WORK'),
  })
  .actions((self) => ({
    resetTimer() {
      self.timerSeconds = 0;
    },
  }))
  .actions((self) => ({
    setMode(mode: 'BREAK' | 'DEEP-WORK') {
      if (mode === self.mode) return;
      self.mode = mode;
      self.resetTimer();
    },
    setTimerSeconds(seconds: number) {
      self.timerSeconds = seconds;
    },
    setTimerStarted(hasStarted: boolean) {
      self.timerStarted = hasStarted;
    },
  }))
  .actions((self) => ({
    startDeepWork() {
      self.setMode('DEEP-WORK');
      self.timerStarted = true;
    },
    stopDeepWork() {
      self.setMode('BREAK');
    },
  }))
  .views((self) => ({
    get timerColor() {
      if (self.mode === 'BREAK') return '#D5573B';
      if (self.timerSeconds > 1200) return '#D90368';
      return '#541388';
      
    },
  }));

export interface IPomodoroStore extends Instance<typeof PomodoroStore> {}
