import { Instance, types } from 'mobx-state-tree';
import { PollResult } from './PollStore';
import { toJS } from 'mobx';

export const HTMLQuestionStore = types
  .model('HTMLQuestionStore', {
    results: types.maybeNull(PollResult),
    answerMap: types.maybeNull(types.map(types.frozen())),
    displayResults: types.boolean,
  })
  .actions((self) => ({
    showResults() {
      self.displayResults = true;
    },
    hideResults() {
      self.displayResults = false;
    },
  }))
  .views((self) => ({
    get answersTOJS() {
      return toJS(self.answerMap);
    },
  }));

export interface IHTMLQuestionStore extends Instance<typeof HTMLQuestionStore> {}
