import { types, Instance, flow, cast } from 'mobx-state-tree';
import { getWhatsAppTemplatesList, sendWhatsAppTemplateMessages } from 'api';
import { ApiResponse } from 'apisauce';
import { RTModel } from '../RTModel';

function matchFileExtension(url: string, fileType: 'IMAGE' | 'VIDEO' | 'DOCUMENT') {
  if (!(url.startsWith('http://') || url.startsWith('https://'))) return false;
  switch (fileType) {
    case 'IMAGE':
      return url.match(/\.(jpeg|jpg|png)$/) !== null;
    case 'VIDEO':
      return url.match(/\.(mp4|3gpp)$/) !== null;
    case 'DOCUMENT':
      return url.match(/\.(pdf|doc|docx|ppt|pptx)$/) !== null;
  }
}

function getFilename(url: string) {
  if (!url) return '';
  const splitURL = url.split('/');
  return splitURL[splitURL.length - 1];
}

interface IHeaderParameter {
  type: 'image' | 'video' | 'document';
  image?: { link: string };
  video?: { link: string };
  document?: { link: string; filename?: string };
}

const HeaderComponent = types.model('HeaderComponent', {
  format: types.union(types.literal('VIDEO'), types.literal('IMAGE'), types.literal('DOCUMENT')),
  type: types.literal('HEADER'),
});

const BottomComponent = types.model('BottomComponents', {
  text: types.string,
  type: types.union(types.literal('BODY'), types.literal('FOOTER')),
});

const QuickReplyButton = types.model('QuickReplyButton', {
  type: types.literal('QUICK_REPLY'),
  text: types.string,
});

const URLButton = types.model('URLButton', {
  text: types.string,
  type: types.literal('URL'),
  url: types.string,
});

const PhoneNumberButton = types.model('URLButton', {
  text: types.string,
  type: types.literal('PHONE_NUMBER'),
  phone_number: types.string,
});

const ButtonComponent = types.model('ButtonComponent', {
  type: types.literal('BUTTONS'),
  buttons: types.array(types.union(QuickReplyButton, URLButton, PhoneNumberButton)),
});

const TemplateModel = types.model('TemplateModel', {
  category: types.maybe(types.string),
  components: types.maybe(types.array(types.union(HeaderComponent, BottomComponent, ButtonComponent))),
  language: types.maybe(types.string),
  name: types.identifier,
  namespace: types.maybe(types.string),
  status: types.maybe(types.string),
});

const CSVModel = types.model('CSVModel', {
  data: types.frozen(),
  meta: types.frozen(),
});

const MinorErrorsModel = types
  .model('MinorErrorsModel', {
    duplicates: 0,
    invalidFields: 0,
  })
  .actions((self) => ({
    incrementDuplicates() {
      self.duplicates++;
    },
    incrementInvalidFields() {
      self.invalidFields++;
    },
    clear() {
      self.duplicates = 0;
      self.invalidFields = 0;
    },
  }));

const WhatsAppSender = types.model('WhatsAppSender', {
  SUBJECT: types.identifier,
  WA_ID: types.string,
});

export interface IWhatsAppSender extends Instance<typeof WhatsAppSender> {}
export interface ICSVModel extends Instance<typeof CSVModel> {}
export interface IBottomComponent extends Instance<typeof BottomComponent> {}
export interface IHeaderComponent extends Instance<typeof HeaderComponent> {}
export interface IButtonComponent extends Instance<typeof ButtonComponent> {}
export interface ITemplateModel extends Instance<typeof TemplateModel> {}
export interface IMinorErrorsModel extends Instance<typeof MinorErrorsModel> {}
export interface IURLButton extends Instance<typeof URLButton> {}

export const TemplateSenderStore = types
  .model('TemplateSenderStore', {
    senders: types.array(WhatsAppSender),
    templates: types.array(TemplateModel),
    error: types.optional(types.string, ''),
    selectedTemplate: types.maybe(types.reference(TemplateModel)),
    csvRows: types.array(types.map(types.frozen())),
    previewString: types.maybe(types.string),
    unfilteredCSV: types.array(CSVModel),
    minorErrors: MinorErrorsModel,
    selectedSender: types.safeReference(WhatsAppSender),
    sessionID: '',
  })
  .actions((self) => ({
    fetchNewTemplates: flow(function* () {
      const response: ApiResponse<any> = yield getWhatsAppTemplatesList();
      if (response.status !== 200) return false;
      self.templates = response.data.templates;
      self.senders = cast(response.data.senders);
      if (self.senders.length < 0) return;
      const salesSender = self.senders.find((sender: IWhatsAppSender) => sender.SUBJECT === 'sales');
      self.selectedSender = salesSender || self.senders[0];
      return true;
    }),
    resetCompleteCSV() {
      this.setCSVRows([]);
      this.setUnfilteredCSV([]);
      this.setError('');
      this.clearMinorErrors();
    },
    setError(newError: string) {
      self.error = newError;
    },
    setTemplate(template: ITemplateModel) {
      self.selectedTemplate = template;
    },
    setCSVRows(csvrows: any) {
      self.csvRows = csvrows;
    },
    setPreviewString(previewString: string) {
      self.previewString = previewString;
    },
    sendTemplatesToAll: flow(function* () {
      if (!self.selectedSender?.SUBJECT) return;
      const resp = yield sendWhatsAppTemplateMessages({
        template_name: self.selectedTemplate?.name,
        template_data: self.csvRows,
        subject: self.selectedSender.SUBJECT,
      });
      self.csvRows = cast([]);
      self.sessionID = resp.data.session_id;
      return resp.status;
    }),
    sentTemplatesToFirst: flow(function* () {
      if (!self.selectedSender?.SUBJECT) return;
      const resp = yield sendWhatsAppTemplateMessages({
        template_name: self.selectedTemplate?.name,
        template_data: [self.csvRows[0]],
        subject: self.selectedSender.SUBJECT,
      });
      self.csvRows = cast([]);
      self.sessionID = resp.data.session_id;
      return resp.status;
    }),
    setUnfilteredCSV(unfilteredCSV: any) {
      self.unfilteredCSV = unfilteredCSV;
    },
    clearMinorErrors() {
      self.minorErrors.clear();
    },
    validateBodyHeader(bodyComponent: IBottomComponent, unfilteredCSV: ICSVModel[]) {
      const bodyText = bodyComponent.text;
      let numberOfVarsRequired = 0;
      const previewString = bodyText.replace(/{{\w+}}/g, (variable) => {
        const theKey = variable.substring(2, variable.length - 2);
        if (!unfilteredCSV[0].data[theKey]) this.setError('The required variables are not present');
        numberOfVarsRequired = numberOfVarsRequired < parseInt(theKey) ? parseInt(theKey) : numberOfVarsRequired;
        return unfilteredCSV[0].data[theKey];
      });
      for (let digit of unfilteredCSV[0].meta.fields) {
        if (!isNaN(digit) && digit > numberOfVarsRequired)
          return this.setError(`Extra variables added in the CSV. Please add ${numberOfVarsRequired} variables.`);
      }

      if (self.error === 'The required variables are not present')
        return this.setError(`${self.error}. Required ${numberOfVarsRequired} variables.`);

      if (!self.error) return this.setPreviewString(previewString);
    },
    validateBodyRow(csvRow: ICSVModel) {
      const variablesArray = [];
      for (let digit of csvRow.meta.fields) {
        if (digit && !csvRow.data[digit]) {
          self.minorErrors.incrementInvalidFields();
          return false;
        }
        if (!isNaN(digit) && digit) {
          variablesArray.push({
            type: 'text',
            text: csvRow.data[digit] as string,
          });
        }
      }
      return variablesArray;
    },
    validateButtonHeader(buttonComponent: IButtonComponent, unfilteredCSV: ICSVModel[]) {
      const firstRow = unfilteredCSV[0];

      for (let i = 0; i < buttonComponent.buttons.length; i++) {
        const button = buttonComponent.buttons[i];
        if (button.type === 'URL') {
          const url = button.url.split('{')[0];
          if (!firstRow.data[`button${i + 1}`] || firstRow.data[`button${i + 1}`].slice(0, url.length) !== url) {
            self.minorErrors.incrementInvalidFields();
            return this.setError(
              `Invalid button variables were provided. Please provide ${buttonComponent.buttons.length} button variables`
            );
          }
        }
      }
    },
    validateButtonRow(buttonComponent: IButtonComponent, csvRow: ICSVModel) {
      const otherComponents = [] as {
        type: 'button';
        sub_type: 'url';
        index: number;
        parameters: { type: string; text: string }[];
      }[];
      for (let i = 0; i < buttonComponent.buttons.length; i++) {
        const button = buttonComponent.buttons[i];
        if (button.type === 'URL') {
          const splitURL = button.url.split('{');
          const url = splitURL[0];
          const buttonText = csvRow.data[`button${i + 1}`];
          if (!buttonText || buttonText.length < url.length || buttonText.slice(0, url.length) !== url) {
            self.minorErrors.incrementInvalidFields();
            return false;
          }
          otherComponents.push({
            type: 'button',
            sub_type: 'url',
            index: i,
            parameters: [{ type: 'text', text: buttonText.slice(url.length) }],
          });
        }
      }
      return otherComponents;
    },
    // Validates whether the header components are correct in the CSV
    validateHeaderComponent(headerComponent: IHeaderComponent, csvRow: ICSVModel, firstRow: boolean = false) {
      const headerComponents = [] as IHeaderParameter[];
      let error = '';
      switch (headerComponent.format) {
        case 'IMAGE':
          if (!csvRow.data.image || !matchFileExtension(csvRow.data.image, 'IMAGE')) {
            error = 'Image is required in this template';
          }
          headerComponents.push({ type: 'image', image: { link: csvRow.data.image } });
          break;
        case 'VIDEO':
          if (!csvRow.data.video || !matchFileExtension(csvRow.data.video, 'VIDEO')) {
            error = 'Video is required in this template';
          }
          headerComponents.push({ type: 'video', video: { link: csvRow.data.video } });
          break;
        case 'DOCUMENT':
          if (!csvRow.data.document || !matchFileExtension(csvRow.data.document, 'DOCUMENT')) {
            error = 'Document is required in this template';
          }
          headerComponents.push({
            type: 'document',
            document: { link: csvRow.data.document, filename: getFilename(csvRow.data.document) },
          });
          break;
      }
      if (error) {
        if (firstRow) return this.setError(error);
        self.minorErrors.incrementInvalidFields();
        return false;
      }
      if (firstRow) return true;
      return headerComponents;
    },
    onTemplateAndCSVAction() {
      const unfilteredCSV = self.unfilteredCSV;
      if (!unfilteredCSV[0].data.phone) return this.setError("The phone number field doesn't exist");
      if (!self.selectedTemplate?.components) return;
      self.selectedTemplate.components.forEach((component) => {
        if (self.error) return;
        switch (component.type) {
          case 'BODY':
            this.validateBodyHeader(component, unfilteredCSV);
            break;
          case 'BUTTONS':
            this.validateButtonHeader(component, unfilteredCSV);
            break;
          case 'HEADER':
            this.validateHeaderComponent(component, unfilteredCSV[0], true);
            break;
        }
      });

      if (self.error) return;

      const filtered = unfilteredCSV.reduce((acc: any, currValue: any) => {
        if (!currValue.data.phone || (currValue.data.phone.length !== 10 && currValue.data.phone[0] !== '+')) {
          self.minorErrors.incrementInvalidFields();
          return acc;
        }
        let phoneNumber = '';
        if (currValue.data.phone.length === 10 && currValue.data.phone[0] !== '+')
          phoneNumber = `+91${currValue.data.phone}`;
        else if (currValue.data.phone[0] === '+' && currValue.data.phone.length > 1) phoneNumber = currValue.data.phone;
        if (acc.find((obj: any) => obj.phone === phoneNumber)) {
          self.minorErrors.incrementDuplicates();
          return acc;
        }

        if (!self.selectedTemplate?.components) return acc;
        let headerParameters;
        let bodyParameters: false | { type: string; text: string }[] = [];
        let otherComponents;
        let invalidEntry = false;

        self.selectedTemplate.components.forEach((component) => {
          if (invalidEntry) return;
          switch (component.type) {
            case 'BODY':
              bodyParameters = this.validateBodyRow(currValue);
              if (bodyParameters === false) invalidEntry = true;
              break;
            case 'BUTTONS':
              otherComponents = this.validateButtonRow(component, currValue);
              if (otherComponents === false) invalidEntry = true;
              break;
            case 'HEADER':
              headerParameters = this.validateHeaderComponent(component, currValue, false);
              if (headerParameters === false) invalidEntry = true;
              break;
          }
        });
        if (invalidEntry) return acc;
        if (!phoneNumber) {
          self.minorErrors.incrementInvalidFields();
          return acc;
        }

        acc.push({
          phone: phoneNumber,
          bodyParameters: bodyParameters || null,
          otherComponents: otherComponents || null,
          headerParameters: headerParameters || null,
        });

        return acc;
      }, []);
      this.setCSVRows(filtered);
    },
    setWhatsAppSender(sender: IWhatsAppSender) {
      self.selectedSender = sender;
    },
  }));

export interface ITemplateSenderStore extends Instance<typeof TemplateSenderStore> {}

export const TemplateSenderStatus = RTModel.named('TemplateSenderStatus').props({
  currentCount: 0,
  totalCount: 0,
});

export interface ITemplateSenderStatus extends Instance<typeof TemplateSenderStatus> {}
