import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';

export interface IReferralRow {
  name: string;
  phone: string;
}

export const FeedbackStore = types
  .model('FeedbackStore', {
    isShown: false,
    hasSubmitted: false,
    formType: types.maybe(types.maybeNull(types.string)),
    loadForm: InvokerType,
    submitForm: InvokerType,
    submitReferralForm: InvokerType,
  })
  .actions((self) => ({
    showFeedbackForm() {
      self.isShown = true;
    },
    hideFeedbackForm() {
      self.isShown = false;
    },
    setHasSubmitted(val: boolean) {
      self.hasSubmitted = val;
    },
    submit(rating: number, issues: string) {
      return self.submitForm.invoke({ rating, issues });
    },
    setDeferred(days: number) {
      const date = new Date();
      date.setDate(date.getDate() + days);
      window.localStorage.setItem('referralDefer', date.toISOString());
    },
    submitReferrals(referrals: Array<IReferralRow>) {
      return self.submitReferralForm.invoke({ referrals });
    },
    setScienceInviteShown(state: boolean){
      localStorage.setItem('showScienceInvite', String(state));
    }
  }))
  .views((self) => ({
    get canShowReferral() {
      return false;
      // const deferDate = window.localStorage.getItem('referralDefer');
      // if (!deferDate) {
      //   return true;
      // }
      // return new Date(deferDate) < new Date();
    },
    get canShowScienceInvite(){
      return localStorage.getItem('showScienceInvite') !== 'true'
    }
  }));

export interface IFeedbackStore extends Instance<typeof FeedbackStore> {}
