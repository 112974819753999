import { types, Instance, cast } from 'mobx-state-tree';
import { throttle } from 'utils/throttle';
import InvokerType from './InvokerType';


export const ReactionType = types
  .model('ReactionType', {
    id: types.string,
    reaction: types.string,
    username: types.string,
    timestamp: types.number,
  });

export interface IReactionType {
  id: string;
  reaction: string;
  username: string;
  timestamp: number;
}

export interface IReactionPacket {
  id: string;
  rn: string;
  un: string;
}

export const ReactionStore = types
  .model('ReactionStore', {
    enableReactions: false,
    addNewReaction: InvokerType,
    rn: types.maybeNull(types.frozen({})),
    _localReaction: types.maybeNull(types.frozen({})),
  })
  .actions((self) => {
    const invokeThrottledReaction = throttle((params: any) => {
      self.addNewReaction.invokeAndForget(params);
    }, 1000);
    return {
      invokeThrottledReaction,
    };
  })
  .actions((self) => ({
    pushNewReaction(reaction: string, username: string) {
      const reactionO = { reaction, username };
      const localReaction = { reaction, username, id: Math.random().toString() };
      self._localReaction = cast(localReaction);
      self.invokeThrottledReaction(reactionO);
    },
    toggleFeedbackReaction() {
      self.enableReactions = !self.enableReactions;
    },
    hideFeedbackReaction() {
      self.enableReactions = false;
    },
    showFeedbackReaction() {
      self.enableReactions = true;
    },
  }))
  .views((self) => ({
    get localReaction() {
      return self._localReaction;
    },
    get newReaction() {
      return self.rn;
    },
  }));

export interface IReactionStore extends Instance<typeof ReactionStore> { }
