import { types, Instance } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { FeedbackStore } from './FeedbackStore';
import { RTModel } from './RTModel';

const RefreshStore = types
  .model('RefreshStore', {
    r: types.optional(types.number, -1),
  })
  .actions((self) => ({
    refreshPage() {
      self.r = Math.random();
    },
  }));

const TutorSessionStudentState = RTModel.named('TutorSessionStudentState')
  .props({
    refreshStore: RefreshStore,
    feedbackStore: types.maybe(FeedbackStore),
    activityRoom: types.maybe(types.string),
    activityRoomError: '',
    playlistIndex: 0,
    targetPlaylistIndex: 0,
    refreshActivityRoom: InvokerType,
    moveToPlaylistItem: InvokerType,
    completed: false,
    chatRoomName: '',
    exitSessionInvoke: InvokerType,
    showParentDetailPopup: false,
    showEmpathyDialog: false,
  })
  .actions((self) => ({
    refreshRoom() {
      return self.refreshActivityRoom.invoke();
    },
    moveToPlaylist(index: number, actIndex?: number) {
      self.moveToPlaylistItem.invoke({ index, actIndex });
    },
    exitSession() {
      return self.exitSessionInvoke.invoke();
    },
    setShowParentDetailPopup(val: boolean) {
      self.showParentDetailPopup = val;
    },
    setShowEmpathyDialog(val: boolean) {
      self.showEmpathyDialog = val;
    },
  }))
  .views((self) => ({
    get isMoving() {
      return self.moveToPlaylistItem.invoking || self.targetPlaylistIndex !== self.playlistIndex;
    },
  }));

export default TutorSessionStudentState;
export interface ITutorSessionStudentState extends Instance<typeof TutorSessionStudentState> {}
