import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';
import User, { IUser } from './User';

const GroupState = RTModel.named('GroupState')
  .props({
    name: types.string,
    students: types.array(User),
    add: InvokerType,
    remove: InvokerType,
  })
  .actions((self) => ({
    addStudent(student: IUser) {
      return self.add.invoke({ username: student.username });
    },
    removeStudent(student: IUser) {
      return self.remove.invoke({ username: student.username });
    },
  }));

export default GroupState;
export interface IGroupState extends Instance<typeof GroupState> {}
