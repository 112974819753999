import {
  createSessionClip,
  fetchSessionRecordingForFeedback,
  getSessionClipSuggestions,
  markSessionQualityThreadAcknoledged,
  optimizeVideo,
  postSessionQualityReply,
  postSessionQualityThread,
} from 'api';
import { ApiResponse } from 'apisauce';
import Config from 'config';
import { parse } from 'fecha';
import { toJS } from 'mobx';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { formatDate } from 'utils/formatDate';
import Badge from '../BadgesStore';
import { CoachLite } from './CoachStore';
import Sprint from './Sprint';
import User, { IStudent, Student, StudentLite } from '../User';

export function inMinutesSeconds(totalInSeconds: number) {
  const minutes = Math.floor(totalInSeconds / 60);
  const seconds = totalInSeconds - minutes * 60;

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export function durationString(dateString: string) {
  const dateMilliseconds = parse(dateString, Config.DATETIME_FORMAT)?.getTime();
  if (!dateMilliseconds) return '';

  const currentDateTime = Date.now();

  const ms = currentDateTime - dateMilliseconds;

  let seconds = ms / 1000;
  let minutes = ms / (1000 * 60);
  let hours = ms / (1000 * 60 * 60);
  let days = ms / (1000 * 60 * 60 * 24);
  if (seconds < 60) return seconds.toFixed(0) + ' secs';
  else if (minutes < 60) return minutes.toFixed(0) + ' mins';
  else if (hours < 24) return hours.toFixed(0) + ' hrs';
  else return days.toFixed(0) + ' days';
}

const ThreadCount = types
  .model('ThreadCount', {
    total: 0,
    unread: 0,
  })
  .actions((self) => ({
    decrementUnreadCount: () => {
      self.unread = self.unread - 1;
    },
    incrementCount: () => {
      self.total = self.total + 1;
      self.unread = self.unread + 1;
    },
  }));

export const SessionQualityCoachLite = types
  .model('SessionQualityCoachLite', {
    name: types.string,
    thread_count: ThreadCount,
    state: types.string,
  })
  .views((self) => ({
    get is_optimized() {
      return self.state === 'Optimized';
    },
    get optimizing() {
      return ['Optimizing', 'Optimize requested'].includes(self.state);
    },
  }))
  .actions((self) => ({
    optimize_video: flow(function* () {
      const response: ApiResponse<any> = yield optimizeVideo(self.name);
      return response.ok;
    }),
    setState(state: string) {
      self.state = state;
    },
  }));

export interface ISessionQualityCoachLite extends Instance<typeof SessionQualityCoachLite> {}

const TutorSessionForFeedback = types
  .model('TutorSessionForFeedback', {
    name: types.string,
    start_at: types.string,
    sprint: Sprint,
    students: types.array(Student),
  })
  .views((self) => ({
    getStartAtDateString: () => {
      return formatDate(self.start_at, 'Do MMM, YYYY');
    },
  }));

export const SessionBadgeEvent = types.model('SessionBadgeEvent', {
  badge: Badge,
});

export interface ISessionBadgeEvent extends Instance<typeof SessionBadgeEvent> {}

export const EventActivity = types.union(SessionBadgeEvent);

export interface IEventActivity extends Instance<typeof EventActivity> {}

const SessionEvents = types.model('SessionEvents', {
  name: types.identifier,
  event_type: types.union(
    types.literal('new_badge'),
    types.literal('highlight_peak_moment'),
    types.literal('coach_generated_clip')
  ),
  start_at: types.string,
  relative_timestamp: types.number,
  student_skill: types.maybeNull(types.string),
  clip_url: types.maybeNull(types.string),
  student: types.maybeNull(StudentLite),
  created_by: types.maybeNull(User),
  event_activity: types.maybeNull(EventActivity),
});

export interface ISessionEvents extends Instance<typeof SessionEvents> {}

const SessionRecordingFeedback = types
  .model('SessionRecordingFeedback', {
    name: types.string,
    recording_url: types.string,
    is_optimized: types.boolean,
    tutor_session: TutorSessionForFeedback,
    session_events: types.array(SessionEvents),
    clip_Suggestions: types.array(SessionEvents),
    selectedSuggestedClip: types.safeReference(SessionEvents),
  })
  .actions((self) => ({
    fetchClipsSuggestions: flow(function* () {
      try {
        const response: ApiResponse<any> = yield getSessionClipSuggestions(self.name);
        if (response.problem) return;
        self.clip_Suggestions = cast(response.data);
      } finally {
      }
    }),
    setSelectedSuggestedClip: (clip?: ISessionEvents) => {
      self.selectedSuggestedClip = clip;
    },
  }));

const SessionQualityReply = types.model('SessionQualityReply', {
  content: types.string,
  user: User,
  created_at: types.string,
});

const SessionQualityThread = types
  .model('SessionQualityThread', {
    name: types.identifier,
    acknowledged_at: types.maybeNull(types.string),
    video_timestamp: types.number,
    content: types.string,
    thread_owner: User,
    user: User,
    replies: types.array(SessionQualityReply),
    created_at: types.string,
    errors: '',
    postingReply: false,
    markingAcknowledged: false,
  })
  .actions((self) => ({
    postSessionQualityReply: flow(function* (content: string) {
      self.postingReply = true;
      self.errors = '';
      try {
        const response: ApiResponse<any> = yield postSessionQualityReply(self.name, content);
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          self.errors = response.problem;
          return;
        }
        const reply = response.data.reply;
        self.replies.push(cast(reply));
      } finally {
        self.postingReply = false;
      }
    }),
    markAcknowledged: flow(function* () {
      self.markingAcknowledged = true;
      self.errors = '';
      try {
        const response: ApiResponse<any> = yield markSessionQualityThreadAcknoledged(self.name);
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          self.errors = response.problem;
          return;
        }
        self.acknowledged_at = response.data.acknowledged_at;
      } finally {
        self.markingAcknowledged = false;
      }
    }),
  }));

export const SessionRecordingQualityCoach = types
  .model('SessionRecordingQualityCoach', {
    session_recording: SessionRecordingFeedback,
    coach: CoachLite,
    threads: types.array(SessionQualityThread),
    thread_count: ThreadCount,
    currentTime: 0,
    manualPlayerTime: 0,
    manualUpdateTime: false,
    errors: '',
    postingThread: false,
    highlightedThread: types.safeReference(SessionQualityThread),
    nextVideoTimestamp: 0,
    trimTimeStamps: types.frozen({ start: 0, end: 0 }),
    cropCordinates: types.frozen({ width: 0, height: 0, x: 0, y: 0 }),
    taggedStudent: types.safeReference(Student),
    taggesSkill: types.maybe(types.string),
  })
  .actions((self) => ({
    setVideoPlayerTime: (newTime: number) => {
      self.currentTime = newTime;
    },
    setManualPlayerTime: (newTime: number) => {
      self.manualPlayerTime = newTime;
      self.manualUpdateTime = true;
    },
    setManualUpdateStatus(status: boolean) {
      self.manualUpdateTime = status;
    },
    setTrimTimeStamps(start: number, end: number) {
      self.trimTimeStamps = { start, end };
    },
    setCropCordinates(width: number, height: number, x: number, y: number) {
      self.cropCordinates = { width, height, x, y };
    },
    resetVideoClipState: () => {
      self.trimTimeStamps = { start: 0, end: 0 };
      self.cropCordinates = { width: 0, height: 0, x: 0, y: 0 };
    },
    addSkill(skill?: string) {
      self.taggesSkill = skill;
    },
    tagStudent(student?: IStudent) {
      self.taggedStudent = student;
    },
    addThread: flow(function* (content: string) {
      self.postingThread = true;
      const videoTimestamp = self.currentTime;
      self.errors = '';
      try {
        const postResponse: ApiResponse<any> = yield postSessionQualityThread(
          self.session_recording.name,
          content,
          videoTimestamp
        );
        if (postResponse.problem) {
          getEnv(self).commonStore.setNetworkProblem(postResponse.problem);
          self.errors = postResponse.problem;
          return;
        }
        const newThread: ISessionQualityThread = postResponse.data.thread;
        const threads = toJS(self.threads);
        threads.push(newThread);
        self.threads = cast(threads);
        self.thread_count.incrementCount();
      } finally {
        self.postingThread = false;
      }
    }),
    createClip: flow(function* () {
      self.errors = '';
      try {
        const crop_params =
          self.cropCordinates.height > 0 && self.cropCordinates.width > 0 ? self.cropCordinates : undefined;
        const response: ApiResponse<any> = yield createSessionClip({
          sessionRecordingName: self.session_recording.name,
          start_at: self.trimTimeStamps.start,
          end_at: self.trimTimeStamps.end,
          crop_params: crop_params,
          studentSkill: self.taggesSkill,
          student: self.taggedStudent?.username,
        });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          self.errors = response.problem;
          return false;
        }
        return true;
      } finally {
      }
    }),
    setHighlightedThread: (thread: ISessionQualityThread) => {
      self.highlightedThread = thread;
    },
    setNextVideoTimestamp: (videoTimestamp: number) => {
      self.nextVideoTimestamp = videoTimestamp;
    },
  }))
  .views((self) => ({
    getCurrentTime: () => {
      return inMinutesSeconds(self.currentTime);
    },
  }));

const SessionRecordingFeedbackStore = types
  .model('SessionRecordingFeedbackStore', {
    session_recording_quality_coach: types.maybe(SessionRecordingQualityCoach),
    loading: false,
    errors: '',
  })
  .actions((self) => ({
    fetchSessionRecordingForFeedback: flow(function* (sessionName: string) {
      self.loading = true;
      self.errors = '';
      try {
        const response: ApiResponse<any> = yield fetchSessionRecordingForFeedback(sessionName);
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          self.errors = response.problem;
          return;
        }
        self.session_recording_quality_coach = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
  }));

export interface ISessionRecordingFeedback extends Instance<typeof SessionRecordingFeedback> {}
export interface ISessionRecordingQualityCoach extends Instance<typeof SessionRecordingQualityCoach> {}
export interface ISessionRecordingFeedbackStore extends Instance<typeof SessionRecordingFeedbackStore> {}
export interface ISessionQualityThread extends Instance<typeof SessionQualityThread> {}
export interface ISessionQualityReply extends Instance<typeof SessionQualityReply> {}
export interface ISessionQualityCoachLite extends Instance<typeof SessionQualityCoachLite> {}

export default SessionRecordingFeedbackStore;
