import Config from 'config';
import { parse } from 'fecha';
import { Instance, types } from 'mobx-state-tree';
import User from './User';

export const MasterclassMessage = types
  .model('MasterclassMessage', {
    pks: types.optional(types.string, 'none'),
    content: types.string,
    sender: User,
    user: User,
    message_type: types.string,
    created_at: types.string,
    updated_at: types.string,
    is_starred: types.boolean,
    is_scholarship_availed: types.boolean,
    timestamp: types.maybeNull(types.number),
  })
  .views((self) => ({
    get createdAtDate() {
      if (self.timestamp) {
        return parse(new Date(self.timestamp).toJSON(), Config.DATETIME_FORMAT);
      }
      return parse(self.created_at, Config.DATETIME_FORMAT);
    },
    get createdAt() {
      return parse(self.created_at, Config.DATETIME_FORMAT);
    },
    get updatedAt() {
      const parsed = parse(self.created_at, Config.DATETIME_FORMAT);
      if (parsed === null) return new Date();
      return parsed;
    },
  }));

export interface IMasterclassMessage extends Instance<typeof MasterclassMessage> { }


export const CannedMasterclassMessage = types
  .model('MasterclassMessage', {
    id: types.number,
    content: types.string
  })


export interface ICannedMasterclassMessage extends Instance<typeof CannedMasterclassMessage> { }