import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApiResponse } from 'apisauce';
import { checkPermissions } from 'api';

interface IAccessControlProps extends React.ComponentProps<any> {
  children?: React.ReactNode;
}

interface IAccessControlContext {
  hasPermission: (arg0: string) => void;
  permissions: { [key: string]: boolean };
}
export const AccessControlContext = React.createContext<IAccessControlContext | null>(null);

export function AccessControlProvider({ children }: IAccessControlProps) {
  const [permissionResults, setPermissionResults] = useState<{ [key: string]: boolean }>({});
  const [pendingChecks, setPendingChecks] = useState<Array<string>>([]);

  useEffect(() => {
    if (pendingChecks.length === 0) return;

    const checkAccess = async () => {
      const resp: ApiResponse<any> = await checkPermissions(pendingChecks);
      setPermissionResults((results) => ({ ...results, ...resp.data }));
      setPendingChecks([]);
    };
    const iid = window.setTimeout(() => checkAccess(), 10);
    return () => window.clearTimeout(iid);
  }, [pendingChecks]);

  const hasPermission = useCallback((permission: string) => {
    setPendingChecks((checks) => [...checks, permission]);
  }, []);

  return (
    <AccessControlContext.Provider value={{ permissions: permissionResults, hasPermission }}>
      {children}
    </AccessControlContext.Provider>
  );
}

interface AccessControlProps {
  permission: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export function AccessControl({ permission, children, fallback = null }: AccessControlProps) {
  const accessControlCtx = useContext(AccessControlContext);
  const hasPermission = accessControlCtx?.hasPermission;

  useEffect(() => {
    hasPermission && hasPermission(permission);
  }, [hasPermission, permission]);

  const isAllowed = accessControlCtx?.permissions ? accessControlCtx?.permissions[permission] : false;

  return <>{isAllowed ? children : fallback}</>;
}

interface ExperimentControlProps {
  experiment: string;
  children: React.ReactNode;
  fallback: React.ReactNode;
}
export function ExperimentControl({ experiment, children, fallback }: ExperimentControlProps) {
  const permission = `exp.${experiment}`;
  return (
    <AccessControl permission={permission} fallback={fallback}>
      {children}
    </AccessControl>
  );
}

interface RewardsAccessProps {
  reward: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

export function RewardsAccess({ reward, children, fallback }: RewardsAccessProps) {
  const permission = `reward.${reward}`;

  return (
    <AccessControl permission={permission} fallback={fallback}>
      {children}
    </AccessControl>
  );
}
