import { types, Instance } from 'mobx-state-tree';
import { RTModel } from './RTModel';

export const BrowserDebugState = RTModel.named('BrowserDebugState')
  .props({
    query: types.optional(types.string, ''),
    result: types.optional(types.string, ''),
    req: types.maybe(types.number),
    triggerTest: false,
    microphoneDevice: types.optional(types.string, ''),
    cameraDevice: types.optional(types.string, ''),
    capture: types.optional(types.string, ''),
    captureEnabled: false,
    browserMeta: types.frozen({}),
  })
  .actions((self) => ({
    setResult(res: string) {
      self.result = res;
    },
    setQuery(res: string) {
      self.query = res;
      self.result = '';
      self.req = Math.random();
    },
    setTriggerTest(val: boolean) {
      self.triggerTest = val;
    },
    setBrowserMeta(val: any) {
      self.browserMeta = val;
    },
    setMicrophoneDevice(val: string) {
      self.microphoneDevice = val;
    },
    setCameraDevice(val: string) {
      self.cameraDevice = val;
    },
  }))
  .actions((self) => ({
    setCapture(capture: string) {
      self.capture = capture;
    },
    requestCapture() {
      self.captureEnabled = true;
    },
    disposeCapture() {
      self.captureEnabled = false;
      self.capture = '';
    },
  }));

export interface IBrowserDebugState extends Instance<typeof BrowserDebugState> {}
