import mixpanel from 'mixpanel-browser';
import { IMasterclassSessionState } from 'store/MasterclassSessionState';
import { IMasterclassSession } from 'store/MasterclassStore';
import { ISession } from 'store/Session';
import { ISignUpStore } from 'store/SignUpStore';
import { IUser } from 'store/User';

const mxIncrementalSuperProperty = (property: string) => {
  const value = mixpanel.get_property(property);
  const update = {} as { [key: string]: number };
  if (value && typeof value == 'number') {
    update[property] = value + 1;
  } else {
    update[property] = 1;
  }
  mixpanel.register(update);
};

export const mxUserRegistrationFormSubmit = ({
  signUpStore,
  sessionCategory,
  registrationFormType,
  currentSession,
  referralCode,
}: {
  signUpStore: ISignUpStore;
  sessionCategory?: string;
  registrationFormType: string;
  currentSession?: ISession;
  referralCode?: string | null;
}) => {
  mixpanel.register({
    grade: signUpStore.grade,
  });

  mixpanel.track('Registration form submit', {
    $name: signUpStore.kidName,
    $email: signUpStore.parentEmail,
    $phone: signUpStore.phone,
    schoolName: signUpStore.kidSchool,
    registrationFormType: referralCode ? 'Referral' : registrationFormType,
    sessionCategory,
    sprintName: currentSession?.sprint.title,
    sprintDateTime: currentSession?.startAtDate,
    sessionId: currentSession?.name,
    hasLaptop: signUpStore.extraInfo.get('hasLaptop') || undefined,
    masterclassName: currentSession?.name,
    referralCode: referralCode,
    // registrationFormName: '',
    // sessionType: parent/student session,
    // sessionVisibility,
  });
};

export const mxTempUserFormSubmit = ({ signUpStore }: { signUpStore: ISignUpStore }) => {
  mixpanel.register({
    grade: signUpStore.grade,
  });

  mixpanel.track('Temp Registration form submit', {
    $name: signUpStore.kidName,
  });
};

export const mxUserLogin = () => {
  mixpanel.register({
    userVerified: true,
  });

  mixpanel.track('User Login', {
    userVerified: true,
  });
};

export const mxOTPSuccess = () => {
  mixpanel.register({
    userVerified: true,
  });

  mixpanel.track('OTP Success', {
    userVerified: true,
  });
};

export const mxOTPSkipped = (attempts: number) => {
  mixpanel.register({
    userVerified: false,
  });

  mixpanel.track('Skip OTP', {
    OTPAttempted: attempts,
  });
};

export const mxMasterclassFeedbackSubmit = (rating: number) => {
  mixpanel.track('Feedback submitted', {
    rating: rating,
  });
};

export const mxJoinMasterclassSession = (session_name: string, masterclassState: IMasterclassSessionState) => {
  mixpanel.register({
    session_name,
    sessionCategory: 'masterclass',
    courseName: masterclassState.sprint.course?.name,
    sprintName: masterclassState.sprint.name,
    sprintCoach: masterclassState.coach?.name,
  });

  mixpanel.track('Land on Session Page', {});
};

export const QAInteraction = (pks: string, text: string, interactionType: string) => {
  mixpanel.track('QA Interaction', {
    pks,
    text,
    interactionType,
  });
};

export const mxQAInteraction = (url: string) => {
  mixpanel.track('QA Link Interaction', {
    url,
  });
};

export const mxReferralLinkShared = (referralCode: string, medium: string) => {
  mixpanel.track('Referral Link Shared', {
    referralCode,
    medium,
  });
};

export const mxTrackPageVisit = (pageName: string) => {
  mixpanel.track('Page Visit', { 'Page Name': `App :: ${pageName}` });
};

export const mxMasterclassPageVisit = (
  masterclassSession: IMasterclassSession,
  landedOnMasterclassPage: boolean = false
) => {
  mixpanel.register({
    sessionInternalName: masterclassSession.name,
    preRegistrationEnabled: masterclassSession.school ? masterclassSession.school.can_pre_register : true,
    schoolMasterclass: masterclassSession.school !== null ? true : false,
    schoolInternalName: masterclassSession.school?.name,
  });

  mixpanel.track('Masterclass Page Visit', {
    masterclassPage: landedOnMasterclassPage,
  });
};

export const mxReportBrowserIssue = (reason: string) => {
  mixpanel.track('Unsupported Browser', { reason });
};

export const mxVideoDecodeFailedIssue = (message: string) => {
  mixpanel.track('Unable to View Video', { message });
};

export const mxVideoDecodeRecovered = (message: string) => {
  mixpanel.track('Able to View Video', { message });
};

export const mxMobileLandscapeMode = (isLandscape: boolean) => {
  mixpanel.track('Landscape Mode Toggle', { screenMode: isLandscape ? 'landscape' : 'portrait' });
};

export const mxTrackScreenShare = ({
  screenShare,
  currentUser,
  session_name,
  sprint,
}: {
  screenShare: boolean;
  currentUser: IUser;
  session_name: string;
  sprint: string;
}) => {
  mixpanel.track(`Host ${screenShare ? 'started' : 'stopped'} screenshare`, {
    sessionName: session_name,
    user: currentUser.name,
    sprint,
  });
};

export const mxTrackPastEpisode = ({
  episodeType,
  course,
  sprint,
}: {
  sprint: string;
  course: string;
  episodeType: 'Replayed' | 'Catchup';
}) => {
  mixpanel.track(`Episode ${episodeType}`, { sprint, course });
};

export const mxRegistrationForm = () => {
  mixpanel.track('Registration Form');
};

export const mxOTPForm = () => {
  mixpanel.track('OTP Form');
};

export const mxTempUserForm = () => {
  mixpanel.track('Temp User Form');
};

export const mxTrackErrors = (errors: { [key: string]: Array<string> }) => {
  mixpanel.track('Signup Errors', { errors });
};

export const mxJoiningTime = (starts_in: number, isDone: boolean) => {
  if (isDone) {
    mixpanel.track('User joined after session ended', { starts_in });
  } else if (starts_in > 15 * 60) {
    mixpanel.track('User joined over 15 mins before the session', { starts_in });
  } else if (starts_in > 0) {
    mixpanel.track('User joined upto 15 mins before the session', { starts_in });
  } else {
    mixpanel.track('User joined on time', { starts_in });
  }
};

export const mxShowGuestSignupForm = () => {
  mixpanel.track('Guest Signup Form');
};

export const mxEnteredWaitingRoom = () => {
  mixpanel.track('Entered Waiting Room');
};

export const mxFeedbackSubmitted = (rating: number) => {
  mixpanel.track('Feedback Submitted', { rating });
};

export const mxBannerView = (bannerName: string, bannerTitle: string) => {
  mixpanel.track('Banner Rendered', { bannerName, bannerTitle });
};

export const mxBannerClicked = (bannerName: string, bannerTitle: string) => {
  mixpanel.track('Banner Clicked', { bannerName, bannerTitle });
};

export const mxHandleOrientationAttendedEvent = (coachName: string, sessionLength: number, sessionId: string) => {
  mixpanel.track('Orientation Attended', {
    'Coach Name': coachName,
    'Session Length': sessionLength,
    'Session Id': sessionId,
  });
};

export const mxReferralPageVisited = (userInfo: {
  grade: string;
  subject: string;
  unit: string;
  gender: string;
  coachName: string;
}) => {
  mixpanel.register(userInfo);
  mixpanel.track('Referral Page Visited');
};

export const mxAddFriendClicked = (referredName: string, referredPhone: string, referredGrade: string) => {
  mixpanel.track('Add friend clicked', {
    referredName,
    referredPhone,
    referredGrade,
  });
};

export const mxReferralLinkGenerated = (
  referredName: string,
  referredPhone: string,
  referredGrade: string,
  referredUserVerified: boolean,
  referralCode: string
) => {
  mxIncrementalSuperProperty('referralCount');
  mixpanel.track('Referral Link Generated', {
    referredName,
    referredPhone,
    referredGrade,
    referredUserVerified,
    referralCode,
  });
};

export const mxInviteLinkShared = (channel: string, referralCode: string) => {
  mixpanel.track('Invite Link Shared', {
    channel,
    referralCode,
  });
};

export const mxInviteReminderSent = () => {
  mixpanel.track('Invite Reminder Sent', {
    referredPhone: '',
    referralCode: '',
  });
};
