import { types, getParent, isAlive } from 'mobx-state-tree';

class SocketWatcher {
  buffer: Array<any>;
  packetId: number;
  iid: number;
  ws: WebSocket | null;
  now: number;

  constructor() {
    this.buffer = [];
    this.packetId = 0;
    this.iid = 0;
    this.ws = null;
    this.now = +new Date();
  }

  startWatching(ws: WebSocket) {
    this.ws = ws;
    this.heartbeat();
  }

  stopWatching() {
    this.iid && window.clearTimeout(this.iid);
  }

  heartbeat() {
    this.iid && window.clearTimeout(this.iid);
    this.now = +new Date();
    this.iid = window.setTimeout(this.onIdle, 11.5 * 1000);
  }

  onIdle = () => {
    console.warn('WS Idle close, elasped', (+new Date() - this.now), 'ms');
    this.ws && this.ws.close();
  }

  store_and_stamp(pkt: any) {
    if (!pkt['msg_id']) pkt['msg_id'] = ++this.packetId;

    this.buffer.push(pkt);
    return pkt;
  }

  processAck(msg_id: number) {
    const bufferToKeep = this.packetId - msg_id;
    this.buffer = this.buffer.slice(-bufferToKeep);
  }
}
const Socket = types
  .model('Socket', {
    url: types.string,
    connectionState: types.optional(types.string, 'unknown'),
  })
  .actions((self) => ({
    setConnectionState(state: string) {
      if (!isAlive(self) || !isAlive(getParent(self))) return;
      self.connectionState = state;
    },
  }))
  .actions((self) => {
    let ws: WebSocket | null = null,
      _closing = false,
      _closeTrial = 0,
      _watcher = new SocketWatcher();

    var _destroying = false;

    function initSocket() {
      if (ws) return;

      ws = new WebSocket(self.url);

      ws.addEventListener('close', onClose);
      ws.addEventListener('open', onOpen);
      ws.addEventListener('message', onMessage);
      ws.addEventListener('error', onError);
    }

    function closeSocket() {
      if (!ws) {
        return;
      }
      ws.removeEventListener('close', onClose);
      ws.removeEventListener('open', onOpen);
      ws.removeEventListener('message', onMessage);
      ws.removeEventListener('error', onError);

      _closing = true;
      self.setConnectionState('closing');
      ws.close();
      ws = null;
      _watcher.stopWatching();
    }

    function onError() {
      const parent = getParent(self) as any;
      parent.onError();
    }

    function onOpen() {
      ws && _watcher.startWatching(ws);
      console.log('websocket open');
      self.setConnectionState('connected');
      const parent = getParent(self) as any;
      parent.onOpen();
    }

    function flushBuffer() {
      _watcher.buffer.forEach((pkt) => sendWithoutBuffer(pkt));
    }

    function onClose() {
      _watcher.stopWatching();

      if (!_closing) {
        if (self.connectionState !== 'reconnecting') self.setConnectionState('reconnecting');

        const retryAfter = Math.min(15000, 600 + _closeTrial * 300);
        _closeTrial++;

        ws = null;

        if (_closeTrial < 15) {
          console.log('websocket closed, retrying in', retryAfter);

          setTimeout(() => {
            initSocket();
          }, retryAfter);
        } else {
          console.error('socket retry exhausted!');
          closeSocket();
          self.setConnectionState('error');

          setTimeout(() => {
            initSocket();
          }, 3 * 60 * 1000);
        }
      } else if (!_destroying) {
        self.setConnectionState('closed');
      }
    }

    function onMessage(e: MessageEvent) {
      _watcher.heartbeat();
      const r = JSON.parse(e.data);
      const parent = getParent(self) as any;
      parent.onMessage(r);
      _watcher.processAck(r['last_msg_id']);
      _closeTrial = 0;
    }

    function sendWithoutBuffer(pkt: any) {
      if (ws && ws.readyState === 1) {
        ws.send(JSON.stringify(pkt));
        return true;
      }
      return false;
    }
    function send(pkt: any) {
      const packet = _watcher.store_and_stamp(pkt);
      return sendWithoutBuffer(packet);
    }

    function beforeDestroy() {
      _destroying = true;
      closeSocket();
    }

    function afterCreate() {
      initSocket();
    }

    return {
      initSocket,
      send,
      closeSocket,
      afterCreate,
      beforeDestroy,
      flushBuffer,
    };
  });

export default Socket;
