import { types, Instance } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

const ProfilePicStore = RTModel.named('ProfilePicStore')
  .props({
    showPicPopup: false,
    showProfilePopup: false,
    profilePic: types.optional(types.string, ''),
    progress: types.optional(types.string, ''),
    saveProfilePicInvoke: InvokerType,
    checkProfilePicInvoke: InvokerType,
  })
  .actions((self) => ({
    setShowPicPopup(val: boolean) {
      self.showPicPopup = val;
    },
    setShowProfilePopup(val: boolean) {
      self.showProfilePopup = val;
    },
    setProgress(val: string) {
      self.progress = val;
    },
    setProfilePic(val: string) {
      self.profilePic = val;
    },
    reset(show: boolean = false) {
      self.showPicPopup = show;
      self.progress = '';
      self.profilePic = '';
    },
    afterSaveCleanup() {
      self.showPicPopup = false;
      self.progress = '';
    },
    saveProfilePic() {
      return self.saveProfilePicInvoke.invoke({ profile_pic: self.profilePic });
    },
    checkProfilePic(publicURL: string) {
      return self.checkProfilePicInvoke.invoke({ url: publicURL });
    },
  }));

export default ProfilePicStore;
export interface IProfilePicStore extends Instance<typeof ProfilePicStore> {}
