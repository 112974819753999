import { getBatchArtworks } from 'api';
import { ApiResponse } from 'apisauce';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import { BatchLite } from './coachStores/BatchStore';
import { CoachLite } from './coachStores/CoachStore';

const StudentArt = types.model('StudentArt', {
  name: types.string,
  grade: types.string,
  art_url: types.string,
  presentation_video: types.maybeNull(types.string),
  project_link: types.maybeNull(types.string),
  project_image: types.maybeNull(types.string)
});

export default StudentArt;

export interface IStudentArt extends Instance<typeof StudentArt> {}

export const BatchGalleryStore = types
  .model('BatchGalleryStore', {
    batch: types.maybeNull(BatchLite),
    seniorCoach: types.maybeNull(CoachLite),
    artworks: types.maybeNull(types.array(StudentArt)),
    loading: false,
  })
  .actions((self) => ({
    fetchArtworks: flow(function* (projectName: string, batchName: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield getBatchArtworks(projectName, batchName);
        if (response.problem) {
          if (response.status === 404) {
            getEnv(self).commonStore.setNetworkProblem(response.data.error);
          } else {
            getEnv(self).commonStore.setNetworkProblem(response.problem);
          }
          return;
        }
        self.artworks = cast(response.data.artworks);
        self.batch = cast(response.data.batch);
        self.seniorCoach = cast(response.data.senior_coach);
      } finally {
        self.loading = false;
      }
    }),
  }));

export interface IBatchGalleryStore extends Instance<typeof BatchGalleryStore> {}
