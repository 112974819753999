import { submitInsightCallFeedbackForSkip, submitInsightCallFeedback, fetchInsightCallFeedbackFormDetails } from 'api';
import { ApiResponse } from 'apisauce';
import { flow, types, cast } from 'mobx-state-tree';

export const ChoiceStructure = types.model({
  value: types.string,
  label: types.string,
});

const FeedbackFormDetails = types.model({
  has_upcoming_payment: types.boolean,
  feedback_type: types.union(types.literal('insightVideoFeedback'), types.literal('insightCallFeedback')),
  active_insight_call_session_name: types.maybeNull(types.string),
  parent_update_statuses: types.array(ChoiceStructure),
  student_name: types.string,
});

const InsightCallFeedbackStore = types
  .model({
    submittingFeedback: false,
    errors: '',
    feedbackSubmitted: false,
    loading: false,
    feedbackFormDetails: types.maybeNull(FeedbackFormDetails),
  })
  .actions((self) => ({
    setErrors(errorText: string) {
      self.errors = errorText;
    },
  }))
  .actions((self) => ({
    submitInsightCallFeedback: flow(function* ({
      parentUpdateStatus,
      coachNotes,
      insightCallName,
      callLocation,
    }: {
      parentUpdateStatus: string;
      coachNotes: string;
      insightCallName: string;
      callLocation?: string;
    }) {
      if (!coachNotes || coachNotes.length < 30)
        return self.setErrors('Please enter atleast 30 characters in the notes section');
      try {
        self.submittingFeedback = true;
        const response: ApiResponse<any> = yield submitInsightCallFeedback({
          parent_update_status: parentUpdateStatus,
          coach_notes: coachNotes,
          insight_call: insightCallName,
          call_location: callLocation,
        });

        if (response.problem) {
          return self.setErrors('There was a problem submitting the feedback');
        }
        self.feedbackSubmitted = true;
      } finally {
        self.submittingFeedback = false;
      }
    }),
    submitInsightCallFeedbackForSkip: flow(function* (insightCallName: string) {
      self.submittingFeedback = true;
      try {
        const response: ApiResponse<any> = yield submitInsightCallFeedbackForSkip({ insight_call: insightCallName });
        if (response.problem) {
          return self.setErrors('There was a problem updating');
        }
        self.feedbackSubmitted = true;
      } finally {
        self.submittingFeedback = false;
      }
    }),
    setFeedbackSubmitted: (feedbackSubmitted: boolean) => {
      self.feedbackSubmitted = feedbackSubmitted;
    },
    fetchFeedbackFormDetails: flow(function* (insightCallName: string) {
      self.loading = true;
      try {
        const formDetailsResponse: ApiResponse<any> = yield fetchInsightCallFeedbackFormDetails(insightCallName);
        if (formDetailsResponse.problem) {
          self.errors = 'There was a problem fetching the details';
          return;
        }
        self.feedbackFormDetails = cast(formDetailsResponse.data);
      } finally {
        self.loading = false;
      }
    }),
  }))
  .views((self) => ({
    rescheduleLinkRelative(insightCallSessionName: string) {
      return `/insight-call-reschedule-request?name=${insightCallSessionName}`;
    },
  }));

export default InsightCallFeedbackStore;
export const InsightFeedbackTypes = {
  INSIGHT_VIDEO_FEEDBACK: 'insightVideoFeedback',
  INSIGHT_CALL_FEEDBACK: 'insightCallFeedback',
};
