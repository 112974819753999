import { types, Instance, cast } from 'mobx-state-tree';
import HintStore from './HintStore';
import { VideoStore } from './VideoStore';
import { IConversationalPoint, ConversationalPoint } from './ActivityDetail';

export const BaseState = types
  .model({
    progress: '',
  })
  .actions((self) => ({
    setProgress(progress: string) {
      self.progress = progress;
    },
  }));

export const QuizState = BaseState.named('QuizState')
  .props({
    activity_type: types.literal('quiz activity'),
    index: 0,
    answerSets: types.maybeNull(types.array(types.array(types.string))),
    showSolution: false,
    attempts: 0,
  })
  .actions((self) => ({
    setIndex(index: number) {
      self.index = index;
    },
    setAnswerAtIndex(index: number, answer: Array<string>) {
      if (!self.answerSets) {
        self.answerSets = cast([]);
      }
      // @ts-ignore
      self.answerSets.splice(index, 1, answer);
    },
    reset() {
      self.answerSets = null;
      self.showSolution = false;
      self.index = 0;
    },
    setShowSolution(val: boolean) {
      self.showSolution = val;
    },
    resetAttempts() {
      self.attempts = 0;
    },
    incrAttempts() {
      self.attempts += 1;
    },
  }))
  .views((self) => ({
    getAnswer(index: number) {
      if (self.answerSets && index >= 0 && index < self.answerSets.length) {
        return self.answerSets[index];
      }
      return [];
    },
  }));

export interface IQuizState extends Instance<typeof QuizState> {}

export const ReactQuestionState = BaseState.named('ReactQuestionState')
  .props({
    activity_type: types.literal('react question'),
    answer: '',
  })
  .actions((self) => ({
    setAnswer(answer: string) {
      self.answer = answer;
    },
  }));

export interface IReactQuestionState extends Instance<typeof ReactQuestionState> {}

export const AnnotateState = BaseState.named('AnnotateState')
  .props({
    activity_type: types.literal('coding annotate activity'),
    clear: 0,
    req: 0,
    hasDrawn: false,
  })
  .actions((self) => ({
    clearDrawing() {
      self.hasDrawn = false;
      self.clear = Math.random();
    },
    reqDrawing() {
      self.req = Math.random();
    },
    setHasDrawn() {
      self.hasDrawn = true;
    },
  }));

export interface IAnnotateState extends Instance<typeof AnnotateState> {}

export const VideoState = BaseState.named('VideoState')
  .props({
    activity_type: types.literal('video'),
    videoStore: types.maybe(VideoStore),
  })
  .actions((self) => ({
    init() {
      if (!self.videoStore) self.videoStore = cast({});
    },
  }));

export interface IVideoState extends Instance<typeof VideoState> {}

export const CodingState = BaseState.named('ICodingState')
  .props({
    activity_type: types.literal('coding activity'),
    hintStore: types.maybe(HintStore),
  })
  .actions((self) => ({
    initHintStore() {
      if (self.hintStore) {
        return;
      }
      self.hintStore = cast({ videos: [] });
    },
  }));

export interface ICodingState extends Instance<typeof CodingState> {}

export const GenericState = BaseState.named('GenericState').props({
  activity_type: types.string,
});

export interface IGenericState extends Instance<typeof GenericState> {}

export const RecordedQuestions = types.model({
  question: types.identifierNumber,
  answer: types.string,
});

export const RecordingActivityState = BaseState.named('RecordingActivityState')
  .props({
    activity_type: types.literal('recording activity'),
    currentQuestion: 0,
    recordedQuestions: types.maybe(types.map(RecordedQuestions)),
  })
  .actions((self) => ({
    init() {
      if (self.recordedQuestions === undefined) {
        self.recordedQuestions = cast({});
      }
    },
    setCurrentQuestion(sequenceNumber: number) {
      self.currentQuestion = sequenceNumber;
    },
    setAnswer(question: number, answer: string) {
      if (!self.recordedQuestions) {
        throw new Error('RecordingActivityState not inited');
      }
      self.recordedQuestions.put({ question, answer });
    },
    resetState() {
      self.currentQuestion = 0;
      self.recordedQuestions = cast({});
    },
  }));

export interface IRecordingActivityState extends Instance<typeof RecordingActivityState> {}

export const PollState = BaseState.named('PollState')
  .props({
    activity_type: types.literal('poll activity'),
    answerSet: types.maybeNull(types.array(types.string)),
    showSolution: false,
    attempts: 0,
  })
  .actions((self) => ({
    setAnswer(answer: Array<string>) {
      if (!self.answerSet) {
        self.answerSet = cast([]);
      }
      self.answerSet = cast(answer);
    },
    reset() {
      self.showSolution = false;
      self.attempts = 0;
    },
    incrAttempts() {
      self.attempts += 1;
    },
    setShowSolution(val: boolean) {
      self.showSolution = val;
    },
    setAnswerAtIndex(index: number, answer: Array<string>) {
      // unused index - standardise poll and quiz states
      if (!self.answerSet) {
        self.answerSet = cast([]);
      }
      self.answerSet = cast(answer);
    },
  }))
  .views((self) => ({
    getAnswer(index: number) {
      // unused index - standardise poll and quiz states
      return self.answerSet || [];
    },
  }));

export interface IPollState extends Instance<typeof PollState> {}

export const P5JSState = BaseState.named('P5JSState')
  .props({
    activity_type: types.literal('p5js activity'),
    instructionsCollapsed: false,
  })
  .actions((self) => ({
    setInstructionsCollapsed(val: boolean) {
      self.instructionsCollapsed = val;
    },
  }));

export interface IP5JSState extends Instance<typeof P5JSState> {}

export const VoiceChatActivityState = BaseState.named('VoiceChatActivityState')
  .props({
    activity_type: types.literal('Voice Chat activity'),
    activity_context: types.frozen({ ai_name: '', activity_question: '' }),
    isCompleted: false,
    isSubmitted: false,
    chatRoomName: types.maybe(types.string),
  })
  .actions((self) => ({
    setIsCompleted() {
      self.isCompleted = true;
    },
    setChatRoomName(roomName: string) {
      self.chatRoomName = roomName;
    },
    setIsSubmitted(state: boolean) {
      self.isSubmitted = state;
    },
    reset() {
      self.isCompleted = false;
    },
  }));

export interface IVoiceChatActivityState extends Instance<typeof VoiceChatActivityState> {}

export type IConversationState =
  | null
  | 'conversationStarted'
  | 'AiSpeaking'
  | 'WaitingOnUser'
  | 'UserResponding'
  | 'AiProcessing'
  | 'AiProcessingDone'
  | 'ConversationCompleted'
  | 'ConversationFailed';

export const ConversationRecordingActivityState = BaseState.named('ConversationRecordingActivityState')
  .props({
    activeConversationPoint: types.maybe(ConversationalPoint),
    lastPlayedMessage: types.maybeNull(types.string),
    conversationState: types.maybeNull(
      types.union(
        types.literal('conversationStarted'),
        types.literal('AiSpeaking'),
        types.literal('WaitingOnUser'),
        types.literal('UserResponding'),
        types.literal('AiProcessing'),
        types.literal('AiProcessingDone'),
        types.literal('ConversationCompleted'),
        types.literal('ConversationFailed')
      )
    ),
  })
  .actions((self) => ({
    resetActiveConvo() {
      self.activeConversationPoint = undefined;
      self.conversationState = null;
    },
    setLastPlayedMessage(message: string) {
      self.lastPlayedMessage = message;
    },
    setConversationPoint(point: IConversationalPoint) {
      self.activeConversationPoint = point;
    },
    setConversationState(state: IConversationState) {
      self.conversationState = state;
    },
  }));

export interface IConversationRecordingActivityState extends Instance<typeof ConversationRecordingActivityState> {}

export const ActivityState = types.union(
  QuizState,
  QuizState,
  QuizState,
  P5JSState,
  PollState,
  CodingState,
  AnnotateState,
  ReactQuestionState,
  VideoState,
  RecordingActivityState,
  VoiceChatActivityState,
  ConversationRecordingActivityState,
  GenericState // keep generic activity last
); // IDK why this blows up if ther is < 10 types.

export interface IActivityState extends Instance<typeof ActivityState> {
  activity_type: string;
}
