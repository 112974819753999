import { types, Instance } from 'mobx-state-tree';

const Screen = types
  .model('Screen', {
    reqIFrame: types.maybe(types.number),
    resolution: types.frozen({ width: 0, height: 0 }),
    reqDrawing: types.maybe(types.number),
    reqClearDrawing: types.maybe(types.number),
  })
  .actions((self) => ({
    setResolution(width: number, height: number) {
      self.resolution = { width, height };
    },
    requestIFrame() {
      self.reqIFrame = Math.random();
    },
    requestDrawing() {
      self.reqDrawing = Math.random();
    },
    clearDrawing() {
      self.reqClearDrawing = Math.random();
    }
  }))

export default Screen;
export interface IScreen extends Instance<typeof Screen> {}
