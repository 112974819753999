import { Instance, types } from "mobx-state-tree";

export const PageStore = types
  .model('PageStore', {
    number: types.number,
    size: types.number,
    count: types.number,
  })
  .actions((self) => ({}));

export interface IPageStore extends Instance<typeof PageStore> {}
