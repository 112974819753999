import { types, Instance, flow } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import User from './User';
import { generateBadgeStudentReport } from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';

const Badge = types.model('Badge', {
  name: types.string,
  image: types.string,
  title: types.string,
  description: types.string,
  count: 0,
});

export default Badge;

export interface IBadge extends Instance<typeof Badge> {}

export const FeedbackBadge = types
  .model({
    badge: Badge,
    pks: types.identifier,
    assigned_to: User,
    assigned_by: User,
    loading: false,
  })
  .actions((self) => ({
    generateStudentReport: flow(function* () {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield generateBadgeStudentReport(self.pks);
        if (response.problem) {
          return false;
        }
        window.open(response.data.url, '_blank');
        return response.data.url;
      } finally {
        self.loading = false;
      }
    }),
  }));

export interface IFeedbackBadge extends Instance<typeof FeedbackBadge> {}

export const BadgesStore = types
  .model('BadgesStore', {
    badges: types.array(Badge),
    assignBadge: InvokerType,
    assignedBadgeMap: types.map(types.array(Badge)),
    newBadge: types.map(types.string),
    loading: false,
  })
  .actions((self) => ({
    assignBadgeToUser(badgeName: string, username: string) {
      return self.assignBadge.invoke({ badgeName, username });
    },
  }));

export interface IBadgesStore extends Instance<typeof BadgesStore> {}
