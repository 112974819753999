import { RTModel } from './RTModel';
import { Instance, types } from 'mobx-state-tree';
import { MasterclassMessage } from './MasterclassMessages';
import InvokerType from './InvokerType';

const MasterclassMainChatState = RTModel.named('MasterclassMainChatState')
  .props({
    messageMap: types.map(MasterclassMessage),
    highlightedChatMessage: types.maybeNull(MasterclassMessage),
    setHighlightedMessageInvoke: InvokerType,
    addBroadcastMessageInvoke: InvokerType,
    addPinnedMessageInvoke: InvokerType,
    broadcastMessages: types.array(types.string),
    pinnedMessages: types.array(types.string),
    pinnedMessageToggleInvoke: InvokerType
  })
  .actions((self) => ({
    setHighlightedMessage(message_pks: string) {
      return self.setHighlightedMessageInvoke.invoke({ message_pks });
    },
    addPinnedChatMesage(message_text: string) {
      return self.addPinnedMessageInvoke.invoke({ message_text });
    },
    addBroadcastChatMessage(message_text: string) {
      return self.addBroadcastMessageInvoke.invoke({ message_text });
    },
    pinnedMessageToggle(messagePks: string, isPinned: boolean) {
      return self.pinnedMessageToggleInvoke.invoke({ message_pks: messagePks, is_pinned: isPinned });
    }
  }));

export default MasterclassMainChatState;
export interface IMasterclassMainChatState extends Instance<typeof MasterclassMainChatState> {}

