import { types, flow, cast, getEnv, Instance } from 'mobx-state-tree';
import {
  listSessions,
  getSessionDetail,
  listActiveSessions as listActiveSessionsAPI,
  fetchUnitBackground,
  listUnfinishedSessions,
  getMySessionDetail,
} from '../api';
import { Session, ISession } from './Session';
import { ApiResponse } from 'apisauce';
import {
  createOneOneSession,
  createSession,
  createTournamentSession,
  createTestSession,
  deleteTutorSession,
  getNextSessionDetail,
  IDeleteSession,
} from 'api/coachDashboard';
import { PageStore } from './Page';
import { BatchLite } from './coachStores/BatchStore';
import Sprint from './coachStores/Sprint';
import { toJS } from 'mobx';

const NextSessionDateDetails = types.model('NextSessionDateDetails', {
  date: types.string,
  holiday: types.boolean,
  scheduled_session: types.maybeNull(types.frozen({ sprint_name: '', time: '' })),
});

const NextSessionDetail = types
  .model({
    batch: types.maybeNull(BatchLite),
    sprint: types.maybeNull(Sprint),
    dates: types.array(types.maybeNull(NextSessionDateDetails)),
    start_at: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setStartAt(start_at: string) {
      self.start_at = start_at;
    },
  }));

const TutorSessionStore = types
  .model({
    currentSession: types.maybe(types.safeReference(Session)),
    upcomingSessions: types.array(types.safeReference(Session)),
    completedSessions: types.array(types.safeReference(Session)),
    unfinishedSessions: types.array(types.safeReference(Session)),
    fetchingSession: false,
    sessions: types.array(Session),
    errors: '',
    createErrors: '',
    creating: false,
    upcomingSessionsPage: types.maybe(PageStore),
    unitBackgrounds: types.maybeNull(types.array(types.frozen({ position: '', image: '' }))),
    nextSessionDetail: types.maybeNull(NextSessionDetail),
    completedSessionsPage: types.maybe(PageStore),
    fetchingCompletedSessions: false,
    showAddPeakMomentPopup: false,
    reloadUpcomingSessions: false,
  })
  .views((self) => ({
    get backgroundImages() {
      if (self.unitBackgrounds) {
        return self.unitBackgrounds
          .map(({ position, image }) => {
            return `url(${image}) ${position} bottom/contain no-repeat`;
          })
          .join(',');
      }
      return '';
    },
  }))
  .actions((self) => {
    function setSessions(sessions: any) {
      self.sessions = cast(sessions);
    }
    function setShowAddPeakMomentPopup(show: boolean) {
      self.showAddPeakMomentPopup = show;
    }

    function clear() {
      self.sessions = cast([]);
      self.currentSession = undefined;
    }

    function clearCreateErrors() {
      self.createErrors = '';
    }

    function appendSessions(sessions: any[]) {
      const oldSessions = toJS(self.sessions);
      const filteredOldSessions = oldSessions.filter((session) => {
        return !sessions.find((value) => value.name === session.name);
      });
      self.sessions = cast([...filteredOldSessions, ...sessions]);
    }

    const listUpcomingSessions = flow(function* ({
      demo = undefined,
      page = 1,
      pageSize,
      test = undefined,
    }: {
      demo?: boolean;
      page?: number;
      pageSize?: number;
      test?: boolean;
    }) {
      self.fetchingSession = true;
      const response = yield listSessions({ upcoming: true, demo, page, pageSize, test });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      setSessions(response.data.results);
      self.upcomingSessions = response.data.results.map((x: { name: string }) => x.name);
      self.upcomingSessionsPage = response.data.page;
      self.fetchingSession = false;
    });

    const fetchUnfinishedSessions = flow(function* ({
      demo = undefined,
      test = undefined,
    }: {
      demo?: boolean;
      page?: number;
      pageSize?: number;
      test?: boolean;
    }) {
      const response = yield listUnfinishedSessions({ demo, test });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      appendSessions(response.data.unfinished);
      self.unfinishedSessions = response.data.unfinished.map((x: { name: string }) => x.name);
    });

    const listCompletedSessions = flow(function* ({
      demo = undefined,
      page = 1,
      pageSize = 20,
      test = undefined,
    }: {
      demo?: boolean;
      page?: number;
      pageSize?: number;
      test?: boolean;
    }) {
      self.fetchingCompletedSessions = true;
      const response = yield listSessions({ upcoming: false, demo, page, pageSize, test, completed: true });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      appendSessions(response.data.results);
      self.completedSessions = response.data.results.map((x: { name: string }) => x.name);
      self.completedSessionsPage = response.data.page;
      self.fetchingCompletedSessions = false;
    });

    const listActiveSessions = flow(function* () {
      self.fetchingSession = true;
      try {
        const response: ApiResponse<any> = yield listActiveSessionsAPI();
        if (response.problem) {
          if (response.status === 403) {
            self.errors = 'Unauthorised';
          }
          if (response.status === 500) {
            self.errors = 'Oops!';
          }
          return;
        }
        setSessions(response.data);
      } finally {
        self.fetchingSession = false;
      }
    });

    const fetchSession = flow(function* (session_name: string) {
      self.fetchingSession = true;
      const response = yield getSessionDetail({ session_name });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      setSessions([response.data]);
      setCurrentSession(response.data);
      self.fetchingSession = false;
    });

    const fetchMySession = flow(function* (session_name: string) {
      self.fetchingSession = true;
      const response = yield getMySessionDetail({ session_name });
      if (response.problem) {
        getEnv(self).commonStore.setNetworkProblem(response.problem);
        return;
      }
      setSessions([response.data]);
      setCurrentSession(response.data);
      self.fetchingSession = false;
    });

    function setCurrentSession(session: ISession) {
      self.currentSession = session.name as any;
    }

    return {
      listUpcomingSessions,
      fetchUnfinishedSessions,
      setCurrentSession,
      fetchSession,
      fetchMySession,
      listActiveSessions,
      clear,
      listCompletedSessions,
      setShowAddPeakMomentPopup,
      clearCreateErrors,
    };
  })
  .actions((self) => ({
    triggerReloadUpcomingSessions: () => {
      self.reloadUpcomingSessions = !self.reloadUpcomingSessions;
    },
    createTestSession: flow(function* (sprintName: string) {
      try {
        self.creating = true;
        const response: ApiResponse<any> = yield createTestSession({ sprintName });
        self.sessions = cast([response.data]);
        self.currentSession = cast(response.data.name);
      } finally {
        self.creating = false;
      }
    }),
    createSessionPostSession: flow(function* ({
      sprintName,
      batchName,
      dateTime,
      studentUsernames,
      isCatchupSession = false,
      subject,
    }: {
      sprintName: string;
      batchName?: string;
      dateTime: string;
      studentUsernames?: string[];
      isCatchupSession?: boolean;
      subject?: string;
    }) {
      try {
        self.creating = true;
        const response: ApiResponse<any> = yield createSession({
          sprintName,
          batchName,
          dateTime,
          studentUsernames: studentUsernames || [],
          isCatchupSession,
          subject,
          additionalNotes: self.currentSession?.name ? `post session ${self.currentSession.name}` : undefined,
        });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return false;
        }
        return response.data;
      } finally {
        self.creating = false;
      }
    }),
    createTournamentSession: flow(function* ({
      sprintName,
      batchNames,
      dateTime,
    }: {
      sprintName: string;
      dateTime: string;
      batchNames: string[];
    }) {
      try {
        self.creating = true;
        self.createErrors = '';
        const response: ApiResponse<any> = yield createTournamentSession({
          sprintName,
          batchNames: batchNames || [],
          dateTime,
        });
        if (response.problem) {
          if (response.status === 400) {
            self.createErrors = cast(response.data.error);
            return false;
          }
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return false;
        }

        return true;
      } finally {
        self.creating = false;
      }
    }),
    createSession: flow(function* ({
      sprintName,
      batchName,
      dateTime,
      studentUsernames,
    }: {
      sprintName: string;
      batchName?: string;
      dateTime: string;
      studentUsernames?: string[];
    }) {
      try {
        self.creating = true;
        self.createErrors = '';
        const response: ApiResponse<any> = yield createSession({
          sprintName,
          batchName: batchName || '',
          dateTime,
          studentUsernames: studentUsernames || [],
        });
        if (response.problem) {
          if (response.status === 400) {
            self.createErrors = cast(response.data.error);
            return false;
          }
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return false;
        }

        self.sessions = cast([response.data]);
        self.currentSession = cast(response.data.name);
        return true;
      } finally {
        self.creating = false;
      }
    }),
    createOneOneSession: flow(function* (sprintName: string, studentUsername: string, dateTime: string) {
      try {
        self.creating = true;
        const response: ApiResponse<any> = yield createOneOneSession({ sprintName, studentUsername, dateTime });
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.sessions = cast([response.data]);
        self.currentSession = cast(response.data.name);
      } finally {
        self.creating = false;
      }
    }),
    fetchUnitBackground: flow(function* (sprintName: string) {
      const response: ApiResponse<any> = yield fetchUnitBackground({ sprintName });
      if (response.problem) {
        self.unitBackgrounds = null;
        return;
      }
      self.unitBackgrounds = response.data;
    }),
    getNextSessionDetails: flow(function* (sessionName: string) {
      self.nextSessionDetail = null;
      const response: ApiResponse<any> = yield getNextSessionDetail({ sessionName });
      if (response.problem) {
        return;
      }
      self.nextSessionDetail = cast(response.data);
    }),
    deleteSession: flow(function* (data: IDeleteSession) {
      try {
        const response: ApiResponse<any> = yield deleteTutorSession(data);
        if (response.status === 403) {
          getEnv(self).commonStore.setNetworkProblem(response.data.error);
          return;
        }
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
      } finally {
      }
    }),
    clearCurrentSession() {
      self.currentSession = undefined;
    },
    clearNextSessionDetails() {
      self.nextSessionDetail = null;
    },
  }));

export default TutorSessionStore;
export interface ITutorSessionStore extends Instance<typeof TutorSessionStore> {}
