import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

const TutorSessionGroupManagerState = RTModel.named('TutorSessionGroupManagerState').props({
  group_list: types.array(types.string),
  addGroup: InvokerType,
});

export default TutorSessionGroupManagerState;
export interface ITutorSessionGroupManagerState extends Instance<typeof TutorSessionGroupManagerState> {}
