import { IScholarshipSubmitDetails } from 'masterclass/components/ApplyScholarshipForm';
import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';

const MasterclassStudentState = RTModel.named('MasterclassStudentState')
  .props({
    roll_number: 0,
    call_room_name: '',
    joinSession: InvokerType,
    submitFeedbackInvoke: InvokerType,
    markStudentWarmInvoke: InvokerType,
    feedbackSubmitted: false,
    userRole: types.union(
      types.literal('guest'),
      types.literal('student'),
      types.literal('coach'),
      types.literal('unverified')
    ),
    isMicLocked: true,
    callSync: InvokerType,
    actions: types.array(types.string),
    isPinned: false,
    isWarm: false,
    facingIssues: false,
    actionInvoke: InvokerType,
    markFacingIssuesInvoke: InvokerType,
    refreshState: InvokerType,
    scholarshipLink: types.maybeNull(types.string),
    shortLink: types.optional(types.string, ''),
    hasWonScholarship: false,
    sendScholarshipMessageInvoke: InvokerType,
    studentPhone: types.maybeNull(types.string),
  })
  .actions((self) => ({
    join() {
      return self.joinSession.invoke();
    },
    submitFeedback(rating: number, scholarshipData: IScholarshipSubmitDetails) {
      return self.submitFeedbackInvoke.invoke({ rating, extra_info: scholarshipData });
    },
    setPinned(val: boolean) {
      self.isPinned = val;
    },
    markStudentWarm() {
      return self.markStudentWarmInvoke.invoke();
    },
    setStudentAction(action: string) {
      return self.actionInvoke.invokeAndForget({ action });
    },
    removeStudentAction(action: string) {
      self.actions.remove(action);
    },
    markFacingIssues(val: boolean) {
      return self.markFacingIssuesInvoke.invoke({ has_issues: val });
    },
    refresh() {
      return self.refreshState.invokeAndForget();
    },
    setScholarshipWon(showBanner: boolean) {
      self.hasWonScholarship = showBanner;
    },
    sendScholarshipMessage(phone: string) {
      return self.sendScholarshipMessageInvoke.invoke({ phone });
    },
  }));

export default MasterclassStudentState;
export interface IMasterclassStudentState extends Instance<typeof MasterclassStudentState> {}
