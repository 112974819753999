import { updateStudentDetails, validateStudentProfile, setUserTimezone } from 'api';
import { ApiResponse } from 'apisauce';
import { IStudentContact } from 'components/StudentProfilePopup/CommunicationForm';
import { types, Instance, flow } from 'mobx-state-tree';
import Config from 'config';

const UserAvatar = types.model('UserAvatar', {
  name: types.string,
  image_url: types.string,
  primary_color: types.string,
  secondary_color: types.string,
});

const UserImage = types.model('UserImage', {
  name: types.string,
  image_url: types.string,
});

export const User = types
  .model('User', {
    username: types.identifier,
    first_name: types.string,
    name: types.string,
    avatar: UserAvatar,
    image: types.maybeNull(UserImage),
    email: types.maybeNull(types.string),
    gender: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => ({
    get dp() {
      return self.image?.image_url || self.avatar.image_url;
    },
    get hasDp() {
      return self.image !== null;
    },
    get isBot() {
      return self?.username === Config.BOT_USERNAME;
    },
  }));

export default User;

export interface IUser extends Instance<typeof User> {}
export interface IUserAvatar extends Instance<typeof UserAvatar> {}
export interface UserMap {
  [key: string]: IUser | undefined;
}

const UserLiteTimezone = types
  .model({
    username: types.string,
    timezone: types.string,
    changing: false,
    errors: '',
    changed: false,
  })
  .actions((self) => ({
    changeTimezone: flow(function* (timezone: string) {
      self.changing = true;
      try {
        const response: ApiResponse<any> = yield setUserTimezone({ username: self.username, timezone });
        if (response.problem) {
          self.errors = `There was an error ${response.problem}`;
          return;
        }
        self.timezone = timezone;
      } finally {
        self.changing = false;
      }
    }),
  }));

const Student = User.named('Student')
  .props({
    grade: types.maybe(types.string),
    gender: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    pincode: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    parent_phone: types.maybeNull(types.string),
    parent_name: types.maybeNull(types.string),
    parent_email: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    tshirt_size: types.maybeNull(types.string),
    subjects: types.maybeNull(types.array(types.string)),
    validatingProfile: false,
  })
  .views((self) => ({
    get genderDetails(): { pronoun: string; possessive: string; addressing: string } {
      switch (self.gender) {
        case 'male':
          return { pronoun: 'He', possessive: 'His', addressing: 'Him' };
        case 'female':
          return { pronoun: 'She', possessive: 'Her', addressing: 'Her' };
        default:
          return { pronoun: 'He/She', possessive: 'Their', addressing: 'Them' };
      }
    },
  }))
  .actions((self) => ({
    updateStudentDetails: flow(function* (params: {
      gender?: string;
      name?: string;
      grade?: string;
      address?: string;
      city?: string;
      pincode?: string;
      contacts?: IStudentContact[];
    }) {
      const result: ApiResponse<any> = yield updateStudentDetails(self.username, params);
      if (result.problem) return result;
      if (params.gender) self.gender = params.gender;
      if (params.grade) self.grade = params.grade;
      if (params.name) self.name = params.name;
      return result;
    }),
    validateStudentProfile: flow(function* ({
      fields,
      errorCB,
    }: {
      fields: { [key: string]: string };
      errorCB: (errorMsg: string) => void;
    }) {
      self.validatingProfile = true;
      try {
        const result: ApiResponse<any> = yield validateStudentProfile(self.username, fields);

        if (result.problem) {
          const fieldKey = Object.keys(fields)[0];
          if (result.status === 400 && result.data && typeof result.data === 'object') {
            errorCB(result.data[fieldKey]);
            return;
          }
          errorCB('There was an error validating the data');
          return;
        }
        return true;
      } finally {
        self.validatingProfile = false;
      }
    }),
  }));
const StudentExtra = Student.named('StudentExtraModel').props({
  timezone: types.maybeNull(types.string),
});

const StudentLite = types.model('StudentLite', {
  username: types.identifier,
  name: types.string,
});

export { Student, StudentLite, StudentExtra, UserLiteTimezone };
export interface IStudent extends Instance<typeof Student> {}
export interface IStudentLite extends Instance<typeof StudentLite> {}
export interface StudentMap {
  [key: string]: IStudent;
}
export interface IUserLiteTimezone extends Instance<typeof UserLiteTimezone> {}

export const DEFAULT_USER = {
  username: '',
  name: 'Unknown',
  first_name: 'Unknown',
  avatar: {
    name: '',
    primary_color: '#000',
    secondary_color: '#000',
    image_url: '',
  },
  image: null,
  gender: null,
  dp: '',
  hasDp: false,
  email: '',
  isBot: false,
};
