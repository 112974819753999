import { fetchDailyReport, fetchDailyReportPreview } from 'api';
import { ApiResponse } from 'apisauce';
import Config from 'config';
import { format, parse } from 'fecha';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import User, { Student } from './User';

const ReportSprint = types.model({
  name: types.string,
  title: types.string,
  description: types.string,
  image_url: types.maybeNull(types.string),
  unit_index: '',
  sequence: types.integer,
  total_unit_sprints: types.integer,
});

export interface IReportSprint extends Instance<typeof ReportSprint> {}

const StudentBadge = types.model({
  name: types.string,
  title: types.string,
  image: types.string,
  description: types.string,
  student_report_placeholder: types.string,
});

export interface IStudentBadge extends Instance<typeof StudentBadge> {}

const DailyReport = types
  .model('DailyReport', {
    name: types.string,
    student: Student,
    attendance_status: types.union(
      types.literal('not_attended'),
      types.literal('redid_session'),
      types.literal('attended')
    ),
    coach: types.maybeNull(User),
    sprint: ReportSprint,
    param_values: types.maybe(types.frozen()),
    student_badge: types.maybeNull(StudentBadge),
    subject: types.string,
    session_time: types.string,
  })
  .views((self) => ({
    sessiontime: () => {
      if (!self.session_time) return '';
      const parsedDate = parse(self.session_time, Config.DATETIME_FORMAT);
      if (!parsedDate) return '';
      return format(parsedDate, 'Do MMMM, YYYY') || '';
    },
    getFormatedText: (placeholderText: string) => {
      const placeholders = {
        student_fname: self.student.first_name || 'student',
        gender_prounoun: self.student.genderDetails.pronoun.toLowerCase(),
        gender_prounoun_cap: self.student.genderDetails.pronoun,
        student_his_her: self.student.genderDetails.possessive.toLowerCase(),
        student_his_her_cap: self.student.genderDetails.possessive,
        gender_addressing: self.student.genderDetails.addressing.toLowerCase(),
        gender_addressing_cap: self.student.genderDetails.addressing,
      } as { [key: string]: string };

      return placeholderText.replace(/{{\w+}}/g, (variable: string) => {
        const theVariable = variable.slice(2, variable.length - 2);
        return placeholders[theVariable] || '';
      });
    },
  }));
export interface IDailyReport extends Instance<typeof DailyReport> {}

const DailyReportStore = types
  .model('DailyReportStore', {
    dailyReport: types.maybeNull(DailyReport),
    loading: false,
  })
  .actions((self) => ({
    fetchReport: flow(function* (reportName: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchDailyReport(reportName);
        if (response.problem) return;
        self.dailyReport = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
    fetchPreviewReport: flow(function* (sprint_name: string) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchDailyReportPreview(sprint_name);
        if (response.problem) {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
          return;
        }
        self.dailyReport = cast(response.data);
      } finally {
        self.loading = false;
      }
    }),
  }));

export default DailyReportStore;
export interface IDailyReportStore extends Instance<typeof DailyReportStore> {}
