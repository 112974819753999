import { getBadges } from 'api';
import { ApiResponse } from 'apisauce';
import { cast, flow, getEnv, Instance, types } from 'mobx-state-tree';
import Badge from './BadgesStore';

export const AchievementStore = types
  .model('AchievementStore', {
    badges: types.array(Badge),
    userDetail: types.frozen({ username: '', name: '' }),
    errormsg: types.maybeNull(types.string),
  })
  .actions((self) => ({
    getBadges: flow(function* (username?: string) {
      const response: ApiResponse<any> = yield getBadges(username);
      if (response.problem) {
        if (response.status === 404) {
          self.errormsg = 'Student not Found, Please check the URL';
        } else {
          getEnv(self).commonStore.setNetworkProblem(response.problem);
        }
        return;
      }

      self.badges = cast(response.data.badges);
      self.userDetail = cast(response.data.user);
    }),
  }))
  .views((self) => ({
    get totalBadgesEarnedCount() {
      let count = 0;
      self.badges.forEach((badge) => {
        count += badge.count;
      });
      return count;
    },
  }));

export interface IAchievementStore extends Instance<typeof AchievementStore> {}
