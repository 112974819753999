import React, { ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { error: Error | null }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
    this.setState({ error });

    const clarityFunc = (window as any).clarity;
    if (typeof clarityFunc === 'function') {
      clarityFunc('upgrade', 'exception');
    }

    if (error.name !== 'ChunkLoadError') {
      Sentry.withScope((scope) => {
        scope.setExtra('errorInfo', errorInfo);
        Sentry.captureException(error);
      });
    }

    if (window.localStorage.getItem('autoRefreshOnErrors') === 'true') {
      window.setTimeout(() => {
        this.reload();
      }, 3000);
    }
  }

  reload() {
    // @ts-ignore
    window.location.reload(true);
  }

  render() {
    const { error } = this.state;

    if (error) {
      if (error.name === 'ChunkLoadError') {
        return (
          <div className="snap">
            <h1>Application Updated</h1>
            <p>This application has been updated, please refresh your browser to see the latest content.</p>
            <button onClick={this.reload}>Click here</button> to refresh the page
          </div>
        );
      }
      return (
        <div className="snap" onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}>
          <h1>Oops</h1>
          <p>We're sorry — something's gone wrong.</p>
          <button onClick={this.reload}>Click here</button> to try refresh the page
          {Sentry.lastEventId() && <p>Our team has been notified, but click to fill out a report.</p>}
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
