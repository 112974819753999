import { RTModel } from './RTModel';
import User, { IUser, Student, UserMap } from './User';
import InvokerType from './InvokerType';
import { Instance, types } from 'mobx-state-tree';


const CallSessionState = RTModel.named('CallSessionState')
  .props({
    coach: User,
    parent: User,
    student: Student,
    sessionClosed: false,
    endSessionInvoke: InvokerType,
    parentSessionLink: '',
    rescheduleLink: '',
    parentAttendedSession: false,
    menuState: types.frozen({ drawerOpen: false, scriptIdx: 0 }),
    startsIn: types.number,
    startsAt: types.string,
    callName: types.string,
    _startedAtTimestamp: types.maybe(types.integer),
  }).views((self) => ({
    get userMap(): UserMap {
      return [self.coach, self.parent].reduce((a: UserMap, user: IUser) => {
        a[user.username] = user;
        return a;
      }, {});
    },
    get mainUsers() {
      return [self.coach, self.parent];
    },
    get drawerOpen() {
      return self.menuState.drawerOpen;
    },
    get scriptIdx() {
      return self.menuState.scriptIdx;
    },
    get timeElapsedInMinutes() {
      if (!self._startedAtTimestamp) return 0;
      return Math.floor(((new Date()).getTime() - self._startedAtTimestamp)/60000);
    }
  }))
  .actions((self) => ({
    endSession() {
      return self.endSessionInvoke.invoke();
    },
    setDrawerOpen(val: boolean) {
      self.menuState = { ...self.menuState, drawerOpen: val };
    },
    setScriptIdx(idx: number) {
      self.menuState = { ...self.menuState, scriptIdx: idx };
    },
    setStartedAtTimestamp() {
      self._startedAtTimestamp = (new Date()).getTime();
    }
  }));

export default CallSessionState;
export interface ICallSessionState extends Instance<typeof CallSessionState> {}
