import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { MasterclassMessage } from './MasterclassMessages';
import { RTModel } from './RTModel';

const MasterclassChatAdminState = RTModel.named('MasterclassChatAdminState')
  .props({
    userMessagesFeed: types.array(types.string),
    starredMessageFeed: types.array(types.string),
    starredMessageToggleWithPksInvoke: InvokerType,
    cannedMessagesInvoke: InvokerType,
    messageMap: types.map(MasterclassMessage),
  }).actions(self => ({
      starredMessageToggle(masterClassMessagepks: string, is_starred: boolean){ 
        return self.starredMessageToggleWithPksInvoke.invoke({masterclass_message_pks: masterClassMessagepks, is_starred});
      },
      getCannedMessages(){
        return self.cannedMessagesInvoke.invoke();
      },
  }));

export default MasterclassChatAdminState;
export interface IMasterclassChatAdminState extends Instance<typeof MasterclassChatAdminState> {}
