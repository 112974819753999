import { fetchCatchupSessions, fetchRegisteredSprints, registerForCatchupSession } from 'api';
import { ApiResponse } from 'apisauce';
import { types, flow, cast, Instance } from 'mobx-state-tree';
import Sprint from './coachStores/Sprint';

const Catchup = types
  .model('Catchup', {
    sprint: Sprint,
    sessions: types.array(types.frozen({ name: '', start_at: '' })),
  })
  .actions((self) => ({
    registerForSession: flow(function* (sessionName: string) {
      const response: ApiResponse<any> = yield registerForCatchupSession(sessionName);
      if (response.problem) {
        console.error(response.problem);
        return;
      }
      return true;
    }),
  }));

export interface ICatchup extends Instance<typeof Catchup> {}

export const CatchupStore = types
  .model('CatchupStore', {
    registeredSprints: types.array(types.string),
    catchupSessions: types.array(Catchup),
    loading: false,
    loadingCatchupSessions: false,
    loadingError: false,
  })
  .actions((self) => ({
    setLoading(loading: boolean) {
      self.loading = loading;
    },
    fetchRegisteredSprints: flow(function* () {
      const response: ApiResponse<any> = yield fetchRegisteredSprints();
      if (response.problem) {
        console.error(response.problem);
        return;
      }
      self.registeredSprints = cast(response.data.registered_sprints);
    }),
    fetchCatchupSessions: flow(function* ({ missedCatchupSessions }: { missedCatchupSessions?: boolean }) {
      self.loadingCatchupSessions = true;
      try {
        const response: ApiResponse<any> = yield fetchCatchupSessions({ missedCatchupSessions });
        if (response.problem) {
          console.error(response.problem);
          self.loadingError = true;
          return;
        }
        self.catchupSessions = cast(response.data.catchup_sessions);
      } finally {
        self.loadingCatchupSessions = false;
      }
    }),
  }));

export interface ICatchupStore extends Instance<typeof CatchupStore> {}
