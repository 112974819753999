import { Instance, types } from 'mobx-state-tree';
import { RTModel } from './RTModel';

const Notification = types.model('Notification', {
  message: types.string,
  variant: types.union(
    types.literal('default'),
    types.literal('error'),
    types.literal('success'),
    types.literal('warning'),
    types.literal('info'),
  ),
  persist: false,
});

const TutorSessionCoachAdminState = RTModel.named('TutorSessionCoachAdminState')
  .props({
    coachCurrentRoom: types.maybeNull(types.string),
    coachScrollPosition: types.maybeNull(types.number),
    notify: types.maybe(Notification),
  })
  .actions((self) => ({
    setCoachCurrentRoom(name: string | null) {
      self.coachCurrentRoom = name;
    },
    setCoachScrollPosition(position: number) {
      self.coachScrollPosition = position;
    },
  }));

export default TutorSessionCoachAdminState;
export interface ITutorSessionCoachAdminState extends Instance<typeof TutorSessionCoachAdminState> {}
