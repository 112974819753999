import { types, Instance } from 'mobx-state-tree';
import { VideoDetail, IVideoDetail } from './ActivityDetail';
import { toJS } from 'mobx';

export const VideoStore = types
  .model('VideoStore', {
    video: types.maybeNull(VideoDetail),
    ts: types.optional(types.number, -1),
    playState: types.optional(types.number, -1),
    defaultSeek: types.optional(types.number, 0)
  })
  .actions((self) => ({
    pause() {
      self.playState = 1;
    },
    play() {
      self.playState = 2;
    },
    seek(pt: number) {
      self.defaultSeek = pt;
      self.ts = pt;
    },
    setVideo(video: IVideoDetail) {
      self.defaultSeek = 0;
      self.ts = 0;
      self.video = toJS(video);
    },
    closeVideo() {
      self.video = null;
      self.defaultSeek = 0;
      self.ts = 0;
    },
    setDefaultSeek(val: number) {
      self.defaultSeek = val;
    }
  }))
  .views((self) => ({
    get isActive() {
      return !!self.video;
    },
  }));

export interface IVideoStore extends Instance<typeof VideoStore> {}
