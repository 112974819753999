import {
  fetchStudentBatchHistory,
  fetchStudentDetails,
  fetchStudents,
  fetchOnboardingFormData,
  changeBatchStudentMergeStatus,
} from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';
import { types, flow, Instance, cast } from 'mobx-state-tree';
import { BatchLite, BatchStudentLite } from './BatchStore';
import { StudentExtra, UserLiteTimezone } from '../User';

export const BatchStudentHistory = types.model('BatchStudentHistory', {
  name: types.string,
  batch: BatchLite,
  status: types.string,
  status_updated_on: types.string,
});
export const OnboardingFormData = types.model('OnboardingFormData', {
  childAccomplishments: types.optional(types.string, ''),
  subOutcomes: types.optional(types.string, ''),
  childMotivationToJoinNeoSchool: types.optional(types.string, ''),
  childPriorProgrammingExperience: types.optional(types.string, ''),
  childPassionHobbies: types.array(types.optional(types.string, '')),
  extraInfoForLearningCoach: types.optional(types.string, ''),
});
export interface IOnboardingFormData extends Instance<typeof OnboardingFormData> {}

export const BatchStudentStore = BatchStudentLite.named('BatchStudentStore')
  .props({
    name: types.string,
    batch: BatchLite,
    student: StudentExtra,
    attendance: types.maybe(types.number),
    feedback: types.maybe(types.number),
    unmergeable: types.maybe(types.boolean),
    parent: types.maybeNull(UserLiteTimezone),
    batchHistory: types.maybe(types.array(BatchStudentHistory)),
    callback_on: types.maybeNull(types.string),
    onboardingFormData: types.maybe(OnboardingFormData),
    submitting: false,
    error: '',
  })
  .actions((self) => ({
    fetchBatchHistory: flow(function* () {
      const response = yield fetchStudentBatchHistory({
        batchStudent: self.name,
      });
      if (response.ok) {
        self.batchHistory = response.data;
      }
    }),
    fetchOnboardingFormData: flow(function* () {
      const response: ApiResponse<any> = yield fetchOnboardingFormData(self.name);
      if (response.ok) {
        self.onboardingFormData = cast(response.data);
      }
    }),
    changeBatchStudentMergeStatus: flow(function* (unmergeable: boolean, reason?: string) {
      self.submitting = true;
      self.error = '';
      try {
        const response: ApiResponse<any> = yield changeBatchStudentMergeStatus(self.name, unmergeable, reason);
        if (response.problem && response.status === 400 && response.data.error) self.error = response.data.error;
      } finally {
        self.submitting = false;
      }
    }),
  }));

export interface IBatchStudentStore extends Instance<typeof BatchStudentStore> {}

export const BatchStudentListStore = types
  .model('BatchStudentListStore', {
    batchStudents: types.array(BatchStudentStore),
    batchStudent: types.maybe(BatchStudentStore),
    loading: false,
    loaded: false,
  })
  .actions((self) => ({
    listStudents: flow(function* (q?: string, batchName?: string) {
      self.loading = true;
      self.loaded = false;
      try {
        const response = yield fetchStudents({ q, batchName });
        if (response.problem) {
          return;
        }
        self.batchStudents = response.data.results;
        self.loaded = true;
      } finally {
        self.loading = false;
      }
    }),
    fetchBatchStudentDetails: flow(function* (batchStudent: string, daysBefore: number, status?: string) {
      self.loading = true;
      try {
        const response = yield fetchStudentDetails({ batchStudent, daysBefore, status });
        if (response.problem) {
          return;
        }
        self.batchStudent = response.data;
      } finally {
        self.loading = false;
      }
    }),
  }))
  .views(() => ({}));
