import { fetchBatchByBatchStudentName, submitParentFeedback } from 'api';
import { flow, Instance, types } from 'mobx-state-tree';
import { ApiResponse } from 'apisauce';
import { BatchLite } from './coachStores/BatchStore';

export const ParentFeedbackQuestionAnswer = types.model('ParentFeedbackQuestionAnswer', {
  mcq: types.optional(types.array(types.string), []),
  rating: types.maybe(types.number),
  feedbackText: types.maybe(types.string),
});

export const ParentFeedbackQuestion = types.model('ParentFeedbackQuestion', {
  questionText: types.string,
  name: types.string,
  type: types.union(
    types.literal('multiple-choice'),
    types.literal('feedback-text'),
    types.literal('rating'),
    types.literal('rating10'),
    types.literal('starRating'),
    types.literal('starRating10')
  ),
  choices: types.maybe(types.array(types.string)),
  required: false,
  answer: types.maybe(ParentFeedbackQuestionAnswer),
});

export const ParentFeedbackStore = types
  .model('ParentFeedbackStore', {
    feedbackObject: types.frozen(),
    feedbackSubmitted: false,
    errors: '',
    submittingFeedback: false,
    batch: types.maybe(BatchLite),
    loading: false,
  })
  .actions((self) => ({
    updateFeedbackObj(questionNumber: number, questionValue: IParentFeedbackQuestion) {
      self.feedbackObject = { ...(self.feedbackObject || {}), [questionNumber]: questionValue };
    },
    submitFeedback: flow(function* (
      questions: IParentFeedbackQuestion[],
      batchStudentName: string,
      feedbackType: 'feedback' | 'nps' | 'post_insight_call'
    ) {
      self.submittingFeedback = true;

      let questionAnswers = {} as { [key: string]: any };
      let requestData = {} as { [key: string]: any };
      questions.forEach((question, idx) => {
        const answerDetails: IParentFeedbackQuestion = self.feedbackObject[idx];
        if (answerDetails) questionAnswers[question.name] = answerDetails.answer;
      });

      requestData = { questionAnswers, batchStudentName, feedbackType };

      const response: ApiResponse<any> = yield submitParentFeedback(requestData);
      if (response.problem) return (self.errors = 'There was an error submitting your feedback');
      self.feedbackSubmitted = true;
      self.submittingFeedback = false;
    }),
    setErrors(errorText: string) {
      self.errors = errorText;
    },
  }))
  .actions((self) => ({
    fetchBatch: flow(function* ({ batchStudentName }: { batchStudentName: string }) {
      self.loading = true;
      try {
        const response: ApiResponse<any> = yield fetchBatchByBatchStudentName(batchStudentName);
        if (response.problem) return self.setErrors('There was an error fetching batch details');
        self.batch = response.data;
      } finally {
        self.loading = false;
      }
    }),
    validateForm: (questions: IParentFeedbackQuestion[], feedbackObj: { [key: number]: IParentFeedbackQuestion }) => {
      const incompleteQuestions = [] as number[];
      questions.forEach((question, idx) => {
        if (question.required) {
          const questionInObj = feedbackObj[idx];
          if (!questionInObj) incompleteQuestions.push(idx);
          else {
            switch (question.type) {
              case 'rating':
                if (!questionInObj.answer?.rating) incompleteQuestions.push(idx);
                break;
              case 'starRating':
                if (!questionInObj.answer?.rating) incompleteQuestions.push(idx);
                break;
              case 'feedback-text':
                if (!questionInObj.answer?.feedbackText) incompleteQuestions.push(idx);
                break;
              case 'multiple-choice':
                if (!questionInObj.answer?.mcq || !questionInObj.answer?.mcq?.length) incompleteQuestions.push(idx);
                break;
              default:
                break;
            }
          }
        }
      });

      return !incompleteQuestions.length;
    },
  }));

export interface IParentFeedbackQuestion extends Instance<typeof ParentFeedbackQuestion> {}
export interface IParentFeedbackStore extends Instance<typeof ParentFeedbackStore> {}
export interface IParentFeedbackQuestionAnswer extends Instance<typeof ParentFeedbackQuestionAnswer> {}
