import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

interface LoadingProps {
  errorInfo?: Error | string;
  isError?: boolean;
  fullpage?: boolean;
  block?: boolean;
  loadingMsg?: string;
}
const Loading = ({
  fullpage = false,
  isError = false,
  errorInfo = undefined,
  block = false,
  loadingMsg,
}: LoadingProps) => {
  return (
    <div className={clsx('loading', { 'loading-fullpage': fullpage, 'loading-block': block })}>
      <div className={'loader'}>
        {!isError ? (
          <div className={'dot-typing'} />
        ) : (
          <div>
            <h1>Oops</h1>
            <h2>Error loading {fullpage ? 'page' : ''}</h2>
            {errorInfo && <h3 className="errorInfo">{errorInfo.toString()}</h3>}
            <Link to="/">Home</Link>
          </div>
        )}
      </div>
      {loadingMsg && <span className={'loadingMsg'}>{loadingMsg}</span>}
    </div>
  );
};

export default Loading;
