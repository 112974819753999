import { listCoaches } from 'api/coachDashboard';
import { ApiResponse } from 'apisauce';
import { types, flow, Instance } from 'mobx-state-tree';
import User from '../User';
import { fetchMyCoachesList } from '../../api';

export type COACH_ROLES = 'coach' | 'tech' | 'sales' | 'ops';

export const COACH_ROLE: { [key: string]: COACH_ROLES } = {
  COACH: 'coach',
  TECH: 'tech',
  SALES: 'sales',
  OPS: 'ops',
};

export const CoachLite = types.model({
  username: types.string,
  name: types.string,
});

export interface ICoachLite extends Instance<typeof CoachLite> {}

export const CoachLiteWithSessionCount = CoachLite.named('CoachLiteWithSessionCount').props({
  sessions_count: 0,
});

export interface ICoachLiteWithSessionCount extends Instance<typeof CoachLiteWithSessionCount> {}

export const CoachWithSessionCount = User.named('CoachWithSessionCount').props({
  sessions_count: types.maybeNull(types.number),
});

export interface ICoachWithSessionCount extends Instance<typeof CoachWithSessionCount> {}

export const CoachStore = types
  .model('CoachStore', {
    coaches: types.array(CoachWithSessionCount),
    senior_coaches: types.array(User),
    coach: types.maybeNull(User),
    loading: false,
  })
  .actions((self) => ({
    listCoaches: flow(function* ({
      q,
      canFetchJuniorCoaches = false,
      role = COACH_ROLE.COACH,
      freeAt,
      fetchSubstituteCoaches,
      sessionAt,
      pageSize,
    }: {
      q: string;
      canFetchJuniorCoaches?: boolean;
      role?: COACH_ROLES;
      freeAt?: string;
      fetchSubstituteCoaches?: boolean;
      sessionAt?: string;
      pageSize?: number;
    }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield listCoaches({
          q,
          canFetchJuniorCoaches,
          role,
          freeAt,
          fetchSubstituteCoaches,
          sessionAt,
          pageSize,
        });
        if (response.problem) {
          return;
        }
        self.coaches = response.data.results;
      } finally {
        self.loading = false;
      }
    }),
    listSeniorCoaches: flow(function* ({ q }: { q: string }) {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield listCoaches({ q, fetchSeniorCoaches: true });
        if (response.problem) {
          return;
        }
        self.senior_coaches = response.data.results;
      } finally {
        self.loading = false;
      }
    }),
    fetchMyCoachesList: flow(function* () {
      try {
        self.loading = true;
        const response: ApiResponse<any> = yield fetchMyCoachesList();
        if (response.problem) {
          return;
        }
        self.coaches = response.data;
      } finally {
        self.loading = false;
      }
    }),
  }));
