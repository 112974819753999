import { Instance, types } from 'mobx-state-tree';
import InvokerType from './InvokerType';
import { RTModel } from './RTModel';
import { Playlist } from './coachStores/Sprint';

const SingleActivityState = RTModel.named('SingleActivityState')
  .props({
    activityRoom: types.maybe(types.string),
    refreshActivityRoom: InvokerType,
    playlist: Playlist,
  })
  .actions((self) => ({
    refreshRoom() {
      return self.refreshActivityRoom.invoke();
    },
  }));

export default SingleActivityState;
export interface ISingleActivityState extends Instance<typeof SingleActivityState> {}
